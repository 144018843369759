import React from 'react';
import styles from './navBar.module.scss';
import { FormattedMessage } from "react-intl";

interface Props {
  titleLeftId: string;
}

export class HelfyNavBar extends React.PureComponent<Props> {

  render() {
    return (
      <nav className={`${styles.navBar} ${styles.border}`}>
        <div className={styles.navLeft}>
          <p><FormattedMessage id={this.props.titleLeftId}/></p>
        </div>
      </nav>
    );
  }
}