import React from 'react';
import { FormattedMessage } from "react-intl";
import { ButtonProps } from "./Types/types";
import styles from './Styles/buttons.module.scss';
import { PrimaryButtonLoader } from "../Loaders/PrimaryButtonLoader";

export const CancelButton = ({ isBusy, textId, buttonStyles, ...rest }: ButtonProps) => {
  return (
    <button {...rest} className={`${styles.cancelButton} ${buttonStyles}`}>
      {isBusy ? <PrimaryButtonLoader /> : <FormattedMessage id={textId} />}
    </button>
  );
}
