import React, { useEffect, useState } from 'react';
import { ReviewsError } from "./ReviewsError";
import { NoReviews } from "../Components/NoReviews";
import styles from '../Styles/review-container.module.scss';
import { GreenLoader } from "../../../Helfy.Components/Loaders/GreenLoader";
import { ReviewItem } from "../Components/ReviewItem";
import { useHelfyState } from '../../../Helfy.Utility/GeneralUtilities';
import { apiGetPostAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { HelfyPagination } from '../../../Helfy.Components/Pagination/HelfyPagination';

interface Props {
	counselorId: number
}

export const ReviewsContainer = ({ counselorId }: Props) => {
	const { error, isBusy, setError, setIsBusy } = useHelfyState();
	const [reviews, setReviews] = useState<any[]>([]);
	const [page, setPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const pageSize = 3;

	const getReviews = async (page: number, pageSize: number) => {
		let data = { params: { page: page, pageSize: pageSize } };
		var { r, error } = await apiGetPostAsync(true, () => data, null, setError, setIsBusy, "/Reviews/GetReviews/" + counselorId, "get reviews", null)

		if (!error) {
			setTotalPages(r?.data?.totalPages)
			setReviews(r?.data?.data)
		}
	}

	const pageChanged = async (page: number) => {
		setPage(page)
	}

	useEffect(() => {
		getReviews(page, pageSize)
	}, [page, pageSize])

	return (
		<React.Fragment>
			<div className={styles.reviewHolder}>
				<p className={styles.title}>REVIEWS</p>
				{reviews && reviews.map(item => <ReviewItem review={item} key={item.id} />)}
				{isBusy && <div className={styles.loader}><GreenLoader height={50} width={50} isWhiteBackGround /></div>}
				{!error && reviews.length === 0 && !isBusy && <NoReviews />}
				{!!error && !isBusy && <ReviewsError />}
			</div>
			<div className={styles.pagerContainer}>
				<HelfyPagination onPageChange={pageChanged} currentPage={page} totalPages={totalPages} />
			</div>
		</React.Fragment>
	);
}


