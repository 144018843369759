import * as actions from './Actions';
import { ISearchCounselorsProps } from "../../Types";

const initialState: ISearchCounselorsProps = {
  isBusy: false,
  counselors: [],
  hasMore: true,
  pageSize: 25,
  pageNumber: 1,
  errorMessage: "",
  languages: [],
  specialties: [],
  genderOptions: [],
  isGridView: false,
  isInitialFetch: true,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_COUNSELORS_REQUESTED:
      return { ...state, isBusy: true, hasMore: false };
    case actions.CLEAR_COUNSELORS:
      return { ...state, ...initialState };
    case actions.LOAD_COUNSELORS_SUCCESS:
      const combinedCounselors = [...state.counselors, ...action.payload.counselors]; //todo: this is not efficient not sure why we are doing this. if you load everyting just show everything
      const uniqueCounselors = Array.from(new Map(combinedCounselors.map(counselor => [counselor.id, counselor])).values());

      return { ...state, isBusy: false, hasMore: action.payload.hasMore, pageNumber: action.payload.pageNumber, isInitialFetch: false, counselors: uniqueCounselors };
    case actions.LOAD_COUNSELORS_ERROR:
      return { ...state, isBusy: false, errorMessage: action.payload, isInitialFetch: false };

    default:
      return state
  }
}
