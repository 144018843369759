import React from 'react';
import styles from './step-indicator.module.scss'

interface Props {
  totalSteps: number;
  currentStep: number;
}

export const StepIndicator = ({ currentStep, totalSteps }: Props) => {
  const calculatePercentage = () => {
    currentStep += 1; //todo zasto ovo?
    const percentage = currentStep / totalSteps;

    return `${percentage * 100}%`;
  };

  return (
    <div className={styles.stepper}>
      <div className={styles.stepIndicator}>
        <span>{currentStep + 1}</span>
        <span>/</span>
        <span>{totalSteps}</span>
      </div>
      <div className={styles.stepOuter}>
        <div className={styles.stepperFilled} style={{ width: calculatePercentage() }} />
      </div>
    </div>
  );
}