import React from 'react';
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import styles from '../Styles/register.module.scss'
import { ModalBody } from "../../../Helfy.Components/Modal/ModalBody";
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { ModalHeader } from "../../../Helfy.Components/Modal/ModalHeader";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { getErrorMessageFromApi } from "../../../Helfy.Components/ApiErrors";
import { AsyncInputField } from "../../../Helfy.Components/Formik/AsyncInputField";
import { useSelector } from 'react-redux';
import { getLoading } from '../../../features/emailLookup/emailLookupSlice';

interface Props {
  isValid: boolean;
  error: string | null;
  isSubmitting: boolean;
  emailLoading: boolean;
  openLogin(): void;
  handleSubmit(): void;
  onCloseClick(): void;
}

export const RegisterView = ({ isValid, isSubmitting, handleSubmit, onCloseClick, error, openLogin, emailLoading }: Props) => {
  const intl = useIntl();

  return (
    <ModalBody bodyStyle={{ width: '620px', overflow: 'hidden' }}>
      <ModalHeader show={true} text={intl.formatMessage({ id: "sign.up.title" })} onCloseClick={onCloseClick} />
      {(error != null && error != "") && <div className={styles.errorMessage}><p>{getErrorMessageFromApi(error)}</p></div>}
      <div className={styles.modalContent}>
        <Field
          type="email"
          id="email"
          name="email"
          component={AsyncInputField}
          showSpinner={emailLoading}
          label={intl.formatMessage({ id: "sign.up.email.label" })}
          placeholder={intl.formatMessage({ id: "login.email.placeholder" })}
        />
        <Field
          type="text"
          name="firstName"
          id="inputFirstName"
          component={InputField}
          label={intl.formatMessage({ id: "sign.up.first.name.label" })}
          placeholder={intl.formatMessage({ id: "sign.up.first.name.placeholder" })}
        />
        <Field
          type="text"
          name="lastName"
          id="inputLastName"
          component={InputField}
          label={intl.formatMessage({ id: "sign.up.last.name.label" })}
          placeholder={intl.formatMessage({ id: "sign.up.last.name.placeholder" })}
        />
        <Field
          type="password"
          id="inputPassword"
          name="password"
          component={InputField}
          label={intl.formatMessage({ id: "sign.up.password.label" })}
          placeholder={intl.formatMessage({ id: "sign.up.password.placeholder" })}
        />
      </div>
      <div className={styles.footer}>
        <PrimaryButton
          type="submit"
          disabled={!isValid}
          isBusy={isSubmitting}
          onClick={handleSubmit}
          textId="sign.up.account.sign.up"
          buttonStyles={styles.buttonSignIn}
        />
        <p>
          <FormattedMessage id="sign.up.already.have.account" />
          <button onClick={openLogin} className={styles.buttonSignUp}><FormattedMessage id="sign.up.account.sign.in" /></button>
        </p>
      </div>
    </ModalBody>
  );
}