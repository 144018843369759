import React from 'react';
// import { verifyPaypalAsync } from "../Redux/Actions";
import styles from '../Styles/payment-methods.module.scss'
import { SecondaryButton } from "../../../Helfy.Components/Buttons/SecondaryButton";
import { useAppDispatch } from '../../../Helfy.Store/Hooks';
import { apiGetPostAsync } from '../../../Helfy.Api/Helpers/Helpers';

interface Props {
  isBusy: boolean;
  show: boolean;
  authorizeStripeAsync: () => Promise<void>;
}

export const AuthorizeStripe = React.memo(({ isBusy, show, authorizeStripeAsync }: Props) => {
  const dispatch = useAppDispatch();

  if (!show) return null;
  
  return (
    <div className={`${styles["buttons-holder"]} ${styles.one}`}>
      <SecondaryButton textId="generic.messages.verify.stripe" isBusy={isBusy} onClick={authorizeStripeAsync} />
    </div>
  )
});