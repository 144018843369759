import React from 'react';
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { GoToChatButton } from "./GoToChat";
import { ReviewRequest } from "./ReviewRequest";
import { HelfyAvatar } from "../Avatar/HelfyAvatar";
import styles from './Styles/notification-list-item.module.scss'
import { NotificationEnum } from "../../Helfy.Enums/NotificationEnum";
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../Helfy.Store/Hooks';
import { RoleEnum } from '../../Helfy.Enums/RoleEnum';

interface Props {
  notification: any;
}

export const NotificationItem = ({ notification }: Props) => {
  const { id, profilePhoto, chatUserId, displayName, notificationTime, type, title, notificationText } = notification;
  const isCounselor = useAppSelector(i => i.app.role) === RoleEnum.Counselor.stringValue;
  const navigate = useNavigate();

  const goToSessionRequests = () => {
    navigate("/session-request")
  }

  const sessionRequest =
    <div className={styles.buttonHolder}>
      <button type="button" onClick={goToSessionRequests}>Go to session requests</button>
    </div>

  return (
    <div className={`${styles["notification-item"]} ${styles.active}`}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.avatar}>
            <HelfyAvatar fullName={displayName} profilePhoto={profilePhoto} />
          </div>
          <p className={styles.name}>{displayName}</p>
          <p className={styles.date}>{notificationTime && <FormattedRelativeTime value={notificationTime} updateIntervalInSeconds={1} />}</p>
        </div>
        <div className={styles.right}>
          {{
            [NotificationEnum.SessionRequested]: isCounselor ? sessionRequest : null,
            [NotificationEnum.SessionModified]: isCounselor ? sessionRequest : null,
            [NotificationEnum.SessionAccepted]: isCounselor ? sessionRequest : null
          }[type as number]}
        </div>
      </div>
      <div className={styles.body}>
        {{
          [NotificationEnum.Standard]: <h3 className={styles.title}>{title}</h3>,
          [NotificationEnum.SessionRequested]: <h3 className={styles.title}>{title}</h3>,
          [NotificationEnum.SessionModified]: <h3 className={styles.title}>{title}</h3>,
          [NotificationEnum.SessionAccepted]: <h3 className={styles.title}>{title}</h3>,
          [NotificationEnum.SessionRejected]: <h3 className={`${styles.title} ${styles.declined}`}>{title}</h3>,
        }[type as number]}
      </div>
    </div>
  );
}
