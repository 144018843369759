import axios from 'axios';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import styles from '../Styles/buy-credits.module.scss';
import { SelectPackage } from "../Components/SelectPackage";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { ChoosePaymentMethod } from "../Components/ChoosePaymentMethod";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { BackNavigation } from "../../../Helfy.Components/NavBar/BackNavigation";
import { StepIndicator } from "../../../Helfy.Components/Steps/StepIndicator";

import large from "../../../Helfy.Assets/Images/img_pkg_large.svg";
import medium from "../../../Helfy.Assets/Images/img_pkg_medium.svg";
import small from "../../../Helfy.Assets/Images/img_pkg_small.svg";
import tiny from "../../../Helfy.Assets/Images/img_pkg_tiny.svg";

import largeActive from "../../../Helfy.Assets/Images/img_pkg_large_active.svg"
import mediumActive from "../../../Helfy.Assets/Images/img_pkg_medium_active.svg"
import smallActive from "../../../Helfy.Assets/Images/img_pkg_small_active.svg"
import tinyActive from "../../../Helfy.Assets/Images/img_pkg_tiny_active.svg"

export const BuyCreditsContainer = React.memo(() => {
  const intl = useIntl();
  const [totalSteps] = useState(2);
  const [isBusy, setIsBusy] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(getPackageOptions()[2]);

  //todo: treba staviti stilove da su u biti isti background isti buttoni sve je ista velicina a mijenja se unutra dio
  //nekak napraviti sa novim wizard controlom morat će ga se promijeniti vjerovatno HelfySteps. čak nije tak teško ali neda mi se sada

  //todo: use our api function or something better
  async function handleSubmit() {
    setIsBusy(true);
    const data = { product: selectedPackage.code }
    try {
      const res = await axios.post(`${API_URL}/Payment/SinglePayment`, data);
      window.location.href = res.data.url;
    } catch (e) {
      setIsBusy(false);
    }
  }

  //todo: use out wizard control
  return (
    <>
      <BackNavigation title={intl.formatMessage({ id: "generic.messages.buy.credits" })} navigateTo="/account/payments" show={true} />
      <div className={styles["main-content"]}>
        <section className={styles["buy-credits"]}>
          <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />
          {currentStep === 1 && <ChoosePaymentMethod selectedPackage={selectedPackage} />}
          {currentStep === 0 && <SelectPackage packages={getPackageOptions()} selectedPackage={selectedPackage} selectPackage={(pack: any) => setSelectedPackage(pack)} />}
          <div className={currentStep === 0 ? styles["button-holder"] : styles["buttons-holder"]}>
            {currentStep === 1 && <button className={styles.left} onClick={() => setCurrentStep(0)}>{intl.formatMessage({ id: "generic.messages.prev" })}</button>}
            {currentStep === 0 && <button className={styles.right} onClick={() => setCurrentStep(1)}>{intl.formatMessage({ id: "generic.messages.next" })}</button>}
            {currentStep === 1 && <PrimaryButton textId="generic.messages.buy" isBusy={isBusy} onClick={handleSubmit} />}
          </div>
        </section>
      </div>
    </>
  )

  function getPackageOptions() {
    return [
      {
        id: 0,
        code: "tiny",
        name: 'Tiny',
        currency: "$",
        value: 15,
        activeImg: tinyActive,
        img: tiny,
      },
      {
        id: 1,
        code: "small",
        name: 'Small',
        currency: "$",
        value: 30,
        activeImg: smallActive,
        img: small,
      },
      {
        id: 2,
        value: 50,
        code: "medium",
        name: 'Medium',
        currency: "$",
        img: medium,
        activeImg: mediumActive,
      },
      {
        id: 3,
        code: "large",
        value: 100,
        img: large,
        currency: "$",
        name: 'Large',
        activeImg: largeActive,
      }
    ]
  }
});