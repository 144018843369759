export enum SleepingHabits {
    Bad = 0,
    Average = 1,
    Good = 2
}

export const SleepingHabitsStringKeys: { [key in SleepingHabits]: string } = {
    [SleepingHabits.Bad]: "on.boarding.patient.bad",
    [SleepingHabits.Average]: "on.boarding.patient.average",
    [SleepingHabits.Good]: "on.boarding.patient.good",
};