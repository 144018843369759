//todo: remove when done
import axios from 'axios';
import { Dispatch } from "redux";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { ErrorManager } from "../../../Helfy.Managers/ErrorManager";
import { LogManager } from "../../../Helfy.Managers/LogManager";
import { apiGetPostAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { setError, setIsBusy, setParticipant } from './Reducer';
import { IParticipant } from '../Types';
import { RootState } from '../../../Helfy.Store/Store';


//todo: ispada da se prvo pokrene unread event a onda tek ovaj call. dali bi ovaj call trebao biti prvi?
//mozda treba prebaciti logiku iz helfyhuva nekako tu?
export const loadParticipantsAsync = () => {
    return async (dispatch: Dispatch) => {
        await apiGetPostAsync(true, () => ({ page: 1, pageSize: 25 }), null, (e) => dispatch(setError(e)), (b) => dispatch(setIsBusy(b)), "/Conversation/GetParticipants", "GetParticipants", (r) => {
            dispatch(setParticipant({ participants: r?.data.data }))
            return null
        });
    }
}

//todo: nema potrebe po meni za još jedan poziv participants već u sebi loada roomove sve za usera mislim?a možda i ne možda napravi query?
export const loadRoomForUserAsync = (roomId: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const participants = state.participant.participants;

        await apiGetPostAsync(true, null, null, (e) => dispatch(setError(e)), null, "/Conversation/GetChat/" + roomId, "GetChat", (r) => {
            const selectedUser = participants.find((p: IParticipant) => p.roomId === roomId);
            const selectedRoom = r?.data

            dispatch(setParticipant({ selectedRoom: r?.data, selectedUser: selectedUser }))
            return null
        })
    }
}

// import { ROOM_MESSAGES_HAVE_BEEN_LOADED } from "../../Chat/Redux/Actions";

// export const LOAD_PARTICIPANTS_SUCCESS = "LOAD_PARTICIPANTS_SUCCESS";
// export const LOAD_PARTICIPANTS_FAILURE = "LOAD_PARTICIPANTS_FAILURE";
// export const LOAD_PARTICIPANTS_REQUESTED = "LOAD_PARTICIPANTS_REQUESTED";

// export const LOAD_ROOM_FOR_USER_SUCCESS = 'LOAD_ROOM_FOR_USER_SUCCESS';
// export const LOAD_ROOM_FOR_USER_FAILURE = 'LOAD_ROOM_FOR_USER_FAILURE';
// export const LOAD_ROOM_FOR_USER_REQUESTED = "LOAD_ROOM_FOR_USER_REQUESTED";

// // UTILITY
// export const CLEAR_STATE = "PARTICIPANTS/CLEAR_STATE";
// export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";
// export const SELECTED_USER_CHANGED = "SELECTED_USER_CHANGED";
// export const CHANGE_USER_NETWORK_STATUS = "CHANGE_USER_NETWORK_STATUS";
// export const SET_NEW_MESSAGES_COUNT_TO_ZERO = "SET_NEW_MESSAGES_COUNT_TO_ZERO";
// export const SET_INDICATOR_FOR_INCOMING_MESSAGE = "SET_INDICATOR_FOR_INCOMING_MESSAGE";

// export const loadChatUsersAsync = (page: number, pageSize: number) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       let params = {page: page, pageSize: pageSize};
//       dispatch({type: LOAD_PARTICIPANTS_REQUESTED});
//       const res = await axios.get(API_URL + "/Conversation/GetParticipants", {params: params});
//       dispatch({type: LOAD_PARTICIPANTS_SUCCESS, payload: res.data})
//     } catch (e) {
//       const error = ErrorManager.transformErrorMessages(e);
//       LogManager.logError("LOAD PARTICIPANTS ASYNC", error);
//       LogManager.logError("LOAD PARTICIPANTS ASYNC -> native", e);
//       dispatch({type: LOAD_PARTICIPANTS_FAILURE, payload: error})
//     }
//   }
// };

// //todo: cijeli chat ce se morati maknuti kada dođe vrime
// export const loadRoomForUserAsync = (roomId: number) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       dispatch({type: SELECTED_USER_CHANGED, payload: roomId});
//       const res: any = await axios.get(`${API_URL}/Conversation/GetChat/${roomId}`);
//       dispatch({type: LOAD_ROOM_FOR_USER_SUCCESS, payload: res.data});
//       dispatch({type: SET_NEW_MESSAGES_COUNT_TO_ZERO, payload: roomId});
//       dispatch({type: ROOM_MESSAGES_HAVE_BEEN_LOADED, payload: res.data})
//       return res.data;
//     } catch (e) {
//       const error = ErrorManager.transformErrorMessages(e);
//       LogManager.logError("LOAD ROOM FOR USER ASYNC", error);
//       LogManager.logError("LOAD ROOM FOR USER ASYNC -> native", e);
//       dispatch({type: LOAD_ROOM_FOR_USER_FAILURE, payload: error})
//     }
//   }
// };

// // Utility
// export const clearState = () => (dispatch: Dispatch) => dispatch({type: CLEAR_STATE});
// export const clearSelectedUser = () => (dispatch: Dispatch) => dispatch({type: CLEAR_SELECTED_USER});
// export const changeUserNetworkStatus = (data: any) => (dispatch: Dispatch) => dispatch({type: CHANGE_USER_NETWORK_STATUS, payload: data});