import React from 'react';
import styles from "./modal.module.scss";

interface Props extends React.PropsWithChildren {
  bodyStyle?: any;
}

export const ModalBody = ({ bodyStyle, children }: Props) => {
  return (
    <div className={styles.modalBody} style={bodyStyle}>
      {children}
    </div>
  );
}