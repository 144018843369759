import React from 'react';

import styles from '../Styles/review-container.module.scss';

export const ReviewsError = () => {
	return (
		<div className={styles.errorHolder}>
			<div className={styles.title}>Error while fetching reviews. <br />We will try to fix this as soon as possible</div>
		</div>
	);
}
