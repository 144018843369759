//todo: remove unneeded
import AppReducer from '../Helfy.App/Redux/Reducer';
// import ChatReducer from '../Helfy.Containers/Chat/Redux/Reducer';
// import PayoutReducer from '../Helfy.Containers/Payout/Redux/Reducer'
// import VideoReducer from '../Helfy.Containers/VideoCall/Redux/Reducer'
// import LanguagesReducer from '../Helfy.Containers/Languages/Redux/Reducer';
import AccountInfoReducer from '../Helfy.Containers/AccountInfo/Redux/Reducer'
import ParticipantReducer from '../Helfy.Containers/Participants/Redux/Reducer'
import SpecialtiesReducer from '../Helfy.Containers/Specialities/Redux/Reducer';
import NotificationReducer from '../Helfy.Containers/Notifications/Redux/Reducer'
// import PaymentMethodsReducer from '../Helfy.Containers/PaymentMethods/Redux/Reducer'
import TransactionsListReducer from '../Helfy.Containers/Transactions/Redux/Reducer'
import CounselorDetailsReducer from '../Helfy.Containers/CounselorDetails/Redux/Reducer'
// import CounselorProfileReducer from '../Helfy.Containers/CounselorProfile/Redux/Reducer'
import SessionRequestReducer from '../Helfy.Containers/SessionRequest/Redux/Reducer';
import PreferencesReducer from '../Helfy.Containers/NotificationsSettings/Redux/Reducer'
import PatientOnBoardingReducer from '../Helfy.Containers/PatientOnBoarding/Redux/Reducer'
import SearchCounselors from '../Helfy.Containers/SearchCounselors/Container/Redux/Reducers'
import CounselorOnBoardingReducer from '../Helfy.Containers/CounselorOnBoarding/Redux/Reducer';
// import AccountBasicInformationReducer from '../Helfy.Containers/BasicInformation/Redux/Reducers'
import PatientNotificationSettingsReducer from '../Helfy.Containers/PatientNotificationSettings/Redux/Reducer'
import { emailLookup } from '../features/emailLookup/emailLookupSlice';

//todo: delete all unused reducers
export const reducers = {
  app: AppReducer,
  // chat: ChatReducer,
  // videoRoom: VideoReducer,
  // payout: PayoutReducer,
  emailLookup: emailLookup,
  account: AccountInfoReducer,
  preferences: PreferencesReducer,
  notification: NotificationReducer, //todo: ovo realno ne treba moze se zamijeniti sa lokalnim stateom. promijeniti ime u couunselorNotification
  participant: ParticipantReducer,
  // languageOptions: LanguagesReducer,
  searchCounselors: SearchCounselors,
  sessionRequest: SessionRequestReducer,
  // paymentMethods: PaymentMethodsReducer,
  transaction: TransactionsListReducer,
  specialtiesOptions: SpecialtiesReducer,
  counselorDetails: CounselorDetailsReducer,
  // counselorProfile: CounselorProfileReducer,
  patientOnBoarding: PatientOnBoardingReducer,
  counselorOnBoarding: CounselorOnBoardingReducer,
  // accountBasicInformation: AccountBasicInformationReducer,
  patientNotificationSettings: PatientNotificationSettingsReducer, //todo: ovo realno ne treba moze se zamijeniti sa lokalnim stateom
};
