import React from 'react';
import styles from './reasons-section.module.scss';
import { FormattedMessage } from "react-intl";

export class ReasonsSection extends React.PureComponent {

  render() {
    return (
      <section className={styles.helfyReasons}>
        <h1><FormattedMessage id="landing.page.reasonsSections.heading"/></h1>
        <div className={styles.flex}>
          <div className={styles.helfyCard}>
            <p><FormattedMessage id="landing.page.reasonsSections.paragraph"/></p>
          </div>
          {/*<div className="helfy-card">*/}
          {/*  <p>Pay small fee for the credits in exchange for video call minutes. Any other communication via chat is free and unlimited.</p>*/}
          {/*</div>*/}
          {/*<div className="helfy-card">*/}
          {/*  <p>Do it at your own time and at your own pace. Communicate with your therapists as often as you want and whenever you feel it's needed.</p>*/}
          {/*</div>*/}
        </div>
      </section>
    );
  }
}