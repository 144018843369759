import React from 'react'
import styles from './helfy-layout.module.scss';

interface Props extends React.PropsWithChildren {
  paddedTop?: boolean;
}

//todo: dali se moze ... umjesto children?
export const HelfyContainer = ({ paddedTop, children }: Props) => {
  return (
    <div className={`${styles.helfyContainer} ${paddedTop && styles.padded}`}>
      {children}
    </div>
  );
}

export const HelfyFullHeightContainer = ({ paddedTop, children }: Props) => {
  return (
    <div className={`${styles.fullHeight} ${paddedTop && styles.padded}`}>
      {children}
    </div>
  );
}
