import React from 'react';
import { NavLink } from "react-router-dom";
import styles from '../Styles/payment-methods.module.scss';
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";

interface Props {
  show: boolean;
  disabled: boolean;
}

export const Payout = React.memo(({show, disabled}: Props) => {

  if (!show) return null;

  return (
    <div className={`${styles["buttons-holder"]} ${styles.one}`}>
      <NavLink to="/payout"><PrimaryButton textId="generic.messages.payout" disabled={disabled}/></NavLink>
    </div>
  )
});