import React from 'react';
import Modal from 'react-modal';
import styles from './modal.module.scss';

interface Props extends React.PropsWithChildren {
  isOpen: boolean;
}

//todo: is it better to put this in css and not in code, seems like maybe it can be in helfy modal classname I don't 
const customStyles = {
  content: {},
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  }
};

export const HelfyModal = ({ isOpen, children }: Props) => {
  return (
    <Modal isOpen={isOpen} style={customStyles} className={styles.helfyModal}>
      {children}
    </Modal>
  );
}