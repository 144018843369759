import Yup from '../../../../Helfy.Validation/HelfyYupLocalizedValidation'


export const Questions1Schema = Yup.object().shape({
  coverLetter: Yup.string()
    .required(),
  licenseInformation: Yup.string()
    .required(),
  yearsOfPracticing: Yup.string()
    .required(),
  hoursOfWork: Yup.string()
    .required(),
});
