import React from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styles from '../../Styles/chat.module.scss';

interface Props {
  id: number;
  show: boolean;
}

export const CounselorDetailsButton = React.memo(({ show, id }: Props) => {
  if (!show) return null;

  return (
    <div className={styles.headerLink}>
      <NavLink to={`/counselor-details/${id}`} style={{ textDecoration: 'none' }}>
        <FormattedMessage id="chat.counselor.view.details" />
      </NavLink>
    </div>
  )
});