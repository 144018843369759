import React, { useState } from 'react';
import { Formik } from "formik";
import { Row } from "react-grid-system";
import { ResendEmailView } from "../View/ResendEmailView";
import { HelfyContainer } from "../../../Helfy.Layout/HelfyContainer";
import { setCurrentEmailInvite } from '../../../Helfy.App/Redux/Reducer';
import { useAppDispatch } from '../../../Helfy.Store/Hooks';
import { useIntl } from 'react-intl';
import { ResendEmailSchema } from './ValidationSchema';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { apiSimpleSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { useNavigate } from 'react-router-dom';

type FormValues = {
  email: string,
}

export const ResendEmailContainer = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)

  const handleSubmitAsync = async (values: FormValues, actions: any) => {
    apiSimpleSubmitAsync(values, actions, setError, setIsBusy, "/Auth/ResendEmail", "Forgot Password Requested")
    dispatch(setCurrentEmailInvite(values.email)); 
  }

  const handleSuccess = () => {
    navigate("/accept-email-invite") 
  }

  const text = intl.formatMessage({ id: 'restore.password.success.modal.text' });
  const title = intl.formatMessage({ id: 'restore.password.success.modal.title' });
  const errorTitle = intl.formatMessage({ id: 'restore.password.error.modal.title' });

  const initialValues: FormValues = {
    email: "",
  }

  return (
    <HelfyContainer paddedTop>
      <Row justify="center">
        <Formik validateOnMount validateOnBlur onSubmit={handleSubmitAsync} initialValues={initialValues} validationSchema={ResendEmailSchema}>
          {props => <ResendEmailView {...props} />}
        </Formik>
      </Row>
      <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
      <HelfySimpleSuccessModal error={error} title={title} message={text} onConfirmClick={handleSuccess} />
    </HelfyContainer>
  );
}
