import React from 'react';
import icPhone from '../../../Helfy.Assets/Images/ic_phone.svg'
import { CallButton } from './CallButton';
import styles from '../Styles/video-session.module.scss';

interface Props {
  show: boolean;
  onClick(): void;
}

export const GreenButton = ({ show, onClick }: Props) => {
  return (
    <CallButton show={show} onClick={onClick} titleId={'generic.messages.start.call'} imgSrc={icPhone} buttonStyle={styles.greenBtn} />
  )
};