import * as actions from './Actions';
import { ICounselorDetails, ICounselorDetailsProps } from "../Types";

const initialState: ICounselorDetailsProps = {
  isBusy: false,
  reviews: [],
  errorMessage: '',
  showError: false,
  counselor: {} as ICounselorDetails,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case actions.GET_COUNSELOR_REQUESTED:
      return {...state};

    case actions.GET_COUNSELOR_SUCCESS:
      return {...state, counselor: action.payload};

    case actions.GET_COUNSELOR_FAILURE:
      return {...state, errorMessage: action.payload};

    case actions.COUNSELING_REQUEST_REQUESTED:
      return {...state, isBusy: true};

    case actions.COUNSELING_REQUEST_SUCCESS:
      return {...state, isBusy: false};

    case actions.COUNSELING_REQUEST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};

    case actions.CLEAR_COUNSELOR_DETAILS_ERRORS:
      return {...state, isBusy: false, showError: false, errorMessage: ''}

    default:
      return state
  }
}
