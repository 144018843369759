import axios from 'axios';
import { Dispatch } from "redux";
import { API_URL } from "../../../Helfy.Config/AppSettings";

export const MARK_ALL_AS_READ_REQUESTED = "MARK_ALL_AS_READ_REQUESTED";
export const MARK_ALL_AS_READ_SUCCESS = "MARK_ALL_AS_READ_REQUESTED";
export const MARK_ALL_AS_READ_FAILURE = "MARK_ALL_AS_READ_REQUESTED";

export const READ_NOTIFICATION = "READ_NOTIFICATION";

export const LOAD_NOTIFICATIONS_REQUESTED = "NOTIFICATIONS/LOAD_NOTIFICATIONS_REQUESTED";
export const LOAD_NOTIFICATIONS_SUCCESS = "NOTIFICATIONS/LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_NOTIFICATIONS_FAILURE = "NOTIFICATIONS/LOAD_NOTIFICATIONS_FAILURE";

export const INCREMENT_NEW_NOTIFICATIONS_COUNT = "INCREMENT_NEW_NOTIFICATIONS_COUNT";

export const loadNotifications = (onlyNew: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({type: LOAD_NOTIFICATIONS_REQUESTED});

    axios.get(API_URL + '/notifications/GetUserNotifications', {params: {onlyNew: onlyNew}})
      .then(function (response) {
        dispatch({type: LOAD_NOTIFICATIONS_SUCCESS, payload: response.data})
      }).catch(function (error) {
      dispatch({type: LOAD_NOTIFICATIONS_FAILURE, error: error.toString()})
    });
  }
};

export const markAllAsRead = () => {
  return (dispatch: Dispatch) => {
    dispatch({type: MARK_ALL_AS_READ_REQUESTED});

    axios.get(API_URL + '/notifications/markAllAsRead',)
      .then(res => dispatch({type: MARK_ALL_AS_READ_SUCCESS}))
      .catch(err => dispatch({type: MARK_ALL_AS_READ_FAILURE, error: err.toString()}));
  }
};

export const incrementNotificationsCountBy = (incrementBy: number) => {
  return (dispatch: Dispatch) => dispatch({type: INCREMENT_NEW_NOTIFICATIONS_COUNT, payload: incrementBy})
};
