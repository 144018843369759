import * as actions from './Actions';
import { INotificationProps } from "../Types";

const initialState: INotificationProps = {
  isBusy: false,
  errorMessage: '',
  showError: false,
  notifications: [],
  newNotificationCount: 0,
};

export default (state: INotificationProps = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_NOTIFICATIONS_REQUESTED:
      return { ...state };

    case actions.LOAD_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.payload };

    case actions.LOAD_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.error };

    case actions.MARK_ALL_AS_READ_SUCCESS:
      return { ...state, notifications: [] };

    case actions.MARK_ALL_AS_READ_FAILURE:
      return { ...state, error: action.error };

    case actions.INCREMENT_NEW_NOTIFICATIONS_COUNT:
      return { ...state, newNotificationCount: (action.payload == -1) ? 0 : state.newNotificationCount + action.payload }; //todo: zesci hack
    default:
      return state;
  }
}