import React from 'react';
import { useIntl } from 'react-intl';
import styles from '../Styles/buy-credits.module.scss';

interface Props {
  packages: Array<any>;
  selectedPackage: any;

  selectPackage(option: any): void;
}

export const SelectPackage = React.memo(({packages, selectedPackage, selectPackage}: Props) => {
  const intl = useIntl();
  return (
    <div className={styles["buy-credits-container"]}>
      <div className="credits-holder">
        <h3>{intl.formatMessage({id: "generic.messages.choose.your.credit.package"})}</h3>
        <p>{intl.formatMessage({id: "generic.messages.choose.your.credit.package.desc"})}</p>
        <div className={styles["imgs-holder"]}>
          {
            packages.map((item, index) => {
              return (
                <div className={`${styles.img} ${styles[`img-${index}`]}`}>
                  <p>{item.name} {item.currency} {item.value}</p>
                  <img alt="tinyPackage" src={selectedPackage.id === index ? item.activeImg : item.img} onClick={() => selectPackage(item)}/>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  )
})