import React from 'react';
import { Formik } from "formik";
import { ProfileView } from "./ProfileView";
import { ProfileSchema } from "./ProfileSchema";
import { ISteps } from '../../Types';
import { setState } from '../../Redux/Reducer';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';

interface Props extends ISteps {
}

export const Profile = (p: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.counselorOnBoarding);

  function handleSubmit(values: any,) {
    dispatch(setState(values))
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={state} validationSchema={ProfileSchema} validateOnMount>
      {props => <ProfileView {...props} {...p} />}
    </Formik>
  )
}