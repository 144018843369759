import React from 'react';
import { Props, Theme } from "react-select";
import Select from "react-select";

interface SelectProps extends Props {

}

export const HelfySelect = (props: SelectProps) => {
  return <Select {...props} theme={(theme) => getTheme(theme)} menuPortalTarget={document.body} />
}

function getTheme(theme: Theme) {
  return {
    ...theme,
    border: '1px solid #dff6f1',
    colors: {
      ...theme.colors,
      primary25: '#dff6f1',
      primary: '#79dac7',
    },
  }
}