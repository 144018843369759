import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import styles from '../Styles/chat.module.scss';
import noCounselorIcon from '../../../Helfy.Assets/Images/img_no_counselor.svg'

interface Props {
    show: boolean;
    isCounselor: boolean;
    noChannels: boolean;
}

export const NoSelectedChat = React.memo(({ show, isCounselor, noChannels }: Props) => {
    const intl = useIntl();

    if (!show) return null;

    const type = "." + (isCounselor ? "counselor" : "patient")
    const idAlt = "chat.no.selected.alt" + type;
    const altMessage = intl.formatMessage({ id: idAlt })

    let id1 = "chat.no.selected"
    let id2 = "chat.no.selected.description"

    if (noChannels) {
        id1 += type
        id2 += type
    }

    //todo: is there better way to concatenate strings? not "sds" + var + "sdasds". also probably add the alt to the translations
    return (
        <>
            <div className={styles.noClients}>
                <img className={styles.noClientsImg} src={noCounselorIcon} alt={altMessage} />
                <p>
                    <FormattedMessage id={id1} /> <br />
                    <FormattedMessage id={id2} />
                </p>
            </div >
        </>
    )
});