import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

export const BasicInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(),
  lastName: Yup.string()
    .required(),
  email: Yup.string()
    .email()
    .required()
});
