import React from 'react';
import { useIntl } from "react-intl";
import styles from '../Styles/notifications.module.scss';
import icNone from '../../../Helfy.Assets/Images/img_schedule.svg';

export const NoNotifications = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={styles.noNotifications}>
      <img src={icNone} alt="No notifications"/>
      <p>{intl.formatMessage({id: "notifications.no.new.notifications.line1"})}</p>
    </div>
  )
});