import React from 'react';
import { Label } from "./Components/Label";
import { RowHolder } from "./Components/RowHolder";
import { HelfySelect } from "../Select/HelfySelect";
import { multiSelectStyles } from "../Select/Styles";
import { HelfyMultiValueRemove } from "./Components/HelfyMultiRemove";
import Creatable from "react-select/creatable";

interface Props {
  id: string;
  options: Array<any>
  form: any;
  field: any;
  errors: any;
  touched: any;
  label: string;
  placeholder: string;
}

export const HelfyMultiSelect = ({ label, id, field, options, form, ...rest }: Props) => {
  const handleChange = (value: any) => {
    form.setFieldValue(field.name, value);
  }

  const handleBlur = () => {
    form.setFieldTouched(field.name);
  }

  return (
    <RowHolder field={field} form={form} >
      <Label id={id} label={label} form={form} field={field} />
      <HelfySelect
        {...rest}
        isMulti={true}
        name={field.name}
        options={options}
        onBlur={handleBlur}
        styles={multiSelectStyles}
        onChange={handleChange}
        value={field.value}
        components={{ MultiValueRemove: HelfyMultiValueRemove }}
      />
    </RowHolder>
  );
}

