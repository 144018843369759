import React from 'react';
import { NavLink } from "react-router-dom";
import styles from './footer.module.scss'
import faceBookIcon from '../../../Helfy.Assets/Images/ic_face.svg';
import linkInIcon from '../../../Helfy.Assets/Images/ic_linkedIn.svg';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.footerLeft}>
          <p>All rights reserved:</p>
          <NavLink to="/terms">Terms &amp; conditions</NavLink>
          <NavLink to="/cookie-policy">Cookie policy</NavLink>
          <NavLink to="/privacy">Privacy policy</NavLink>
        </div>
        <div className={styles.footerRight}>
          <NavLink to="/help/contact">Contact us</NavLink>
          <NavLink to="/help/faq">Help</NavLink>
        </div>
      </div>
      <hr />
      <div className={styles.footerBottom}>
        <div className={styles.footerCopyright}>
          <p>Copyright 2024 Helfy</p>
        </div>
        <div>
          <img src={faceBookIcon} alt="Facebook icon" />
          <a href="https://www.linkedin.com/company/helfy4life"><img className={styles.linkedInBtn} src={linkInIcon} alt="LinkedIn icon" /></a>
        </div>
      </div>
    </footer>
  );
}
