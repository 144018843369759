import React, { useState } from 'react';
import { RegisterStepTwo } from "../Components/RegisterStepTwo";
import { RegisterStepOne } from "../Components/RegisterStepOne";
import { HelfyModal } from "../../../Helfy.Components/Modal/HelfyModal";
import { useNavigate } from "react-router-dom";
import { HelfySteps } from '../../../Helfy.Components/Steps/HelfySteps';

interface Props {
  openLogin(): void;
  onCloseClick(): void;
}

//todo ovo refaktorirati da malo bolje izgleda
export const RegisterContainer = ({ onCloseClick, openLogin }: Props) => {
  let navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [role, setRole] = useState(1);
  const [totalSteps, setTotalSteps] = useState(0);

  const move = (numOfSteps: number) => {
    setStep(step + numOfSteps)
  }

  const steps = [
    <RegisterStepOne onCloseClick={onCloseClick} activeRole={role} onRoleClick={setRole} move={move} />,
    <RegisterStepTwo openLogin={openLogin} onCloseClick={onCloseClick} activeRole={role} />
  ]
  return (
    <HelfyModal isOpen={true}>
      {/* todo na step2 je bio history dali treba ja ga zbriso */}
      <HelfySteps step={step} setTotalSteps={setTotalSteps} steps={steps}>


      </HelfySteps>
    </HelfyModal>
  );
}