import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../Helfy.Store/Hooks';
import { signOut } from '../Helfy.App/Redux/Reducer';

interface Props extends React.PropsWithChildren {
}

export const EnsureUserHasFilledProfileGuard = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
   
  const isAuthenticated = useAppSelector(state => state.app.isAuthenticated);
  const userHasFilledProfile = useAppSelector(state => state.app.userHasFilledProfile);
  const userIsAccepted = useAppSelector(state => state.app.userIsAccepted);

  const [isSigningOut, setIsSigningOut] = useState(false);

  //history, todo: history je bio prije isAuthenticated
  useEffect(() => {
    if (isAuthenticated) {
      if (!userHasFilledProfile && location.pathname !== '/fill-profile')
        navigate("/fill-profile")
      else if (!userIsAccepted && location.pathname !== '/accept-wait' && userHasFilledProfile) {
        //dispatch(signOut())
        navigate("/logout/?accept-wait=true")        
      }
    }
  }, [isAuthenticated, userHasFilledProfile]);

  return <>{children}</>
}