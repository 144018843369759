import React from 'react';
import { useIntl } from 'react-intl';
import styles from '../Styles/video-session.module.scss';
import { ChatHolder } from "../../Chat/Components/ChatHolder";
import { useAppSelector } from '../../../Helfy.Store/Hooks';

interface Props {
  show: boolean;
}

export const SidebarChatHolder = React.memo(({show}: Props) => {
  const intl = useIntl();
  const {role} = useAppSelector(state => state.app)

  if(!show) return null;

  return (
    <div className={styles.sideContent}>
      <div className={styles.chatSidebar}>
        <div className={styles.chatControls}>
          <button className={`${styles.chatControlBtn}`}>{intl.formatMessage({id: "generic.messages.chat"})}</button>
          <button className={`${styles.notesControlCounselorBtn}`}>{intl.formatMessage({id: "generic.messages.notes"})}</button>
        </div>
        <ChatHolder isSidebarChat={true}/>
      </div>
    </div>
  )
})