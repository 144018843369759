import React from 'react';
import Avatar from 'react-avatar';
import styles from './helfy-avatar.module.scss';

interface Props {
  fullName: string;
  profilePhoto: string;
}

export const HelfyAvatar = ({ profilePhoto, fullName }: Props) => {

  if (!profilePhoto) return <Avatar name={fullName} size="50px" round="25px" />;

  return <img src={profilePhoto} className={styles.avatarIcon} alt={fullName} />
}