import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { TransactionStatusEnum } from '../Transactions/Types/TransactionStatusEnum';
//nexttodo5: transaction treba biti tipa Transaction

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 30,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    header: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
    },
    body: {
        fontSize: 12,
        marginBottom: 10,
    },
    footer: {
        fontSize: 10,
        textAlign: 'center',
        marginTop: 20,
    }
});

export const Receipt = ({ transaction }: any) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Text>Receipt</Text>
                </View>
                <View style={styles.body}>
                    <Text>Date and time: {transaction.dateTime}</Text>
                    <Text>From: {transaction.fromName}</Text>
                    <Text>To: {transaction.toName}</Text>
                    <Text>Description: {transaction.description}</Text>
                    <Text>Amount: {transaction.amount} $</Text>
                    <Text>Status: {TransactionStatusEnum[transaction.status]}</Text>
                </View>
                <View style={styles.footer}>
                    <Text>
                        helfy Inc., 1111B S Governors Ave STE 7660</Text>
                </View>
            </Page>
        </Document>
    );
}