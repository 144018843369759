import React from 'react';
import { useIntl } from "react-intl";
import styles from '../Styles/session-request.module.scss';
import icNone from "../../../Helfy.Assets/Images/img_no_patient.svg";


export const NoSessionRequests = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={styles.noNotifications}>
      <img src={icNone} alt="No sessions"/>
      <p>{intl.formatMessage({id: "session.request.no.requests.line1"})}</p>
      <p>{intl.formatMessage({id: "session.request.no.requests.line2"})}</p>
      <p>{intl.formatMessage({id: "session.request.no.requests.line3"})}</p>
    </div>
  )
});