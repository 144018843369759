import React, { useEffect, useState } from 'react';
import styles from '../Styles/counselor-profile.module.scss';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { AccountContentHolder } from "../../../Helfy.Layout/Account/AccountContentHolder";
import { ScreenLoadingIndicator } from "../../../Helfy.Components/Loaders/ScreenLoadingIndicator";
import { useHelfyIntl } from '../../../Helfy.Utility/TranslationUtilities';
import { GenderStringKeys } from '../../../Helfy.Enums/Gender';
import { PrimaryButton } from '../../../Helfy.Components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { getCounselorOnboardingAsync } from '../../CounselorOnBoarding/Redux/Action';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { getItem, getLabelsString } from '../../../Helfy.Components/Select/Utilities';


//todo: dali treba ovo biti container? razmisliti o cijeloj strukturi imena i td
export const CounselorProfileContainer = React.memo(() => {
  const intl = useHelfyIntl();
  // todo: mogli bi napraviti useHelfyErrorBusyState, i popravi naming malo je usehelfyX malo useappX
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useAppSelector(state => state.counselorOnBoarding);

  const image = state.profileImage && <img src={state.profileImage} alt="Avatar" className={styles.profileImage}/>
  const items = [
    getItem("Image", image),
    getItem("Full Name", state.fullName),
    getItem("VATID", state.companyId),
    getItem("Company Name", state.companyName),
    getItem("Country", state.country),
    getItem("Linkedin Profile", state.linkedInUrl),
    getItem(intl.getString("generic.message.gender"), intl.getEnumString(state.gender?.value, GenderStringKeys)),
    getItem("Education", state.education),
    getItem("Price per hour", state.pricePerHour + "$"),
    getItem("Languages", getLabelsString(state.languages)),
    getItem("Languages description", state.provideServiceInAnotherLanguage),
    getItem("Specialties", getLabelsString(state.specialties)),
    getItem("Cover Letter", state.coverLetter),
    getItem("License information", state.licenseInformation),
    getItem("Years of practicing", state.yearsOfPracticing),
    getItem("Hours of work", state.hoursOfWork),
    getItem("Counseling style", state.counselingStyle),
  ]

  const errorTitle = intl.formatMessage({ id: "generic.messages.error.modal.title" });
  const title = intl.formatMessage({ id: "generic.messages.success.modal.title.update" });
  const description = intl.formatMessage({ id: "generic.messages.success.modal.text.update" });

  const refillProfile = () => {
    navigate("/fill-profile",)
  }

  useEffect(() => {
    (async () => await dispatch(getCounselorOnboardingAsync(setError, setIsBusy, intl)))();
  }, []);


  return (
    <AccountContentHolder className={styles["counselor-profile"]}>
      <ScreenLoadingIndicator show={isBusy}>
        <div className={styles.left}>
          <div className={styles.info}>
            {items.map((link, index) => (
              <div key={index} className={styles.settingContainer}>
                <label className={styles.label}>{link.label}</label>
                <div className={styles.setting}>{link.item}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles["buttons-holder"]}>
          <PrimaryButton textId={"on.boarding.refill.profile"} isBusy={false} onClick={refillProfile} />
        </div>
      </ScreenLoadingIndicator >
      {/* todo: mozda ovo i na pacijenta ovaj error model ili mozda nije bitno jer cemo query koristiti mozda bude skroz drugacije */}
      <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
    </AccountContentHolder >
  )
});

