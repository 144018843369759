import React from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss'
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { TextAreaField } from "../../../../Helfy.Components/Formik/TextAreaField";
import { ISteps } from '../../Types';
import { useAppSelector } from '../../../../Helfy.Store/Hooks';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
}

export const HelfyQuestions1View = ({ move, isValid, handleSubmit }: Props) => {
  const state = useAppSelector(state => state.counselorOnBoarding);

  const onSubmit = () => {
    handleSubmit();
    move(1);
  }

  //todo: zove se requiredsupervision a ne supervision-ed
  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>Helfy Questions 1</h3>
          <div className={styles.profileContent2}>
            <FastField
              id="reasonForOnlineCounselingInput"
              name="reasonForOnlineCounseling"
              type="text"
              label="Why are you interested in providing service online ?"
              placeholder="Your interests"
              component={TextAreaField} />
            <FastField
              id="referenceContactInput"
              name="referenceContact"
              type="text"
              label="Please provide contact information of at least one licenced therapist who can be your reference"
              placeholder="Contact information"
              component={TextAreaField} />
            <FastField
              id="estimatedTime"
              name="estimatedTime"
              type="text"
              label="How much time do you think you can devote to working on this service ?"
              placeholder="Time on service"
              component={TextAreaField} />
            <FastField
              id="experienceOnlineInput"
              name="experienceOnline"
              type="text"
              label="Do you have experience in providing counseling online ? If so, please tell us about it."
              placeholder="Experience online"
              component={TextAreaField} />
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId="counselor.on.boarding.button.next" onClick={onSubmit} disabled={!isValid} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}