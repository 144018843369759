import React, { useState } from 'react';
import { Formik } from "formik";
import { useIntl } from 'react-intl';
import { ContactUsSchema } from "./ValidationSchema";
import { ContactUsView } from "../Views/ContactUsView";
import { HelfyContainer } from "../../../Helfy.Layout/HelfyContainer";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { LogManager } from "../../../Helfy.Managers/LogManager";
import { ErrorManager } from "../../../Helfy.Managers/ErrorManager";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { apiSimpleSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';

type FormValues = {
  email: string,
  message: string,
  subject: string,
  fullName: string
}

export const ContactUsContainer = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState<string | null>("")

  const handleSubmit = async (values: FormValues, actions: any) => apiSimpleSubmitAsync(values, null, setError, setIsBusy, "/ContactUs", "CONTACT US ")

  const handleSuccess = () => {
    navigate("/")
  }

  const text = intl.formatMessage({ id: 'contact.us.success.modal.text' });
  const title = intl.formatMessage({ id: 'contact.us.success.modal.title' });
  const errorTitle = intl.formatMessage({ id: 'contact.us.error.modal.title' });

  const values: FormValues = {
    email: "",
    message: "",
    subject: "",
    fullName: ""
  }

  return (
    <HelfyContainer>
      <Formik validateOnMount validateOnBlur initialValues={values} onSubmit={handleSubmit} validationSchema={ContactUsSchema}>
        {props => <ContactUsView {...props} />}
      </Formik>
      <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
      <HelfySimpleSuccessModal error={error} title={title} message={text} onConfirmClick={handleSuccess} />
    </HelfyContainer>
  );
}