import * as actions from './Actions';
import { ITransactionListProps } from "../Types";

const initialState: ITransactionListProps = {
  isBusy: false,
  errorMessage: "",
  showError: false,
  transactions: [],
};


export default (state: ITransactionListProps = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_TRANSACTIONS_REQUESTED:
      return {...state, isBusy: true};
    case actions.LOAD_TRANSACTIONS_FAILURE:
      return {...state, isBusy: false, error: action.payload};
    case actions.LOAD_TRANSACTIONS_SUCCESS:
      return {...state, isBusy: false, transactions: action.payload.data};
    default:
      return state;
  }
}