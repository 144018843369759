import React, { useEffect, useState } from 'react';
import styles from '../Styles/patient-profile.module.scss';
import { AccountContentHolder } from '../../../Helfy.Layout/Account/AccountContentHolder';
import { ScreenLoadingIndicator } from '../../../Helfy.Components/Loaders/ScreenLoadingIndicator';
import { loadAnswersAsync } from '../../PatientOnBoarding/Redux/Actions';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { Gender, GenderStringKeys } from '../../../Helfy.Enums/Gender';
import { SleepingHabitsStringKeys } from '../../PatientOnBoarding/Types/SleepingHabits';
import { EatingHabitsStringKeys } from '../../PatientOnBoarding/Types/EatingHabits';
import { MentalHealthStringKeys } from '../../PatientOnBoarding/Types/MentalHealth';
import { PhysicalHealthStringKeys } from '../../PatientOnBoarding/Types/PhysicalHealth';
import { CounselingBeforeStringKeys } from '../../PatientOnBoarding/Types/CounselingBefore';
import { getLocalDateString } from '../../../Helfy.Utility/GeneralUtilities';
import { PrimaryButton } from '../../../Helfy.Components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { useHelfyIntl } from '../../../Helfy.Utility/TranslationUtilities';


export const PatientProfileContainer = () => {
    const intl = useHelfyIntl();
    const [error, setError] = useState<string | null>("")
    const [isBusy, setIsBusy] = useState(false)
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const answers = useAppSelector(s => s.patientOnBoarding);

    useEffect(() => {
        (async () => await dispatch(loadAnswersAsync(setError, setIsBusy)))();
    }, []);


    const items = [
        { label: intl.getString("generic.message.gender"), text: intl.getEnumString(answers.gender, GenderStringKeys) },
        { label: intl.getString("generic.messages.birthday"), text: getLocalDateString(answers.birthDay) },
        { label: intl.getString("on.boarding.patient.counseling.before.short"), text: intl.getEnumString(answers.counselingBefore, CounselingBeforeStringKeys) },
        { label: intl.getString("on.boarding.patient.physical.health.short"), text: intl.getEnumString(answers.physicalHealth, PhysicalHealthStringKeys) },
        { label: intl.getString("on.boarding.patient.mental.health.short"), text: intl.getEnumString(answers.mentalHealth, MentalHealthStringKeys) },
        { label: intl.getString("on.boarding.patient.sleeping.habits.short"), text: intl.getEnumString(answers.sleepingHabits, SleepingHabitsStringKeys) },
        { label: intl.getString("on.boarding.patient.eating.habits.short"), text: intl.getEnumString(answers.eatingHabits, EatingHabitsStringKeys) },
        { label: intl.getString("on.boarding.patient.reason.message"), text: answers.message },
    ]

    const refillProfile = () => {
        navigate("/fill-profile",)
    }

    return (
        <AccountContentHolder className={styles["patient-profile"]}>
            <ScreenLoadingIndicator show={isBusy}>
                <div className={styles.left}>
                    <div className={styles.info}>
                        {items.map((link, index) => (
                            <div key={index} className={styles.settingContainer}>
                                <label className={styles.label}>{link.label}</label>
                                <div className={styles.setting}>{link.text}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles["buttons-holder"]}>
                    <PrimaryButton textId={"on.boarding.refill.profile"} isBusy={false} onClick={refillProfile} />
                </div>
            </ScreenLoadingIndicator >
        </AccountContentHolder >
    )
}