import { useIntl } from "react-intl";
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { TransactionsView } from "../Views/TransactionView";
import { downloadHistoryAsync, loadTransactionsAsync } from "../Redux/Actions";
import { AccountContentHolder } from "../../../Helfy.Layout/Account/AccountContentHolder";
import { getTransactionStatusEnumWithEmptyOptions } from "../Types/TransactionStatusEnum";


export const TransactionsContainer = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [selectedTimeOption, setSelectedTimeOption] = useState(getTimeOptions()[0]);
  const [selectedTypeOption, setSelectedTypeOption] = useState(getTransactionStatusEnumWithEmptyOptions(intl)[0]);
  const { errorMessage, showError, isBusy, transactions } = useAppSelector(state => state.transaction);

  useEffect(() => {
    async function _dummy() {
      await dispatch(loadTransactionsAsync({}));
    }

    _dummy();
  }, []);

  async function handleSearch() {
    let query = { status: selectedTypeOption.value, from: selectedTimeOption.value, search: search };
    await dispatch(loadTransactionsAsync(query))
  }

  function handleTextChange(e: any) {
    setSearch(e.target.value)
  }

  function handleSelectTypeChange(type: any) {
    setSelectedTypeOption(type);
  }

  function handleSelectTimeChange(time: any) {
    setSelectedTimeOption(time);
  }

  async function handleDownloadHistory() {
    await dispatch(downloadHistoryAsync());
  }

  return (
    <AccountContentHolder>
      <TransactionsView
        isBusy={isBusy}
        onSearch={handleSearch}
        transactions={transactions}
        timeOptions={getTimeOptions()}
        typeOptions={getTransactionStatusEnumWithEmptyOptions(intl)}
        handleTextChange={handleTextChange}
        onHistoryDownload={handleDownloadHistory}
        selectedTimeOption={selectedTimeOption}
        selectedTypeOption={selectedTypeOption}
        handleSelectTypeChange={handleSelectTypeChange}
        handleSelectTimeChange={handleSelectTimeChange}
        showNoResult={!isBusy && transactions.length === 0}
      />
    </AccountContentHolder>
  );


  function getTimeOptions() {
    return [
      { label: intl.formatMessage({ id: "generic.messages.past.any" }), value: null },
      { label: intl.formatMessage({ id: "generic.messages.past.30.days" }), value: new Date(firstDate) },
      { label: intl.formatMessage({ id: "generic.messages.past.60.days" }), value: new Date(secondDate) },
      { label: intl.formatMessage({ id: "generic.messages.past.90.days" }), value: new Date(thirdDate) },
    ]
  }
};

const today = new Date();
const firstDate = new Date().setDate(today.getDate() - 30);
const secondDate = new Date().setDate(today.getDate() - 60);
const thirdDate = new Date().setDate(today.getDate() - 90);

