import React from 'react';
import { useNavigate } from "react-router-dom";
import styles from './Styles/notification-list-item.module.scss';

interface Props {
  userId: number;
  goToChat(userId: number): any
}

export const GoToChatButton = React.memo(({goToChat, userId}: Props) => {
  const history = useNavigate();

  function onClick() {
    goToChat(userId);
    history("/")
  }

  return (
    <div className={styles.buttonHolder}>
      <button type="button" onClick={onClick}>Go to chat</button>
    </div>
  )
});