import React from 'react';
import { useIntl } from "react-intl";
import styles from './Styles/notification-list-item.module.scss';

interface Props {
  id: number;
  onClick(id: number): any
}

export const ReviewRequest = React.memo(({ onClick, id }: Props) => {
  const intl = useIntl();
  return (
    <div className={styles.buttonHolder}>
      <button onClick={() => onClick(id)} className={styles.green}>{intl.formatMessage({ id: "generic.messages.review.request" })}</button>
    </div>
  )
});