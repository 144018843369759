import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { ModalBody } from "../../../Helfy.Components/Modal/ModalBody";
import { ModalHeader } from "../../../Helfy.Components/Modal/ModalHeader";
import styles from "../Styles/register.module.scss";
import patient from '../../../Helfy.Assets/Images/img_patient.svg';
import counselor from "../../../Helfy.Assets/Images/img_counselor.svg";
import patientActive from '../../../Helfy.Assets/Images/img_patient_active.svg';
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import counselorActive from "../../../Helfy.Assets/Images/img_counselor_active.svg";

interface Props {
  activeRole: number;
  onCloseClick(): void
  move(numOfSteps: number): void;
  onRoleClick(role: number): void;
}

export const RegisterStepOne = ({ onCloseClick, onRoleClick, activeRole, move }: Props) => {
  const intl = useIntl();
  return (
    <ModalBody bodyStyle={{ width: '620px', overflow: 'hidden' }}>
      <ModalHeader show={true} text={intl.formatMessage({ id: "sign.up.title" })} onCloseClick={onCloseClick} />
      <div className={styles.registerContainer}>
        <div className={styles.modalLeft}>
          <h4><FormattedMessage id="sign.up.counselor" /></h4>
          <img src={activeRole === 1 ? counselorActive : counselor} alt="Counselor logo" onClick={() => onRoleClick(1)} />
        </div>
        <div className={styles.modalRight}>
          <h4><FormattedMessage id="sign.up.patient" /></h4>
          <img src={activeRole === 0 ? patientActive : patient} alt="Counselor logo" onClick={() => onRoleClick(0)} />
        </div>
      </div>
      <div className={styles.footer}>
        <PrimaryButton textId="sign.up.next.button" buttonStyles={styles.buttonSignIn} onClick={() => move(1)} />
      </div>
    </ModalBody>
  )
}