import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

export const ContactUsSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  fullName: Yup.string()
    .min(6, "Min length is 6 characters")
    .required('Required'),
  subject: Yup.string()
    .min(6, "Min length is 6 characters")
    .required(),
  message: Yup.string()
    .min(6, 'Min length 6 characters')
    .required('Required'),
});
