import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './Helfy.App/App';
import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';
import { store } from "./Helfy.Store/Store";
import 'normalize.css';
import './Helfy.Styles/App.scss'
import { Provider } from 'react-redux';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); //todo: dali nam ovo treba


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); //todo:ovo je bilo u starom
