import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from '../../Styles/slides.module.scss';
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { ISteps } from '../../../CounselorOnBoarding/Types';


interface Props extends ISteps {
}

export const Intro = ({ move }: Props) => {
  return (
    <OnBoardingCardHolder>
      <div className={styles.slide1}>
        <h1 className={styles.intro}><FormattedMessage id="on.boarding.patient.intro.title" /></h1>
        <p className={styles.introContent}><FormattedMessage id="on.boarding.patient.intro.text" /></p>
        <div className={styles.buttonHolder}>
          <SecondaryButton textId="on.boarding.patient.prev.text" onClick={() => move(-1)} />
          <PrimaryButton textId="on.boarding.patient.button.next" onClick={() => move(1)} />
        </div>
      </div>
    </OnBoardingCardHolder>
  );
}