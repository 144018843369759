import { emailLookup } from "../Helpers/Helpers";
import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

export const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
    .test('is-unique', "Email already taken", emailLookup as any),
  password: Yup.string()
    .min(6)
    .required(),
  firstName: Yup.string()
    .min(2)
    .max(50)
    .required(),
  lastName: Yup.string()
    .min(2)
    .max(50)
    .required(),
});
