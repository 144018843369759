import React, { useState } from "react"
import styles from '../Styles/review-session.module.scss';
import { HelfyModal } from "../../../Helfy.Components/Modal/HelfyModal";
import { ModalBody } from "../../../Helfy.Components/Modal/ModalBody";
import { ModalHeader } from "../../../Helfy.Components/Modal/ModalHeader";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { ReviewSessionView } from "../Views/ReviewSessionView";
import { Formik } from "formik";
import { apiSimpleSubmitAsync, apiSubmitAsync } from "../../../Helfy.Api/Helpers/Helpers";
import { ReviewSchema } from "./ReviewSchema";
import { getErrorMessageFromApi } from "../../../Helfy.Components/ApiErrors";

interface Props {
	onClose(): void;
	callStateId: number | null
}

type FormValues = {
	rating: number,
	comment: string
}

type LeaveReviewViewModel = {
	callStateId: number | null,
	rating: number,
	text: string
}

const initialValues: FormValues = {
	rating: 0,
	comment: ""
}

export const ReviewSessionContainer = ({ onClose, callStateId }: Props) => {
	const [error, setError] = useState<string | null>(null);
	const [isBusy, setIsBusy] = useState(false);

	const callLeaveReviewApiAsync = async (formValues: FormValues) => {
		const values: LeaveReviewViewModel = {
			callStateId: callStateId,
			text: formValues.comment,
			rating: formValues.rating
		}

		return await apiSubmitAsync(() => values, null, null, null, "/Reviews", "Create review", null)
	}

	const handleSubmitAsync = async (values: FormValues, actions: any) => {
		const { error: err, r } = await callLeaveReviewApiAsync(values)

		if (!err)
			onClose();
		else {
			const err2 = getErrorMessageFromApi(err) ?? "Error has occurred!\nPlease try again later.";
			setError(err2);
		}
	}

	return (
		<HelfyModal isOpen={true} >
			<Formik initialValues={initialValues} onSubmit={handleSubmitAsync} validationSchema={ReviewSchema}>
				{props =>
					<ReviewSessionView {...props} onClose={onClose} error={error} />
				}
			</Formik>
		</HelfyModal>
	)
}