import React from 'react';
import { FastField } from "formik";
import { Col, Row } from "react-grid-system";
import { FormattedMessage } from "react-intl";
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { TextAreaField } from "../../../Helfy.Components/Formik/TextAreaField";
import { HelfyFormHolder } from "../../../Helfy.Layout/HelfyFormHolder";

interface Props {
  isValid: boolean;
  isSubmitting: boolean;
  handleSubmit(): void;
}

export const ContactUsView = ({ isValid, isSubmitting, handleSubmit }: Props) => {
  return (
    <Col xl={12} lg={12} md={12} xs={12} sm={12}>
      <HelfyFormHolder>
        <p><FormattedMessage id="contact.us.title" /></p>
        <FastField
          type="text"
          css='fullName'
          name="fullName"
          label="Full Name *"
          id="inputFullName"
          placeholder="Enter your full name"
          component={InputField}
        />
        <FastField
          id="inputEmail"
          name="email"
          type="email"
          css='email'
          label="Email address *"
          placeholder="Enter your email address"
          component={InputField}
        />
        <FastField
          type="text"
          css='subject'
          name="subject"
          label="Subject *"
          id="inputSubject"
          placeholder="Enter subject"
          component={InputField}
        />
        <FastField
          id="inputMessage"
          name="message"
          css='message'
          type="text"
          label="Your message *"
          placeholder="We are listening ..."
          component={TextAreaField}
        />
        <Row justify="center">
          <PrimaryButton textId="contact.us.send" onClick={handleSubmit} disabled={!isValid} isBusy={isSubmitting} type="button" />
        </Row>
      </HelfyFormHolder>
    </Col>
  );
}