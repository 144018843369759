import React, { useState } from 'react';
import styles from '../Styles/onboarding-patient.module.scss'
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { useNavigate } from 'react-router-dom';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { HelfyNavBar } from '../../../Helfy.Components/NavBar/HelfyNavBar';
import { StepIndicator } from '../../../Helfy.Components/Steps/StepIndicator';
import { Counseling } from '../Views/Step 05/Counseling';
import { useIntl } from 'react-intl';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { Intro } from '../Views/Step 02/Intro';
import { BirthDay } from '../Views/Step 04/BirthDay';
import { PhysicalHealth } from '../Views/Step 06/PhysicalHealth';
import { MentalHealth } from '../Views/Step 07/MentalHealth';
import { Sleeping } from '../Views/Step 08/Sleeping';
import { Eating } from '../Views/Step 09/Eating';
import { Reason } from '../Views/Step 10/Reason';
import { Welcome } from '../Views/Step 01/Welcome';
import { HelfySteps } from '../../../Helfy.Components/Steps/HelfySteps';
import { Gender } from '../Views/Step 03/Gender';
import { useStepState } from '../../../Helfy.Components/Steps';
import { setUserProfileFilled } from '../../../Helfy.App/Redux/Reducer';

export const PatientOnBoardingContainer = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const { step, move, totalSteps, setTotalSteps } = useStepState();
  const state = useAppSelector(s => s.patientOnBoarding)
  const userHasFilledProfile = useAppSelector(state => state.app.userHasFilledProfile);
  const navigateUrl = userHasFilledProfile ? "/account/questionnaire" : "/";

  const submitAsync = async () => {
    await apiSubmitAsync(() => state, null, setError, setIsBusy, "/Account/SetPatientOnBoardingQuestions", "SET_PATIENT_ANSWERS", () => null)
  }

  const cancel = () => {
    navigate(navigateUrl);
  }

  const handleSuccess = () => {
    dispatch(setUserProfileFilled());
    navigate(navigateUrl)
  }

  const text = intl.formatMessage({ id: 'on.boarding.patient.success.modal.text' });
  const title = intl.formatMessage({ id: 'on.boarding.patient.success.modal.title' });
  const errorTitle = intl.formatMessage({ id: 'on.boarding.patient.error.modal.title' });
  const steps = [
    <Welcome move={move} cancel={cancel} />,
    <Intro move={move} />,
    <Gender move={move} />,
    <BirthDay move={move} />,
    <Counseling move={move} />,
    <PhysicalHealth move={move} />,
    <MentalHealth move={move} />,
    <Sleeping move={move} />,
    <Eating move={move} />,
    <Reason move={move} onSubmit={submitAsync} isBusy={isBusy} />
  ]

  return (
    <>
      <HelfyNavBar titleLeftId="counselor.onBoarding.navBar.title" />
      <section className={styles.carouselSection}>
        <div className={styles.carouselCounter}>
          <StepIndicator currentStep={step} totalSteps={totalSteps} />
        </div>
        <HelfySteps step={step} setTotalSteps={setTotalSteps} steps={steps}>
        </HelfySteps>
      </section>
      <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
      <HelfySimpleSuccessModal error={error} title={title} message={text} onConfirmClick={handleSuccess} />
    </>
  );
}