import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { CounselorListView } from "./CounselorListView";
import { NoCounselors } from "../Components/NoCounselors";
import { SearchFilter } from "../Components/SearchFilter";
import styles from '../Styles/search-counselor.module.scss';
import { FilterControls } from "../Components/FilterControls";
import { CounselorGridListView } from "./CounselorGridListView";
import { loadCounselorsAsync, searchCounselorsAsync } from "./Redux/Actions";
import { BackNavigation } from "../../../Helfy.Components/NavBar/BackNavigation";
import { SearchCounselorsInitialEmptyState } from "../Components/SearchCounselorsInitialEmptyState";

export const SearchCounselorContainer = React.memo(() => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [showFilter, setShowFilter] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const {isBusy, pageNumber, pageSize, counselors} = useAppSelector(state => state.searchCounselors)

  useEffect(() => {
    async function dummy() {
      await dispatch(loadCounselorsAsync(pageNumber, pageSize))
    }

    dummy();

  }, []);

  async function _searchCounselorsAsync(data: any) {
    data.pageNumber = 1;
    data.pageSize = pageSize;
    await dispatch(searchCounselorsAsync(data, true));
  }

  return (
    <>
      <BackNavigation title={intl.formatMessage({id: "generic.messages.search.counselors"})} navigateTo="/" show/>
      <div className={styles.desktop}>
        <section className={styles.searchCounselorSection}>
          <SearchFilter show={showFilter} searchCounselorsAsync={_searchCounselorsAsync}/>
          <div className={`${styles.mainContentRight} ${showFilter ? '' : styles.fullWidth}`}>
            <NoCounselors show={counselors.length <= 0 && !isBusy}/>
            <SearchCounselorsInitialEmptyState show={counselors.length === 0 && isBusy}/>
            <FilterControls showFilter={showFilter} isGridView={isGridView} toggleFilter={() => setShowFilter(!showFilter)} toggleView={() => setIsGridView(!isGridView)}/>
            <CounselorListView show={!isGridView}/>
            <CounselorGridListView show={isGridView}/>
          </div>
        </section>
      </div>
    </>
  )
})