import Select from 'react-select';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import styles from '../Styles/filter-bar-left.module.scss'
import searchStyles from '../Styles/search-counselor.module.scss';
import searchIc from '../../../Helfy.Assets/Images/ic_search.svg';
import { multiSelectStyles } from "../../../Helfy.Components/Select/Styles";
import { HelfyRating } from "../../../Helfy.Components/HelfyRating";
import { Animated } from 'react-animated-css';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { getGenderOptions } from '../../../Helfy.Enums/Gender';

interface Props {
  show: boolean;

  searchCounselorsAsync(data: any): any
}

export const SearchFilter = React.memo(({ show, searchCounselorsAsync }: Props) => {
  const intl = useIntl();
  const [rating, setRating] = useState(0);

  const genderOptions = [{ label: "Any gender", value: null }, ...getGenderOptions(intl)]; //todo: add intl for first element. also we have this somewhere else first elements empty i think on search transactions

  const [gender, setGender] = useState(genderOptions[0]);
  const [priceTo, setPriceTo] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [searchTerm, setSearchTem] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const [languages, setLanguages] = useState<any[]>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);

  const loadSpecialitiesAsync = () => apiSubmitAsync(null, null, null, null, "/Options/GetSpecialties", "LOAD_SPECIALITIES ERROR", () => null)
  const loadLanguagesAsync = () => apiSubmitAsync(null, null, null, null, "/Options/GetLanguages", "LOAD_LANGUAGES ERROR", () => null)

  function reset() {
    setRating(0)
    setGender(genderOptions[0])
    setPriceTo("")
    setPriceFrom("");
    setSearchTem("");
    setSelectedLanguage(languages[0]);
    setSelectedSpecialty(specialties[0]);
  }

  async function onSearch() {
    const data = { rating, gender, priceFrom, priceTo, searchTerm, language: selectedLanguage, specialty: selectedSpecialty };
    await searchCounselorsAsync(data);
  }

  //todo: move to react query, the same code is being run on counselor onboarding
  useEffect(() => {
    const fetchAll = async () => {
      const { error: e1, r: r1 } = await loadLanguagesAsync();
      const { error: e2, r: r2 } = await loadSpecialitiesAsync();

      const error = (e1 || "") + (e2 || "")
      return { error, r1, r2 }
    }

    fetchAll().then(r => {
      const { error, r1, r2 } = r;

      if (!!error)
        setError(error)
      else {
        //todo: add intl for first element
        const l = [{ label: "All languages", value: null }, ...r1?.data]
        const s = [{ label: "All specialties", value: null }, ...r2?.data]
        setLanguages(l)
        setSpecialties(s)
        setSelectedLanguage(l[0])
        setSelectedSpecialty(s[0])
      }
    });
  }, [])


  return (
    <div className={`${searchStyles.filterBarContainer} ${show ? '' : searchStyles.collapsed}`}>
      <Animated animationInDuration={500} animationOutDuration={1000} animationIn="fadeInLeftBig" animationOut="fadeOutLeftBig" isVisible={show} >
        <div className={styles.filterBarLeft}>
          <div className={`${styles.section} ${styles.search}`}>
            <div className={styles["form-holder"]}>
              <img src={searchIc} alt="search-icon" />
              <input name='searchTerm' value={searchTerm} type="text" placeholder={intl.formatMessage({ id: "generic.messages.search" })} onChange={(e) => setSearchTem(e.target.value)} />
            </div>
          </div>
          <div className={`${styles.section} ${styles.lined}`}>
            <div className={styles['title-holder']}><span>{intl.formatMessage({ id: "generic.messages.by.gender" })}</span></div>
            <div className={styles.content}>
              <Select styles={multiSelectStyles} placeholder={intl.formatMessage({ id: "generic.messages.choose.gender" })} options={genderOptions} value={gender} onChange={(gender: any) => setGender(gender)} />
            </div>
          </div>
          <div className={`${styles.section} ${styles.lined}`}>
            <div className={styles['title-holder']}><span>{intl.formatMessage({ id: "generic.messages.by.rating" })}</span></div>
            <div className={styles.content}>
              <div className={styles.stars}>
                <HelfyRating rating={rating} handleChange={setRating} starDimension="50px" starSpacing="1px" />
              </div>
            </div>
            <div className={styles["form-by-rating"]}>
              <Select isMulti={false} options={specialties} styles={multiSelectStyles} placeholder="Enter speciality" value={selectedSpecialty} onChange={(specialty: any) => setSelectedSpecialty(specialty)} />
            </div>
            <div className={styles["margin-top"]}>
              <Select isMulti={false} options={languages} value={selectedLanguage} styles={multiSelectStyles} placeholder="Enter spoken language" onChange={(language: any) => setSelectedLanguage(language)} />
            </div>
          </div>
          <div className={`${styles.section} ${styles.search}`}>
            <div className={styles["form-holder"]}>
              <input value={priceFrom} name="priceFrom" min="0" step="any" type="number" onChange={(e) => setPriceFrom(e.target.value)} placeholder="Price($) from" />
            </div>
            <div className={styles["form-holder"]} style={{ marginTop: "1em" }}>
              <input value={priceTo} name="priceTo" min="0" step="any" type="number" onChange={(e) => setPriceTo(e.target.value)} placeholder="Price($) to" />
            </div>
          </div>
          <div className={`${styles.section} ${styles.buttons}`}>
            <span className={styles.buttons}>
              <button className={styles.reset} onClick={reset}>{intl.formatMessage({ id: "generic.messages.reset.filter" })}</button>
              <button className={styles.apply} onClick={onSearch}>{intl.formatMessage({ id: "generic.messages.apply.filter" })}</button>
            </span>
          </div>
        </div>
      </Animated>
    </div>
  )
});

//todo: tu bi isto trebalo staviti simple helfy error modal i mozda na vise mjesta