import React from 'react';
import styles from './crisis-section.module.scss'
import { FormattedMessage } from "react-intl";

export class CrisisSection extends React.PureComponent {

  render() {
    return (
      <section className={styles.crisis}>
        <p>
          <FormattedMessage id="landing.page.crisisSections.paragraph1" /><br />
          <a href='/resources'><FormattedMessage id="landing.page.crisisSections.paragraph2" /></a><br /><FormattedMessage id="landing.page.crisisSections.paragraph3" /></p>
      </section>
    );
  }
}