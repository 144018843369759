import Yup from '../../../../Helfy.Validation/HelfyYupLocalizedValidation'

export const BasicInformationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(),
  country: Yup.string()
    .required(),
  companyId: Yup.string()
    .required(),
  companyName: Yup.string()
    .required(),
  gender: Yup.object()
    .required(),
  linkedInUrl: Yup.string()
    .required()
});