import React from 'react'
import { FormattedMessage } from "react-intl";
import { FaqListItemComponent } from "./FaqListItemComponent";

export const FaqComponent = () => {
  return (
    <div>
      <FaqListItemComponent headerId="faq.questions.header1" textId="faq.questions.answer1" />
      <FaqListItemComponent headerId="faq.questions.header2" textId="faq.questions.answer2" />
      <FaqListItemComponent headerId="faq.questions.header3" textId="faq.questions.answer3" />
      <FaqListItemComponent headerId="faq.questions.header4" textId="faq.questions.answer4" />
      <FaqListItemComponent headerId="faq.questions.header5" textId="faq.questions.answer5">
        <ul>
          <li><FormattedMessage id="faq.questions.answer5A" /></li>
          <li><FormattedMessage id="faq.questions.answer5B" /></li>
          <li><FormattedMessage id="faq.questions.answer5C" /></li>
        </ul>
        <FormattedMessage id="faq.questions.answer5D" />
      </FaqListItemComponent>
    </div>
  );
}