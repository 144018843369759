import Yup from '../../../../Helfy.Validation/HelfyYupLocalizedValidation'


export const HelfyQuestions2Schema = Yup.object().shape({
  requiredSuperVisioned: Yup.string()
    .required(),
  howOftenCheckYourEmail: Yup.string()
    .required(),
  detailsAboutInvestigation: Yup.string()
    .required(),
  disciplineInvestigations: Yup.string()
    .required(),
});
