import React from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss'
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { TextAreaField } from "../../../../Helfy.Components/Formik/TextAreaField";
import { ISteps } from '../../Types';
import { useAppSelector } from '../../../../Helfy.Store/Hooks';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
  isBusy: boolean
}

export const Questions2View = ({ move, isValid, handleSubmit, isBusy }: Props) => {
  const userHasFilledProfile = useAppSelector(state => state.app.userHasFilledProfile);

  const onSubmit = () => {
    handleSubmit();
    if (!userHasFilledProfile) // if filled profile we won't go further this will be the end one
      move(1);
  }

  const buttonLabelId = userHasFilledProfile ? "counselor.on.boarding.button.submit" : "counselor.on.boarding.button.next";
  // const buttonLabelId = "counselor.on.boarding.button.next";
  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>My Profile Questions 2</h3>
          <div className={styles.profileContent2}>
            <FastField
              id="provideServiceInAnotherLanguage"
              name="provideServiceInAnotherLanguage"
              type="text"
              label="Are you able to provide services in another language? If so, please explain."
              component={TextAreaField}
            />
            <FastField
              id="counselingStyle"
              name="counselingStyle"
              type="text"
              label="Please provide some details about your counseling style and approach ?"
              component={TextAreaField}
            />
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId={buttonLabelId} onClick={onSubmit} disabled={!isValid || isBusy} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}