import React from 'react';
import { FieldProps } from 'formik';
import { HelfyRating } from '../HelfyRating';

interface Props {
	starSpacing?: string;
	starDimension?: string;
}

export const FormikRatingField = ({
	field,
	form,
	starSpacing,
	starDimension
}: FieldProps & Props) => {
	const handleChange = (newRating: number) => {
		form.setFieldValue(field.name, newRating);
	};

	return (
		<HelfyRating
			rating={field.value}
			handleChange={handleChange}
			starDimension={starDimension}
			starSpacing={starSpacing}
		/>
	);
};
