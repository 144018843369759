import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { Field } from "formik";
import { useIntl } from 'react-intl';
import styles from '../Styles/payout.module.scss';
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { useAppSelector } from '../../../Helfy.Store/Hooks';

interface Props {
  values: any;
  isValid: boolean;
  isSubmitting: boolean;

  handleSubmit(): any;
}

export const PayoutView = React.memo(({ values, isValid, isSubmitting, handleSubmit }: Props) => {
  const intl = useIntl();

  const { credits } = useAppSelector(state => state.account.accountInfo);
  return (
    <>
      <h3>{intl.formatMessage({ id: "generic.messages.how.many.funds" })}</h3>
      <div>
        <p>{intl.formatMessage({ id: "generic.messages.payout.minimum.10.dollars" })}</p>
      </div>
      <div className={styles.payoutSection}>
        <div className={styles.left}>
          <p>{intl.formatMessage({ id: "generic.messages.available.funds" })}</p>
          <p className={styles.funds}>$ {credits}</p>
        </div>
        <div className={styles.right}>
          <Field type="number" step="0.01" name="amount" id="amountInput" label="Amount *" placeholder="Enter amount" component={InputField} />
        </div>
      </div>
      <div className={styles.buttonsHolder}>
        <NavLink to="/account/payments">
          <button className={styles.left}>{intl.formatMessage({ id: "generic.messages.cancel" })}</button>
        </NavLink>
        <PrimaryButton textId="generic.messages.payout" disabled={isSubmitting || !isValid} isBusy={isSubmitting} type="submit" onClick={handleSubmit} />
      </div>
    </>
  )
});