import React from 'react';
import { Label } from "./Components/Label";
import { RowHolder } from "./Components/RowHolder";
import { DebounceInput } from "react-debounce-input";
import styles from './Styles/row-holder.module.scss';
import { InputLoader } from "../Loaders/InputLoader";

interface Props {
  id: string;
  form: any;
  field: any;
  errors: any;
  touched: any;
  label: string;
  placeholder: string;
  showSpinner: boolean;
}

export const AsyncInputField = (props: Props) => {
  const { field, label, id, placeholder, form, showSpinner, ...rest } = props;
  return (
    <RowHolder form={form} field={field}>
      <InputLoader show={showSpinner} /> 
      <Label id={id} label={label} form={form} field={field} />
      <DebounceInput className={styles.input} id={id} {...field} {...rest} debounceTimeout={300} placeholder={placeholder} onChange={event => field.onChange(event)} />
    </RowHolder>
  );
}
