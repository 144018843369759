import React from 'react';
import { IntlProvider, } from "react-intl";

import locale_en from "./Translations/en.json";

const data = {
  'en': locale_en
};
interface Props {
  children: any;
  defaultLanguage: string;
}

export function LocalizationProvider(props: Props) {
  return (
    <IntlProvider key={props.defaultLanguage} locale={props.defaultLanguage} messages={data["en"]}>
      {props.children}
    </IntlProvider>
  );
}