import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from '../../Helfy.Containers/PatientOnBoarding/Styles/slides.module.scss';

interface Props {
  id: number;
  image: any;
  imageAlt: string;
  selected?: number;
  translationId: string;
  setSelected(selection: number): void;
}

export function HelfyImageButton({ id, selected, image, imageAlt, translationId, setSelected }: Props) {

  return (
    <div className={styles.answer}>
      <button type="button" className={id !== selected ? styles.grayScale : ""} onClick={() => setSelected(id)}>
        <div className={styles.answerRow}>
          <p className={id === selected ? styles.selectedText : ""}><FormattedMessage id={translationId} /></p>
          <img className={styles.yesImage} src={image} alt={imageAlt} />
        </div>
      </button>
    </div>
  )
}