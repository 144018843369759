const brandColor = '#71e8cc';

export const singleStyles = {
  container: (provided: any) => ({
    ...provided,
    display: 'inline-block',
    width: '100%',
    minHeight: '1px',
    borderRadius: '10px',
    textAlign: 'left',
    border: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '1px',
    height: '45px',
    boxShadow: 'none',
    fontFamily: '"Exo 2", sans-serif',
    borderRadius: "10px",
    border: "1px solid #e0e0e0",
    borderColor: state.isFocused ? brandColor : provided.borderColor,
    '&:hover': {
      borderColor: "1px solid #71e8cc"
    },
  }),
  input: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: '"Exo 2", sans-serif',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    height: '24px',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    height: '45px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  multiValue: (base: any) => ({
    ...base,
    borderRadius: "13px"
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    fontFamily: '"Exo 2", sans-serif',
  })
};

export const multiSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    display: 'inline-block',
    width: '100%',
    maxWidth: '330px',
    minWidth: '100px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '45px',
    boxShadow: 'none',
    fontFamily: '"Exo 2", sans-serif',
    borderRadius: "10px",
    border: "1px solid #e0e0e0",
    borderColor: state.isFocused ? brandColor : provided.borderColor,
    '&:hover': {
      borderColor: null
    },
  }),
  input: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: '"Exo 2", sans-serif',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  multiValue: (base: any) => ({
    ...base,
    borderRadius: "13px"
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    fontFamily: '"Exo 2", sans-serif',
  })
};