import React from 'react';
import styles from '../Styles/video-session.module.scss';
import icOpen from '../../../Helfy.Assets/Images/ic_chat.svg';
import icPlay from '../../../Helfy.Assets/Images/ic_play.svg';

interface Props {
  show: boolean;

  toggleSidebar(): void;
}

export const ChatOpener = React.memo(({show, toggleSidebar}: Props) => {
  return (
    <div className={styles.chatBtn} onClick={() => toggleSidebar()}>
      <img style={{width: '28px', height: '26px'}} src={show ? icPlay : icOpen} alt="chat icon popup"/>
    </div>
  )
});