import React from 'react';
import { StarRatingComponent } from "./StarRatingComponent";

interface Props {
  rating: number;
  starSpacing?: string | null;
  starDimension?: string | null;
  handleChange?(rating: number): void;
}

export const HelfyRating = React.memo(({rating, handleChange, starDimension, starSpacing}: Props) => {
  return <StarRatingComponent rating={rating} handleChange={handleChange} starSpacing={starSpacing} starDimension={starDimension} />
});