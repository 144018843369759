import React from 'react';
import { NavLink } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { FormattedMessage } from "react-intl";
import styles from '../Styles/counselor-dashboard.module.scss';
import { ChatHolder } from "../../Chat/Components/ChatHolder";
import { ParticipantListContainer } from "../../Participants/Container/ParticipantListContainer";
import { ISelectedRoom } from "../../Chat/Types";
import { useAppSelector } from '../../../Helfy.Store/Hooks';
import { NoSelectedChat } from '../../Chat/Components/NoSelectedChat';

export const ContentHolder = React.memo(({ }) => {
  const selectedRoom: ISelectedRoom = useAppSelector(state => state.participant.selectedRoom);
  const num: Number = useAppSelector(state => state.participant.participants.length); //todo: not so good. we should fix the whole thing. use somerthing better than redux. probaby query? query often until talkjs makes it into a component

  return (
    <div className={styles.desktop}>
      {/* <Row justify="center">
        <Col lg={12} md={12} sm={12} xs={12} xl={12}> */}
      <div className={styles.dashboardSection}>
        <div className={styles.dashboardLeft}>
          <NavLink className={styles.actionContainer} to="/session-request">
            <button className={styles.actionBtn}><FormattedMessage id="dashboard.counselor.my.session.requests" /></button>
          </NavLink>
          <p><FormattedMessage id="dashboard.counselor.my.clients" /></p>
          <ParticipantListContainer />
        </div>
        <div className={styles.dashboardRight}>
          <NoSelectedChat show={!selectedRoom.roomId} isCounselor={true} noChannels={num == 0} />
          {!!selectedRoom.roomId && <ChatHolder />}
        </div>
      </div>
      {/* </Col>
      </Row> */}
    </div>
  )
})

//todo: delete when done with surplus
