import React from 'react';
import styles from './network-status.module.scss';
import { NetworkStatusEnum } from "../../Helfy.Enums/NetworkStatusEnum";

interface Props {
  status: number;
}

export const NetworkStatusIndicator = ({ status }: Props) => {
  return (
    <>
      {{
        [NetworkStatusEnum.Online.intValue]: <div className={`${styles.status} ${styles.online}`} />,
        [NetworkStatusEnum.Offline.intValue]: <div className={`${styles.status} ${styles.offline}`} />,
        [NetworkStatusEnum.InCall.intValue]: <div className={`${styles.status} ${styles.inCall}`} />,
        [NetworkStatusEnum.DoNotDisturb.intValue]: <div className={`${styles.status} ${styles.active}`} />,
      }[status]}
    </>
  );
}