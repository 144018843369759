import * as actions from './Actions';
import { INotificationPreferencesProps } from "../Types";

const initialState: INotificationPreferencesProps = {
  isBusy: false,
  isSuccess: false,
  showError: false,
  errorMessage: "",
  preferences: {
    sendSmsWhenClientContactsMe: false,
    sendEmailWhenClientContactsMe: false,
    sendSmsWithRemindersOfScheduledSession: false,
    sendEmailWithRemindersOfScheduledSession: false,
  }
};

export default (state: INotificationPreferencesProps = initialState, action: any) => {
  switch (action.type) {
    case actions.GET_PREFERENCES_REQUESTED:
      return {...state, isBusy: true};
    case actions.GET_PREFERENCES_SUCCESS:
      return {...state, isBusy: false, preferences: action.payload};
    case actions.GET_PREFERENCES_FAILURE:
      return {...state, showError: true, errorMessage: action.payload, isBusy: false};

    case actions.UPDATE_PREFERENCES_REQUESTED:
      return {...state, isBusy: true};
    case actions.UPDATE_PREFERENCES_SUCCESS:
      return {...state, isBusy: false, isSuccess: true};
    case actions.UPDATE_PREFERENCES_FAILURE:
      return {...state, errorMessage: action.payload, showError: true, isBusy: false};

    case actions.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: ''};
    case actions.CLEAR_STATE:
      return {...state, ...initialState};

    default:
      return state;
  }

}