import React from 'react';
import { Route, Routes } from "react-router-dom";
import WebRtcContextProvider from "../../Helfy.HubOld";
import { HelpSectionContainer } from "../../Helfy.Containers/HelpSection/HelpSectionContainer";
import { NotificationsListContainer } from "../../Helfy.Containers/Notifications/Container/NotificationsListContainer";
import { VideoCallContainer } from "../../Helfy.Containers/VideoCall/Container/VideoCallContainer";
import { PatientAccountContainer } from "../../Helfy.Containers/PatientAccountContainer";
import { SearchCounselorContainer } from "../../Helfy.Containers/SearchCounselors/Container/SearchCounselorContainer";
import { CounselorDetailsContainer } from "../../Helfy.Containers/CounselorDetails/Container/CounselorDetailsContainer";
import { BuyCreditsContainer } from "../../Helfy.Containers/BuyCredits/Container/BuyCreditsContainer";
import { PatientOnBoardingContainer } from '../../Helfy.Containers/PatientOnBoarding/Container/PatientOnBoardingContainer';
import { PatientDashboardContainer } from '../../Helfy.Containers/PatientDashboard/Container/PatientDashboardContainer';
import { PaymentMethodsContainer } from '../../Helfy.Containers/PaymentMethods/Container/PaymentMethodsContainer';
import { TransactionsContainer } from '../../Helfy.Containers/Transactions/Container/TransactionsContainer';
import { PatientNotificationSettings } from '../../Helfy.Containers/PatientNotificationSettings/Container/PatientNotificationSettings';
import { BasicInformationContainer } from '../../Helfy.Containers/BasicInformation/Container/BasicInformationContainer';
import { FaqComponent } from '../../Helfy.Components/Faq/FaqComponent';
import { ContactUsContainer } from '../../Helfy.Containers/ContactUs/Container/ContactUsContainer';
import { HelfyHub } from '../../Helfy.Hub/HelfyHub';
import { PatientProfileContainer } from '../../Helfy.Containers/PatientProfile/Container/PatientProfileContainer';

{/* 
todo: return this
<WebRtcContextProvider>
</WebRtcContextProvider>
  */}

export const PatientLayout = () => {
  //todo: put inside these
  {/* <WebRtcContextProvider>
    </WebRtcContextProvider> */}

  return (
    <HelfyHub>
      <Routes>
        <Route path="/" element={<PatientDashboardContainer />} />
        <Route path="video-call" element={<VideoCallContainer />} />
        <Route path="account" element={<PatientAccountContainer />} >
          <Route path="questionnaire" element={<PatientProfileContainer />} />
          <Route path="payments" element={<PaymentMethodsContainer />} />
          <Route path="transactions" element={<TransactionsContainer />} />
          <Route path="notifications" element={<PatientNotificationSettings />} />
          <Route path="information" element={<BasicInformationContainer />} />
          <Route path="payments/failure" element={<h1>Unable to verify paypal</h1>} />
        </Route>
        <Route path="buy-credits" element={<BuyCreditsContainer />} />
        <Route path="fill-profile" element={<PatientOnBoardingContainer />} />
        <Route path="notifications" element={<NotificationsListContainer />} />
        <Route path="search-counselors" element={<SearchCounselorContainer />} />
        <Route path="counselor-details/:id" element={<CounselorDetailsContainer />} />
        <Route path="help" element={<HelpSectionContainer showBackNavigation />} >
          <Route path="faq" element={<FaqComponent />} />
          <Route path="contact" element={<ContactUsContainer />} />
        </Route>
      </Routes>
    </HelfyHub>
  );
}