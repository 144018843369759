import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from '../Styles/participant.module.scss';

export const NoParticipantsComponent = () => {
  return (
    <div className={styles.noParticipants}>
      <p><FormattedMessage id="participant.list.no.contacts" /></p>
    </div>
  );
}