import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { DetailsLeft } from "../Components/DetailsLeft";
import detailsStyles from '../Styles/details-view.module.scss';
import styles from '../Styles/search-counselor-details.module.scss';
import { RequestSessionModal } from "../Components/RequestSessionModal";
import { BackNavigation } from "../../../Helfy.Components/NavBar/BackNavigation";
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import { HelfySuccessModal } from "../../../Helfy.Components/UIStates/HelfySuccessModal";
import { clearCounselorDetailsError, getCounselorAsync, sendCounselingRequestAsync } from '../Redux/Actions';
import { ReviewsContainer } from '../../Reviews/Container/ReviewsContainer';

export const CounselorDetailsContainer = () => {
	const intl = useIntl();
	const params = useParams()
	const { id } = params
	const parsedId = parseInt(id || "")
	const dispatch = useAppDispatch();
	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const { showError, counselor, errorMessage, isBusy } = useAppSelector(state => state.counselorDetails);

	useEffect(() => {
		async function dummy() {
			await dispatch(getCounselorAsync(parsedId))
		}

		dummy();
	}, [])

	function hideError() {
		dispatch(clearCounselorDetailsError())
	}

	async function requestSessionAsync(message: string) {
		const data = { counselorId: parsedId, message: message };
		const isSent: any = await dispatch(sendCounselingRequestAsync(data));
		if (isSent) {
			setShowModal(false)
			setShowSuccessModal(true);

			// call get counselor again to update the isRequested flag
			await dispatch(getCounselorAsync(parsedId));
		} else {
			setShowModal(false)
		}
	}

	const successTitle = intl.formatMessage({ id: "generic.messages.request.sent.success.title" });
	const successMessage = intl.formatMessage({ id: "generic.messages.request.sent.success.description" });

	return (
		<>
			<BackNavigation title={intl.formatMessage({ id: 'generic.messages.details' })} show />
			<div className={styles.desktop}>
				<div className={styles.mainContentRight}>
					<div className={detailsStyles["details-container"]}>
						<div className={detailsStyles["details-left"]}>
							<DetailsLeft counselor={counselor} />
						</div>
						<div className={detailsStyles["details-right"]}>
							<div className={`${detailsStyles.sec} ${detailsStyles["req-s"]}`}>
								<div className={detailsStyles["price-container"]}>
									{
										counselor.pricePerHour > 0 &&
										<>
											<p className={detailsStyles["credits-top"]}>{counselor.pricePerHour} $</p>
											<p className={detailsStyles["credits-bottom"]}>per hour</p>
										</>
									}
								</div>
								<button disabled={counselor.isRequested} onClick={() => setShowModal(true)} className={detailsStyles.reqButton}>Request session</button>
								<RequestSessionModal show={showModal} isBusy={isBusy} onBackPress={() => setShowModal(false)} acceptRequest={requestSessionAsync} />
							</div>
							<ReviewsContainer counselorId={parsedId} />
							<HelfySuccessModal show={showSuccessModal} title={successTitle} message={successMessage} onConfirmClick={() => setShowSuccessModal(false)} />
							<HelfyErrorModal show={showError} message={errorMessage} onConfirmClick={hideError} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
};