import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import styles from '../../Styles/slides.module.scss';
import { HelfySelect } from "../../../../Helfy.Components/Select/HelfySelect";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { getDaysForMonth, getMonthNames, getYears } from "../../../../Helfy.Utility/HelfyDateUtility";
import { ISteps } from '../../../CounselorOnBoarding/Types';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { setPartialState } from '../../Redux/Reducer';

interface Props extends ISteps {
}

const formatDate = (year: number, month: number, day: number): string => {
	const date = new Date(year, month - 1, day); // Month is 0-based in JavaScript Date object
	return date.toISOString().split('T')[0]; // Get the date part only
}

export const BirthDay = ({ move }: Props) => {
	const intl = useIntl();
	const birthday = useAppSelector(s => s.patientOnBoarding.birthDay)
	let defaultYear = 0
	let defaultMonth = 0
	let defaultDay = 0

	if (birthday) {
		//todo: shoudnt we use some kind of date time type?
		const v = birthday.split("-").map(s => parseInt(s))
		defaultYear = v[0]
		defaultMonth = v[1]
		defaultDay = v[2]
	}

	const [selectedDay, setSelectedDay] = useState(defaultDay);
	const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
	const [selectedYear, setSelectedYear] = useState(defaultYear);

	useEffect(() => {
	}, [])


	const dispatch = useAppDispatch()

	const handleYearChange = (year: any) => {
		setSelectedYear(year.value);
		setSelectedDay(0);
	}

	const handleMonthChange = (month: any) => {
		setSelectedMonth(month.value);
		setSelectedDay(0);
	}

	const handleDayChange = (day: any) => {
		setSelectedDay(day.value);
	}

	const handleSubmit = () => {
		if (selectedYear && selectedMonth && selectedDay) { // Ensure all parts are selected
			const formattedDate = formatDate(selectedYear, selectedMonth, selectedDay);
			dispatch(setPartialState({ birthDay: formattedDate }));
			move(1);
		}
	}

	const years = getYears();
	const year = years.find(i => i.value == selectedYear) || null

	const months = getMonthNames();
	const month = months.find(i => i.value == selectedMonth) || null

	const days = getDaysForMonth(selectedYear, selectedMonth);
	const day = days.find(i => i.value == selectedDay) || null

	return (
		<OnBoardingCardHolder>
			<div className={styles.slide1}>
				<h1 className={styles.intro}><FormattedMessage id="on.boarding.patient.birthday.title" /></h1>
				<div className={styles.birthdayHolder}>
					<HelfySelect
						value={year}
						options={years}
						onChange={handleYearChange}
						className={styles.selectInput}
						placeholder={intl.formatMessage({ id: "on.boarding.patient.birthday.year" })}
					/>
					<HelfySelect
						value={month}
						isDisabled={!selectedYear}
						options={months}
						className={styles.selectInput}
						onChange={handleMonthChange}
						placeholder={intl.formatMessage({ id: "on.boarding.patient.birthday.month" })}
					/>
					<HelfySelect
						value={day}
						onChange={handleDayChange}
						isDisabled={!selectedMonth}
						className={styles.selectInput}
						options={days}
						placeholder={intl.formatMessage({ id: "on.boarding.patient.birthday.day" })}
					/>
				</div>
				<div className={styles.buttonHolder}>
					<SecondaryButton textId="on.boarding.patient.prev.text" onClick={() => move(-1)} />
					<PrimaryButton textId="on.boarding.patient.button.next" onClick={handleSubmit} disabled={!selectedDay || !selectedMonth || !selectedYear || !year || !month || !day} />
				</div>
			</div>
		</OnBoardingCardHolder>
	);
}