import React from 'react';
import { FastField } from "formik";
import { Col, Row } from "react-grid-system";
import { FormattedMessage, useIntl } from "react-intl";
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { HelfyFormHolder } from "../../../Helfy.Layout/HelfyFormHolder";

interface Props {
  isValid: boolean;
  isSubmitting: boolean;

  handleSubmit(): void;
}

export const RequestRestorePasswordView = ({ isSubmitting, isValid, handleSubmit }: Props) => {
  const intl = useIntl();
  return (
    <Col xl={5} lg={5} md={5} xs={12} sm={12}>
      <HelfyFormHolder>
        <p><FormattedMessage id="restore.password.title" /></p>
        <FastField
          type="email"
          name="email"
          id="email"
          component={InputField}
          label={intl.formatMessage({ id: "restore.password.email.label" })}
          placeholder={intl.formatMessage({ id: "restore.password.email.placeholder" })}
        />
        <Row justify="center">
          <PrimaryButton textId="restore.password.button.send" onClick={handleSubmit} disabled={!isValid} isBusy={isSubmitting} />
        </Row>
      </HelfyFormHolder>
    </Col>
  );
}