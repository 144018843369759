import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { FormattedMessage, useIntl } from "react-intl";
import { IPatientNotificationPreferences } from "../Types";
import { Checkbox } from "../../../Helfy.Components/Checkbox/CheckBoxComponent";
import { PrimaryButton } from '../../../Helfy.Components/Buttons/PrimaryButton';
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import { HelfySuccessModal } from "../../../Helfy.Components/UIStates/HelfySuccessModal";
import styles from '../../NotificationsSettings/Styles/counselor-notificaitons.module.scss';
import { clearError, clearState, GET_PREFERENCES_SUCCESS, getPatientPreferencesAsync, updatePatientPreferencesAsync } from "../Redux/Actions";
import { AccountContentHolder } from '../../../Helfy.Layout/Account/AccountContentHolder';


export const PatientNotificationSettings = React.memo(() => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [isEdit, setEdit] = useState(false);
  const text = intl.formatMessage({ id: 'generic.messages.success.modal.text.update' });
  const title = intl.formatMessage({ id: 'generic.messages.success.modal.title.update' });
  const errorTitle = intl.formatMessage({ id: 'generic.messages.error.modal.title' });
  const preferences = useAppSelector(state => state.patientNotificationSettings.preferences);
  const { isBusy, errorMessage, showError, isSuccess } = useAppSelector(state => state.preferences);

  useEffect(() => {
    async function _dummy() {
      await dispatch(getPatientPreferencesAsync());
    }

    _dummy();
  }, []);

  function handleChange(name: string) {
    if (!isEdit) return;
    const copyPreferences: IPatientNotificationPreferences = preferences;
    if (name === "sendEmailOnCounselorContact") copyPreferences.sendEmailOnCounselorContact = !copyPreferences.sendEmailOnCounselorContact;
    if (name === "sendEmailWithReminders") copyPreferences.sendEmailWithReminders = !copyPreferences.sendEmailWithReminders;

    dispatch({ type: GET_PREFERENCES_SUCCESS, payload: copyPreferences });
  }

  async function handleEditMode() {
    if (!isEdit) return setEdit(true);
    await dispatch(updatePatientPreferencesAsync(preferences))
  }

  function _clearError() {
    dispatch(clearError())
  }

  async function handleSuccess() {
    dispatch(clearState());
    await dispatch(getPatientPreferencesAsync());
    setEdit(false);
  }

  return (
    <AccountContentHolder className={`${styles.myAccountContent} ${styles.notifications}`}>
      <HelfySuccessModal show={isSuccess} title={title} message={text} onConfirmClick={handleSuccess} />
      <HelfyErrorModal show={showError} title={errorTitle} message={errorMessage} onConfirmClick={_clearError} />
      <div className={`${styles.row} row1`}>
        <p className="text1"><FormattedMessage id="notification.preferences.send.email.on.contact.by.counselor" /></p>
        <Checkbox handleChange={handleChange} name="sendEmailOnCounselorContact" checked={preferences.sendEmailOnCounselorContact} />
      </div>
      <div className={`${styles.row} row2`}>
        <p className="text1"><FormattedMessage id="notification.preferences.send.email.reminder" /></p>
        <Checkbox handleChange={handleChange} name="sendEmailWithReminders" checked={preferences.sendEmailWithReminders} />
      </div>
      <div className={`${styles.buttonsHolder} ${styles.one}`}>
        <PrimaryButton textId={isEdit ? "generic.messages.save" : "generic.messages.edit"} isBusy={isBusy} onClick={handleEditMode} disabled={isBusy} />
      </div>
    </AccountContentHolder>
  )
});
