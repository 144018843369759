import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';
import { Footer } from "./Footer/Footer";
import { HelpSectionContainer } from "../HelpSection/HelpSectionContainer";
import { LandingPageContainer } from "./Container/LandingPageContainer";
import { CookiePolicyContainer } from "../CookiePolicy/CookiePolicyContainer";
import { TermsAndConditionContainer } from "../TermsAndConditions/TermsAndConditionContainer";
import { ResetPasswordContainer } from "../ResetPassword/Container/ResetPasswordContainer";
import { RequestForgotPasswordContainer } from "../RequestForgotPassword/Container/RequestForgotPasswordContainer";
import { LandingPageHeaderComponent } from "../../Helfy.Components/Header/LandingPageHeader/LandingPageHeaderComponent";
import { AcceptEmailInviteContainer } from "../AcceptEmailInvite/Container/AcceptEmailInviteContainer";
import { ResendEmailContainer } from "../ResendEmail/Container/ResendEmailContainer";
import { FaqComponent } from '../../Helfy.Components/Faq/FaqComponent';
import { ContactUsContainer } from '../ContactUs/Container/ContactUsContainer';
import { AcceptWaitContainer } from '../AcceptWait/Container/AcceptWaitContainer';
import { PrivacyPolicyContainer } from '../PrivacyPolicy/PrivacyPolicyContainer';
import { ResourcesContainer } from '../Resources/ResourcesContainer';

interface Props {
  openLogin: () => void,
  openRegister: () => void
}

export const LandingPageLayout = ({ openLogin, openRegister }: Props) => {
  return (
    <Container fluid={true}>
      <Row nogutter>
        <Col>
          <Routes>
            <Route element={<LandingPageHeaderComponent onLoginClick={openLogin} onRegisterClick={openRegister} />}>
              <Route path="help" element={<HelpSectionContainer />} >
                <Route path="faq" element={<FaqComponent />} />
                <Route path="contact" element={<ContactUsContainer />} />
              </Route>
              <Route path="accept-wait" element={<AcceptWaitContainer />} />
              <Route path="terms" element={<TermsAndConditionContainer />} />
              <Route path="reset-password" element={<ResetPasswordContainer />} />
              <Route path="cookie-policy" element={<CookiePolicyContainer />} />
              <Route path='privacy' element={<PrivacyPolicyContainer />} />
              <Route path='resources' element={<ResourcesContainer />} />
              <Route path="resend-email-verification" element={<ResendEmailContainer />} />
              <Route path="request-password-reset" element={<RequestForgotPasswordContainer />} />
              <Route path="accept-email-invite" element={<AcceptEmailInviteContainer onRegisterClick={openRegister} />} />
              <Route index element={<LandingPageContainer onRegisterClick={openRegister} />} />
              {/* <Route                     path="*" element={<LandingPageContainer />} /> todo:sta s ovime  i to cemo trebati ili neki 404 ili nesto? ili na glavnom to ide?*/}
            </Route>
          </Routes>
        </Col>
      </Row>
      <Row><Col><Footer /></Col></Row>
    </Container>
  );
}
//todo: register i login are hacking passing off bunch of open register close etc functions. maybe a different architecture for popups should be implemented