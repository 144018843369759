export enum PhysicalHealth {
    Bad = 0,
    Average = 1,
    Good = 2
}

export const PhysicalHealthStringKeys: { [key in PhysicalHealth]: string } = {
    [PhysicalHealth.Bad]: "on.boarding.patient.bad",
    [PhysicalHealth.Average]: "on.boarding.patient.average",
    [PhysicalHealth.Good]: "on.boarding.patient.good",
};