import { IntlShape, useIntl } from "react-intl";

export interface HelfyIntlShape extends IntlShape {
    getString: (key: string) => string;
    getEnumString: (key: number | undefined, dict: { [key: number]: string }) => string;
}

export const useHelfyIntl: () => HelfyIntlShape = () => {
    const intl = useIntl();

    return {
        ...intl,
        getString: (k) => intl.formatMessage({ id: k }),
        getEnumString: (k, d) => k !== undefined ? intl.formatMessage({ id: d[k] }) : ""
    }
}