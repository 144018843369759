import React from 'react';
import { Formik } from "formik";
import { HelfyQuestions2View } from "./HelfyQuestions2View";
import { ISteps } from '../../Types';
import { setState } from '../../Redux/Reducer';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { HelfyQuestions2Schema } from './HelfyQuestions2Schema';

interface Props extends ISteps {
}

export const HelfyQuestions2 = (p: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.counselorOnBoarding);

  const handleSubmit = async (values: any) => {
    dispatch(setState(values))
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} validationSchema={HelfyQuestions2Schema} initialValues={state} validateOnMount>
      {props => <HelfyQuestions2View {...props}  {...p} />}
    </Formik>
  )
}