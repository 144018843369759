import Yup from '../../../../Helfy.Validation/HelfyYupLocalizedValidation'

export const ProfileSchema = Yup.object().shape({
  education: Yup.string()
    .required(),
  languages: Yup.array()
    .nullable()
    .min(1)
    .required(),
  pricePerHour: Yup.number()
    .min(10)
    .required(),
  specialties: Yup.array()
    .min(1)
    .nullable()
    .required(),
  // otherSpecialties: Yup.array() //todo:delete if we don't need other specialties
  //   .min(1)
  //   .nullable()
  //   .required(),
});
