import React, { useState } from 'react';
import { Formik } from "formik";
import { Row } from "react-grid-system";
import { useIntl } from "react-intl";
import { RequestRestorePasswordSchema } from "./ValidationSchema";
import { HelfyContainer } from "../../../Helfy.Layout/HelfyContainer";
import { RequestRestorePasswordView } from "../Views/RequestRestorePasswordView";
import { useNavigate } from 'react-router-dom';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { apiSimpleSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';

type FormValues = {
  email: string,
}

//todo: should we use ; after each command or not? should we make a code rule
export const RequestForgotPasswordContainer = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)

  const handleSubmitAsync = async (values: FormValues, actions: any) => apiSimpleSubmitAsync(values, actions, setError, setIsBusy, "/Auth/ForgotPassword", "Forgot Password Requested");

  const handleSuccess = () => {
    navigate("/")
  }

  const text = intl.formatMessage({ id: 'restore.password.success.modal.text' });
  const title = intl.formatMessage({ id: 'restore.password.success.modal.title' });
  const errorTitle = intl.formatMessage({ id: 'restore.password.error.modal.title' });

  const initialValues: FormValues = {
    email: "",
  }

  return (
    <HelfyContainer paddedTop>
      <Row justify="center">
        <Formik validateOnMount validateOnBlur onSubmit={handleSubmitAsync} isInitialValid={false} initialValues={initialValues} validationSchema={RequestRestorePasswordSchema}>
          {props => <RequestRestorePasswordView {...props} />}
        </Formik>
      </Row>
      <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
      <HelfySimpleSuccessModal error={error} title={title} message={text} onConfirmClick={handleSuccess} />
    </HelfyContainer>
  );
}