import React, { useEffect, useState } from 'react';
import styles from '../Styles/video-session.module.scss';

interface Props {
  show: boolean;
  // start: boolean;
  credits: number | undefined;
  // isTrial: boolean;
  counselorHourlyRate: number;
}

export const Timer = ({ show, credits, counselorHourlyRate }: Props) => {
  const [seconds, setSeconds] = useState(0);
  const [creditsLeft, setCreditsLeft] = useState(credits);
  const [cost, setCost] = useState(0);
  const costPerSecond = counselorHourlyRate / 3600;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (show) {
      // If show is true, start the timer
      setSeconds(0);
      intervalId = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        setCost(cost => cost + costPerSecond);

        if (creditsLeft)
          setCreditsLeft(creditsLeft => creditsLeft ? creditsLeft - costPerSecond : creditsLeft);
      }, 1000);
    }
    else {
      setCost(0);
    }


    return () => clearInterval(intervalId);
  }, [show]);  // Added show to dependency array so effect runs when show changes


  if (!show) {
    return <div className={styles.timerHidden} />
  }

  const secondsToHms = (d: number) => {
    d = Number(d);
    if (isNaN(d)) return "Seems that you dont't have credits";

    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h < 10 ? `0${h}:` : `${h}:`;
    let mDisplay = m < 10 ? `0${m}:` : `${m}:`;
    let sDisplay = s < 10 ? `0${s}` : `${s}`;

    return hDisplay + mDisplay + sDisplay;
  }

  return (
    <div className={styles.timer}>
      <div className={styles.timerHeader}>
        <div className={styles.timerColumn}>Duration</div>
        <div className={styles.timerColumn}>Cost</div>
        {creditsLeft && <div className={styles.timerColumn}>Credits</div>}
      </div>
      <div className={styles.timerValues}>
        <div className={styles.timerColumn}>
          {secondsToHms(seconds)}
        </div>
        <div className={styles.timerColumn}>
          {cost.toFixed(2)}$
        </div>
        {creditsLeft && (
          <div className={styles.timerColumn}>
            {creditsLeft.toFixed(2)}$
          </div>
        )}
      </div>
    </div>
  )
}



// interface State {
//   isActive: boolean;
//   totalSeconds: number;
// }

// export class Timer extends React.PureComponent<Props, State> {
//   private decrementer: any;

//   constructor(props: any) {
//     super(props);
//     this.decrementer = null;
//     this.state = {totalSeconds: this._getAvailableTime(), isActive: false};
//     this._decrementSeconds = this._decrementSeconds.bind(this);
//     this._getAvailableTime = this._getAvailableTime.bind(this);
//   }

//   render() {
//     const {totalSeconds} = this.state;
//     if (!this.props.show) return null;
//     return (
//       <div className={styles.timer}>
//         <span className={totalSeconds < 300 ? styles.endingTimerTime : styles.creditTimerTime}>{this._secondsToHms(this.state.totalSeconds)} &nbsp;</span>
//       </div>
//     );
//   }

//   componentDidUpdate(prevProps: any, prevState: any) {
//     if (this.props.start && prevProps.start === false) this.decrementer = setInterval(this._decrementSeconds, 1000);
//     if (!this.props.start && prevProps.start === true) clearInterval(this.decrementer);
//   }

//   componentWillUnmount() {
//     clearInterval(this.decrementer)
//   }

//   _decrementSeconds() {
//     this.setState({totalSeconds: this.state.totalSeconds - 1})
//   }

//   _getAvailableTime(): number {
//     try {
//       const {credits, counselorHourlyRate, isTrial} = this.props;
//       if (isTrial) return 0.25 * 3600; // 15 minutes
//       if (credits <= 0) return 0;
//       if (counselorHourlyRate <= 0) return 0;

//       const totalHours = this.props.credits / this.props.counselorHourlyRate;
//       return totalHours * 3600;
//     } catch (e) {
//       return 0;
//     }
//   }

//   _secondsToHms(d: number) {
//     d = Number(d);
//     if (isNaN(d)) return "Seems that you dont't have credits";

//     let h = Math.floor(d / 3600);
//     let m = Math.floor(d % 3600 / 60);
//     let s = Math.floor(d % 3600 % 60);

//     let hDisplay = h < 10 ? `0${h}:` : `${h}:`;
//     let mDisplay = m < 10 ? `0${m}:` : `${m}:`;
//     let sDisplay = s < 10 ? `0${s}` : `${s}`;

//     return hDisplay + mDisplay + sDisplay;
//   }

// }
