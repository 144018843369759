import React from 'react';
import { useIntl } from 'react-intl';
import styles from '../Styles/search-counselor.module.scss'
import playIc from '../../../Helfy.Assets/Images/ic_play.svg'
import listIc from '../../../Helfy.Assets/Images/ic_list.svg'
import filterIc from '../../../Helfy.Assets/Images/ic_filter.svg'

interface Props {
  showFilter: boolean;
  isGridView: boolean;

  toggleView(): void;

  toggleFilter(): void;
}

export const FilterControls = React.memo(({showFilter, isGridView, toggleFilter, toggleView}: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.controlsContainer}>
      <div className={styles['arrow-left-holder']} onClick={() => toggleFilter()}>
        {showFilter ? <CloseFilterIcon/> : <OpenFilterIcon/>}
      </div>
      <button className={styles['list-view']} onClick={toggleView}>
        <p>{isGridView ? intl.formatMessage({id: "generic.messages.list.view"}) : intl.formatMessage({id: "generic.messages.grid.view"})}</p>
        <img src={listIc} alt="Change icon"/>
      </button>
    </div>
  )
});

export const CloseFilterIcon = React.memo(() => {
  return (
    <div className={styles['arrow-left']}>
      <img src={playIc} alt="Filter icon"/>
    </div>
  )
});

export const OpenFilterIcon = React.memo(() => {
  return (
    <div className={styles.openFilters}>
      <img src={filterIc} alt="Filter icon"/>
    </div>
  )
});