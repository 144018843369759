import axios from 'axios';
import {Dispatch} from "redux";
import {API_URL} from "../../../Helfy.Config/AppSettings";
import {ErrorManager} from "../../../Helfy.Managers/ErrorManager";
import {LogManager} from "../../../Helfy.Managers/LogManager";

export const COUNSELOR_REQUESTS_REQUESTED = "COUNSELOR_REQUESTS_REQUESTED";
export const COUNSELOR_REQUESTS_SUCCESS = "COUNSELOR_REQUESTS_SUCCESS";
export const COUNSELOR_REQUESTS_FAILURE = "COUNSELOR_REQUESTS_FAILURE";


export const GET_COUNSELOR_REQUEST_FAILURE = "GET_COUNSELOR_REQUEST_FAILURE";
export const GET_COUNSELOR_REQUEST_SUCCESS = "GET_COUNSELOR_REQUEST_SUCCESS";
export const GET_COUNSELOR_REQUEST_REQUESTED = "GET_COUNSELOR_REQUEST_REQUESTED";

export const RESPOND_TO_COUNSELING_REQUEST_REQUESTED = "RESPOND_TO_COUNSELING_REQUEST_REQUESTED";
export const RESPOND_TO_COUNSELING_REQUEST_SUCCESS = "RESPOND_TO_COUNSELING_REQUEST_SUCCESS";
export const RESPOND_TO_COUNSELING_REQUEST_FAILURE = "RESPOND_TO_COUNSELING_REQUEST_FAILURE";


export const loadSessionRequestAsync = (page: number, pageSize: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: COUNSELOR_REQUESTS_REQUESTED});
      const params = {page: page, pageSize: pageSize};
      const res = await axios.get(API_URL + "/CounselingRequest/GetCounselingRequests", {params})
      dispatch({type: COUNSELOR_REQUESTS_SUCCESS, payload: res.data});
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      LogManager.logError("Get Counselor request", e);
      dispatch({type: COUNSELOR_REQUESTS_FAILURE, payload: error});
    }
  }
};

export const loadRequestDetailsAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_COUNSELOR_REQUEST_REQUESTED});
      const response = await axios.get(API_URL + "/counselingRequest/GetCounselingRequest/" + id);
      dispatch({type: GET_COUNSELOR_REQUEST_SUCCESS, payload: response.data})
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      dispatch({type: GET_COUNSELOR_REQUEST_FAILURE, payload: error});
      LogManager.logError("Get request details", e)
    }
  }
};

export const respondToCounselingRequest = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: RESPOND_TO_COUNSELING_REQUEST_REQUESTED});
      const res = await axios.post(`${API_URL}/CounselingRequest/RespondToCounselingRequest/`, data);
      dispatch({type: RESPOND_TO_COUNSELING_REQUEST_SUCCESS, payload: res.data});
      return true;
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      dispatch({type: RESPOND_TO_COUNSELING_REQUEST_FAILURE, payload: error});
      LogManager.logError("Response to counseling request", e);
    }
  }
}


/**
 *  Increment session request with signalR
 * @param incrementBy
 */
export const INCREMENT_SESSION_REQUEST_COUNT = "INCREMENT_SESSION_REQUEST_COUNT";
export const incrementSessionRequestCountBy = (incrementBy: number) => (dispatch: Dispatch) => dispatch({type: INCREMENT_SESSION_REQUEST_COUNT, payload: incrementBy});

export const CLEAR_SESSION_REQUEST_STATE = "CLEAR_SESSION_REQUEST_STATE";
export const CLEAR_SESSION_REQUEST_ERRORS = "CLEAR_SESSION_REQUEST_ERRORS";

export const clearSessionRequestState = () => (dispatch: Dispatch) => dispatch({type: CLEAR_SESSION_REQUEST_STATE});
export const clearSessionRequestError = () => (dispatch: Dispatch) => dispatch({type: CLEAR_SESSION_REQUEST_ERRORS});