import * as actions from './Actions';
import { ISpecialitiesProps } from "../Types";

const initialState: ISpecialitiesProps = {
  specialities: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_SPECIALITIES_OPTIONS_REQUESTED:
      return state;
    case actions.LOAD_SPECIALITIES_OPTIONS_SUCCESS:
      return {...state, specialities: action.payload};

    default:
      return state;
  }
}