import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import WebRtcContextProvider from "../../Helfy.HubOld";
import { HelpSectionContainer } from "../../Helfy.Containers/HelpSection/HelpSectionContainer";
import { CounselorOnBoardingContainer } from "../../Helfy.Containers/CounselorOnBoarding/Containers/CounselorOnBoardingContainer";
import { CounselorDashboardContainer } from "../../Helfy.Containers/CounselorDashboard/Container/CounselorDashboardContainer";
import { SessionRequestContainer } from "../../Helfy.Containers/SessionRequest/Container/SessionRequestContainer";
import { NotificationsListContainer } from "../../Helfy.Containers/Notifications/Container/NotificationsListContainer";
import { CounselorAccountContainer } from "../../Helfy.Containers/CounselorAccountContainer/CounselorAccountContainer";
import { VideoCallContainer } from "../../Helfy.Containers/VideoCall/Container/VideoCallContainer";
import { PayoutContainer } from "../../Helfy.Containers/Payout/Container/PayoutContainer";
import { triggerAsyncId } from 'async_hooks';
import { PaymentMethodsContainer } from '../../Helfy.Containers/PaymentMethods/Container/PaymentMethodsContainer';
import { TransactionsContainer } from '../../Helfy.Containers/Transactions/Container/TransactionsContainer';
import { NotificationsSettings } from '../../Helfy.Containers/NotificationsSettings/Container/NotificationSettingsContainer';
import { BasicInformationContainer } from '../../Helfy.Containers/BasicInformation/Container/BasicInformationContainer';
import { CounselorProfileContainer } from '../../Helfy.Containers/CounselorProfile/Container/CounselorProfileContainer';
import { FaqComponent } from '../../Helfy.Components/Faq/FaqComponent';
import { ContactUsContainer } from '../../Helfy.Containers/ContactUs/Container/ContactUsContainer';
import { HelfyHub } from '../../Helfy.Hub/HelfyHub';
import { AcceptWaitContainer } from '../../Helfy.Containers/AcceptWait/Container/AcceptWaitContainer';

export const CounselorLayout = () => {
  return (
    // todo: everything should be under this
    // <WebRtcContextProvider>
    // </WebRtcContextProvider>
    <HelfyHub>
      <Routes>
        <Route path="/" element={<CounselorDashboardContainer />} />
        <Route path="payout" element={<PayoutContainer />} />
        <Route path="account" element={<CounselorAccountContainer />} >
          <Route path="payments" element={<PaymentMethodsContainer />} />
          <Route path="transactions" element={<TransactionsContainer />} />
          <Route path="notifications" element={<NotificationsSettings />} />
          <Route path="information" element={<BasicInformationContainer />} />
          <Route path="questionnaire" element={<CounselorProfileContainer />} />
          <Route path="payments/failure" element={<h1>Unable to verify paypal</h1>} />
          {/* todo: somewhere should be not found of soimethign route */}
        </Route>
        <Route path="video-call" element={<VideoCallContainer />} />
        <Route path="session-request" element={<SessionRequestContainer />} />
        <Route path="fill-profile" element={<CounselorOnBoardingContainer />} />
        <Route path="notifications" element={<NotificationsListContainer />} />
        <Route path="help" element={<HelpSectionContainer showBackNavigation />} >
          <Route path="faq" element={<FaqComponent />} />
          <Route path="contact" element={<ContactUsContainer />} />
        </Route>
      </Routes>
    </HelfyHub>
  );
}
