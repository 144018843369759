//todo delete comments
import * as actions from './Actions';
import { IPatientOnBoarding } from "../Types";
import { createSlice } from '@reduxjs/toolkit';

const initialState: IPatientOnBoarding = {
  gender: 0,
  birthDay: "",
  message: "",
  eatingHabits: undefined,
  mentalHealth: undefined,
  sleepingHabits: undefined,
  physicalHealth: undefined,
  counselingBefore: undefined,
};

const slice = createSlice({
  name: "patientonboarding",
  initialState: initialState,
  reducers: {
    setPartialState: (state, action) => { //todo: this isn't type safe should we change it?
      const newState = {
        ...state,
        ...action.payload
      }
      return newState
    }
  }
})

export const { setPartialState } = slice.actions
export default slice.reducer


// export default (state: IPatientOnBoarding = initialState, action: any) => {
//   switch (action.type) {

//     // Register reducer
//     case actions.SET_PATIENT_ANSWERS_REQUESTED:
//       return {...state, isBusy: true, showError: false, errorMessage: null};
//     case actions.SET_PATIENT_ANSWERS_FAILURE:
//       return {...state, isBusy: false, showError: true, errorMessage: action.payload};
//     case actions.SET_PATIENT_ANSWERS_SUCCESS:
//       return {...state, isBusy: false, isSuccess: true};

//     case actions.SET_PATIENT_ANSWERS_CLEAR_STATE:
//       return {...state, ...initialState};
//     case actions.SET_PATIENT_ANSWERS_CLOSE_ERROR:
//       return {...state, showError: false, errorMessage: ""};

//     // Default
//     default:
//       return state;
//   }
// }