import React from 'react';
import styles from '../Styles/no-transactions.module.scss';
import { ScreenLoadingIndicator } from "../../../Helfy.Components/Loaders/ScreenLoadingIndicator";


export const TransactionLoader = React.memo(() => {
  return (
    <div className={styles.holder}>
      <div className={styles.txt}>
        <ScreenLoadingIndicator show={true} children={null}/>
      </div>
    </div>
  )
});