import React from 'react';
import { useIntl } from "react-intl";
import { Outlet, Route, useMatch } from "react-router-dom";
import { BackNavigation } from "../../Helfy.Components/NavBar/BackNavigation";
import { AccountHolder } from "../../Helfy.Layout/Account/AccountHolder";
import { SidenavListComponent } from "../../Helfy.Components/Sidenav/SidenavListComponent";
import icAccount from '../../Helfy.Assets/Images/ic_account.svg';
import icQuestion from '../../Helfy.Assets/Images/ic_questionnaire.svg';
import icNotify from '../../Helfy.Assets/Images/ic_notifications.svg';
import icPayment from '../../Helfy.Assets/Images/ic_payment.svg';
import icTransact from '../../Helfy.Assets/Images/ic_chat.svg';

export const PatientAccountContainer = React.memo(() => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <BackNavigation title={intl.formatMessage({ id: "generic.messages.my.account" })} navigateTo="/" show />
      <AccountHolder>
        <SidenavListComponent links={getLinks()} />
        <Outlet />
      </AccountHolder>
    </React.Fragment>
  )
});

function getLinks() {
  return (
    [
      {
        icon: icAccount,
        textId: "generic.messages.account.information",
        to: "/account/information"
      },
      {
        icon: icQuestion,
        textId: "generic.messages.account.questionnaire",
        to: "/account/questionnaire"
      },
      {
        icon: icNotify,
        textId: "generic.messages.account.notifications",
        to: "/account/notifications"
      },
      {
        icon: icPayment,
        textId: "generic.messages.account.payments",
        to: "/account/payments"
      },
      {
        icon: icTransact,
        textId: "generic.messages.account.transactions",
        to: "/account/transactions"
      },
      // {
      //   icon: icPrivacy,
      //   textId: "generic.messages.account.privacy",
      //   to: "/account/privacy"
      // },
    ]
  )
}