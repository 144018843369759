import React from 'react';
import { Col, Row } from "react-grid-system";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";


export const TermsAndConditionContainer = () => {
  return (
    <Row justify="center">
      <Col md={8} lg={8} xs={12} sm={12}>
        <h1><FormattedMessage id="terms.heading" /></h1>
        <ol>
          <li>
            The Terms and Conditions
            <p>The following Terms and Conditions (the "Agreement") navigate your access to and use of our online platform (the "Platform"), through which counselling may be provided. The Platform may be accessed through multiple websites or applications, including, but not limited to, the website helfy.life and its related apps, whether owned and/or operated by us or by third parties.</p>
            <p>By accessing or using the Platform, you are agreeing to be bound by this Agreement. Please read the Agreement carefully before using the Platform. If you do not agree to all terms and conditions of this Agreement, you are not authorised to access or use the Platform.</p>
            <p>In this Agreement, the terms "we," "us," and "our" refer to the company that owns and operates the Platform (the "Company").</p>
          </li>
          <li>
            The Counsellors and Counsellor Services
            <p>The Platform may be used to connect you with a Counsellor who will provide services to you through the Platform ("Counselor Services").</p>
            <p>We require every Counsellor providing Counsellor Services on the Platform to be an accredited, trained, and experienced licensed psychologist (PhD / PsyD), licensed marriage and family therapist (LMFT), licensed clinical social worker (LCSW), licensed professional counsellor (LPC), or a similarly accredited professional specific to their country and/or jurisdiction. Counsellors must have a relevant academic degree, at least 3 years of experience, at least 2,000 hours of hands-on experience, and must be qualified and certified by their respective professional boards after successfully completing the necessary education, exams, training, and practice requirements.</p>
            <p>Counsellors are independent providers who are neither employees nor agents nor representatives of our platform. The platform's role is limited to facilitating access to Counsellor Services, while the Counsellor Services themselves are the responsibility of the Counsellor providing them. If the services provided by a Counsellor do not meet your needs or expectations, you are free to switch to another Counsellor who provides services through the Platform.</p>
            <p>While we hope the Counsellor Services are beneficial to you, it is important to understand, agree, and acknowledge that they may not be the appropriate solution for everyone’s needs and may not be a complete substitute for face-to-face examination and/or care in every particular situation.</p>
            <p>IF YOU ARE CONSIDERING SUICIDE, IF YOU ARE CONSIDERING HARMING YOURSELF OR OTHERS, IF YOU BELIEVE THAT ANY OTHER PERSON MAY BE IN DANGER, OR IF YOU HAVE ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL YOUR LOCAL EMERGENCY SERVICE NUMBER (e.g., 911 in the US) AND NOTIFY THE RELEVANT AUTHORITIES. SEEK IMMEDIATE IN-PERSON ASSISTANCE. THE PLATFORM IS NOT DESIGNED FOR USE IN ANY OF THE AFOREMENTIONED CASES, AND THE COUNSELLORS CANNOT PROVIDE THE ASSISTANCE REQUIRED IN SUCH SITUATIONS.</p>
            <p>THE PLATFORM IS NOT INTENDED FOR THE PROVISION OF CLINICAL DIAGNOSES REQUIRING AN IN-PERSON EVALUATION AND SHOULD NOT BE USED IF YOU REQUIRE OFFICIAL DOCUMENTATION OR APPROVALS FOR PURPOSES SUCH AS, BUT NOT LIMITED TO, COURT-ORDERED COUNSELLING OR EMOTIONAL SERVICE DOG CERTIFICATION. IT IS ALSO NOT INTENDED FOR ANY LIFE-THREATENING SITUATIONS OR MEDICAL TREATMENTS. IT MAY NOT BE APPROPRIATE FOR YOUR SITUATION, AND YOU SHOULD NOT DISREGARD SUCH ADVICE IF DELIVERED THROUGH THE PLATFORM.</p>
            <p>DO NOT DISREGARD, AVOID, OR DELAY IN OBTAINING IN-PERSON CARE FROM YOUR DOCTOR OR OTHER QUALIFIED PROFESSIONAL BECAUSE OF INFORMATION OR ADVICE YOU RECEIVED THROUGH THE PLATFORM.</p>
          </li>
          <li>
            Privacy and Security
            <p>Protecting and safeguarding any information you provide through the Platform is extremely important to us. Information about our security and privacy practices can be found on our Privacy Policy available at <NavLink
              to="/privacy">https://www.helfy.life/privacy</NavLink> (The ‘Privacy Policy’).</p>
            <p>BY AGREEING TO THIS AGREEMENT AND/OR BY USING THE PLATFORM, YOU ARE ALSO AGREEING TO THE TERMS OF THE PRIVACY POLICY. THE PRIVACY POLICY IS INCORPORATED INTO AND DEEMED AS PART OF THIS AGREEMENT. THE SAME RULES THAT APPLY TO CHANGES AND REVISIONS OF THIS AGREEMENT ALSO APPLY TO CHANGES AND REVISIONS OF THE PRIVACY POLICY.</p>
          </li>
          <li>
            Third Party Content
            <p>The Platform may contain other content, products, or services that are offered or provided by third parties ("Third Party Content"), as well as links to other websites or advertisements related to Third Party Content. We assume no responsibility for the creation of any Third Party Content, including (but not limited to) any associated products, practices, terms, or policies. We will not be liable for any damage or loss caused by any Third Party Content.</p>
          </li>
          <li>
            Disclaimer of Warranty and Limitation of Liability
            <p>YOU HEREBY RELEASE US AND AGREE TO HOLD US HARMLESS FROM ANY AND ALL CAUSES OF ACTION AND CLAIMS OF ANY NATURE RESULTING FROM THE COUNSELLOR SERVICES OR THE PLATFORM. THIS INCLUDES, BUT IS NOT LIMITED TO, ANY ACT, OMISSION, OPINION, RESPONSE, ADVICE, SUGGESTION, INFORMATION, AND/OR SERVICE PROVIDED BY ANY COUNSELLOR OR ACCESSIBLE THROUGH THE PLATFORM.</p>
            <p>YOU UNDERSTAND, AGREE, AND ACKNOWLEDGE THAT THE PLATFORM IS PROVIDED "AS IS" WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, SECURITY, OR FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY. YOU USE THE PLATFORM AT YOUR OWN RISK, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESSED OR IMPLIED.</p>
            <p>YOU UNDERSTAND, AGREE, AND ACKNOWLEDGE THAT WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES.</p>
            <p>YOU UNDERSTAND, AGREE, AND ACKNOWLEDGE THAT OUR AGGREGATE LIABILITY FOR DAMAGES ARISING WITH RESPECT TO THIS AGREEMENT AND ANY USE OF THE PLATFORM WILL NOT EXCEED THE TOTAL AMOUNT OF MONEY PAID BY YOU THROUGH THE PLATFORM IN THE THREE MONTHS PERIOD PRIOR TO THE DATE OF THE CLAIM.</p>
            <p>If applicable law does not allow the limitation of liability as set forth above, the limitation will be modified solely to the extent necessary to comply with applicable law.</p>
            <p>This limitation of the liability section shall survive the termination or expiration of this agreement.</p>
          </li>
          <li>
            Your account, representations, conduct and commitments
            <p>You hereby confirm that you are legally able to consent to receive Services, or have the consent of a parent or guardian, and that you are legally able to enter into a contract.</p>
            <p>You confirm and agree that all the information you provided in or through the Platform, as well as the information you will provide in the future, is accurate, true, current, and complete. Furthermore, you agree to maintain and update this information during the term of this Agreement to ensure it remains accurate, current, and complete.</p>
            <p>You agree, confirm, and acknowledge that you are responsible for maintaining the confidentiality of your password and any other security information related to your account ("Account Access"). We advise you to change your password frequently and to take extra care in safeguarding your password.</p>
            <p>You agree to notify us immediately of any unauthorised use of your Account Access or any other concern for breach of your account security.</p>
            <p>You agree, confirm, and acknowledge that we will not be liable for any loss or damage incurred as a result of someone else using your account, either with or without your consent and/or knowledge.</p>
            <p>You agree, confirm, and acknowledge that you are solely and fully liable and responsible for all activities performed using your Account Access. You further acknowledge and agree that we will hold you liable and responsible for any damage or loss incurred as a result of the use of your Account Access by any person, whether authorised by you or not, and you agree to indemnify us for any such damage or loss.</p>
            <p>You agree and commit not to use the account or Account Access of any other person for any reason.</p>
            <p>You agree and confirm that your use of the Platform, including the Counselor Services, is for your own personal use only and that you are not using the Platform or the Counselor Services on behalf of any other person or organisation.</p>
            <p>You agree and commit not to interfere with or disrupt, or attempt to interfere with or disrupt, any of our systems, services, servers, networks, or infrastructure, or any of the Platform's systems, services, servers, networks, or infrastructure, including without limitation obtaining unauthorised access to the aforementioned.</p>
            <p>You agree and commit not to make any use of the Platform for the posting, sending, or delivering of either of the following: (a) unsolicited email and/or advertisement or promotion of goods and services; (b) malicious software or code; (c) unlawful, harassing, privacy-invading, abusive, threatening, vulgar, obscene, racist, or potentially harmful content; (d) any content that infringes a third-party right, including intellectual property rights; (e) any content that may cause damage to a third party; (f) any content which may constitute, cause, or encourage a criminal action or violate any applicable law.</p>
            <p>You agree and commit not to violate any applicable local, state, national, or international law, statute, ordinance, rule, regulation, or ethical code in relation to your use of the Platform and your relationship with the Counsellors and us.</p>
            <p>If you receive any file from us or from a Counselor, whether through the Platform or not, you agree to check and scan this file for any virus or malicious software prior to opening or using this file.</p>
            <p>You will indemnify us, defend us, and hold us harmless from and against any and all claims, losses, causes of action, demands, liabilities, costs, or expenses (including, but not limited to, litigation and reasonable attorneys' fees and expenses) arising out of or relating to any of the following: (a) your access to or use of the Platform; (b) any actions made with your account or Account Access whether by you or by someone else; (c) your violation of any of the provisions of this Agreement; (d) non-payment for any of the services (including Counselor Services) which were provided through the Platform; (e) your violation of any third-party right, including, without limitation, any intellectual property right, publicity, confidentiality, property, or privacy right. This clause shall survive expiration or termination of this Agreement.</p>
            <p>You confirm and agree to use only credit cards or other payment means ("Payment Means") which you are duly and fully authorised to use, and that all payment-related information that you provided and will provide in the future, to or through the Platform, is accurate, current, and correct and will continue to be accurate, current, and correct.</p>
            <p>You agree to pay all fees and charges associated with your Account on a timely basis and according to the fees schedule, the terms, and the rates as published on the Platform. By providing us with your Payment Means, you authorise us to bill and charge you through that Payment Means and you agree to maintain valid Payment Means information in your Account information.</p>
            <p>If you have any concerns about a bill or a payment, please contact us immediately by sending an email to <u><a href="mailto:info@helfy.life">info@helfy.life</a></u>. We will evaluate your issue on a case by case basis and, at our discretion, take steps to resolve any issue, including but not limited to helping you find a new Counsellor, and issuing partial or full refunds when applicable.</p>
          </li>
          <li>
            Modifications, Termination, Interruption and Disruptions to the Platform
            <p>You understand, agree, and acknowledge that we may modify, suspend, disrupt, or discontinue the Platform, any part of the Platform, or the use of the Platform, whether to all clients or to you specifically, at any time with or without notice to you. You agree and acknowledge that we will not be liable for any of the aforementioned actions or for any losses or damages that are caused by any of the aforementioned actions.</p>
            <p>The Platform depends on various factors such as software, hardware, and tools, either our own or those owned and/or operated by our contractors and suppliers. While we make commercially reasonable efforts to ensure the Platform’s reliability and accessibility, you understand and agree that no platform can be 100% reliable and accessible and so we cannot guarantee that access to the Platform will be uninterrupted or that it will be accessible, consistent, timely, or error-free at all times.</p>
          </li>
          <li>
            Notices
            <p>We may provide notices or other communications regarding this agreement or any aspect of the Platform to you by email to the email address that we have on record, by regular mail, or by posting it online. The date of receipt will be deemed the date on which such notice is given. Notices sent to us must be delivered by email to <u><a href="mailto:info@helfy.life">info@helfy.life</a></u>.</p>
          </li>
          <li>
            Important notes about our Agreement
            <p>This Agreement and our relationship with you shall both be interpreted solely in accordance with the laws of the State of Delaware, excluding any rules governing choice of laws.</p>
            <p>You irrevocably agree that the exclusive venue for any action or proceeding arising out of or relating to this Agreement or our relationship with you, regardless of theory, shall be the U.S. District Court for the Northern District of California, or the state courts located in Santa Clara County in California. You irrevocably consent to the personal jurisdiction of the aforementioned courts and hereby waive any objection to the exercise of jurisdiction by these courts.</p>
            <p>THIS AGREEMENT CONSTITUTES THE ENTIRE AGREEMENT BETWEEN YOU AND US. YOU CONFIRM THAT YOU HAVE NOT RELIED UPON ANY PROMISES OR REPRESENTATIONS BY US EXCEPT AS SET FORTH IN THIS AGREEMENT.</p>
            <p>We may change this Agreement by posting modifications on the Platform. Unless otherwise specified by us, all modifications shall be effective upon posting. You are encouraged to check the terms of this Agreement frequently. The last update date of this Agreement is posted at the bottom of the Agreement. By using the Platform after the changes become effective, you agree to be bound by such changes to the Agreement. If you do not agree to the changes, you must terminate access to the Platform and cease participation in its services.</p>
            <p>We may freely transfer or assign this Agreement or any of its obligations hereunder.</p>
            <p>The paragraph headings in this Agreement are solely for the sake of convenience and will not be applied in the interpretation of this Agreement.</p>
            <p>If any provision of this Agreement is held by a court of competent jurisdiction to be illegal, invalid, unenforceable, or otherwise contrary to law, the remaining provisions of this Agreement will remain in full force and effect.</p>
            <p>To clarify any doubt, all clauses regarding limitations of liabilities and indemnification shall survive the termination or expiration of this Agreement.</p>
          </li>
        </ol>
      </Col>
    </Row>
  );
}