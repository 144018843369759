import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from '../../Styles/chat.module.scss';

interface Props {
  show: boolean;
  pricePerHour: number;
}

export const CreditsDisplay = React.memo(({pricePerHour, show}: Props) => {
  if (!show) return null;

  return (
    <div className={styles.creditsColumn}>
      <div className={styles.creditsScore}>{pricePerHour}&nbsp;$</div>
      <div className={styles.creditsText}><FormattedMessage id="chat.header.credits.per.hour"/></div>
    </div>
  )
});