import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICounselorOnBoarding, } from '../Types';

const initialState: ICounselorOnBoarding = {
    companyId: "",
    companyName: "",
    counselingStyle: "",
    country: "",
    coverLetter: '',
    detailsAboutInvestigation: "",
    disciplineInvestigations: "",
    education: "",
    email: "",
    estimatedTime: "",
    experienceOnline: "",
    fullName: "",
    gender: null,
    hoursOfWork: "",
    howOftenCheckYourEmail: "",
    languagesOptions: [],
    specialtiesOptions: [],
    languages: [],
    licenseInformation: "",
    linkedInUrl: "",
    notEmployeeDisclaimer: "",
    //otherSpecialties: [], //todo:delete if we don't need other specialties
    pricePerHour: 30,
    profileImage: "",
    provideServiceInAnotherLanguage: "",
    reasonForOnlineCounseling: "",
    referenceContact: "",
    requiredSuperVisioned: "",
    resume: "",
    specialties: [],
    yearsOfPracticing: '',
};

const slice = createSlice({
    name: "counseloronboarding",
    initialState: initialState,
    reducers: {
        setState: (state, action) => { //todo: this isn't type safe should we change it?
            return action.payload
        }
    }
})

export const { setState } = slice.actions
export default slice.reducer