import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { ContentHolder } from "../Components/ContentHolder";
import { DashboardNavBar } from "../../../Helfy.Components/NavBar/DashboardNav";
import { loadAccountInfoAsync } from '../../AccountInfo/Redux/Actions';
import styles from '../Styles/counselor-dashboard.module.scss';

export const CounselorDashboardContainer = () => {
  useEffect(() => {
    //todo: this runs twice for some reason
    loadAccountInfoAsync().then()
  }, [])
  return (
    <>
      <div className={styles.container}>
        <DashboardNavBar />
        <ContentHolder />
      </div>
    </>
  );
}

// const mapStateToProps = (state: RootState) => {
//   return {}
// };

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return bindActionCreators({ getAccountInfoAsync }, dispatch);
// };

// export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CounselorDashboardContainer));

  // async componentDidMount() {
  //   await this.props.getAccountInfoAsync();
  // }
