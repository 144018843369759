import { useState } from "react";

export const useStepState = () => {
    const [step, setStep] = useState(0);
    const [totalSteps, setTotalSteps] = useState(0);

    const move = (numOfSteps: number) => {
        setStep(step + numOfSteps)
    }

    return { step, setStep, move, totalSteps, setTotalSteps }
}
