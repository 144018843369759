export enum EatingHabits {
    Bad = 0,
    Average = 1,
    Good = 2
}

export const EatingHabitsStringKeys: { [key in EatingHabits]: string } = {
    [EatingHabits.Bad]: "on.boarding.patient.bad",
    [EatingHabits.Average]: "on.boarding.patient.average",
    [EatingHabits.Good]: "on.boarding.patient.good",
};