import { getDaysInMonth } from 'date-fns'
import enLocale from 'date-fns/locale/en-US';

export function getCurrentLocale() {
  return enLocale;
}


export function getMonthNames() {
  const months = [];

  for (let i = 0; i < 12; i++) months.push({ value: i + 1, label: getCurrentLocale()?.localize?.month(i) })

  return months;
}

const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => ({ label: start + (i * step), value: start + (i * step) }));

export function getYears() {
  const currentYear = (new Date()).getFullYear();
  return range(currentYear, currentYear - 100, -1);
}

export function getDaysForMonth(year: number, month: number) {
  const days = getDaysInMonth(new Date(year, month - 1));
  return range(1, days, 1);
}