import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from './hd-work-section.module.scss';
import image1 from '../../../../Helfy.Assets/Images/img_hdiw_1.svg';
import image2 from '../../../../Helfy.Assets/Images/img_hdiw_2.svg';
import image3 from '../../../../Helfy.Assets/Images/img_hdiw_3.svg';
import image4 from '../../../../Helfy.Assets/Images/img_hdiw_4.svg';

export class HowDoesItWorkSection extends React.PureComponent {

  render() {
    return (
      <section className={styles.hdWorkSection}>
        <h1><FormattedMessage id="landing.page.hdWorkSection.heading"/></h1>
        <div className={styles.wrapper}>
          <div className={styles.hdWorkCard}>
            <div className={styles.text}><span>1</span>
              <p><FormattedMessage id="landing.page.hdWorkSection.paragraph1"/></p>
            </div>
            <div className={styles.image}>
              <img src={image1} alt="logo icon"/>
            </div>
          </div>
          <div className={styles.hdWorkCard}>
            <div className={styles.text}><span>2</span>
              <p><FormattedMessage id="landing.page.hdWorkSection.paragraph2"/></p>
            </div>
            <div className={styles.image}>
              <img src={image2} alt="logo icon"/>
            </div>
          </div>
          <div className={styles.hdWorkCard}>
            <div className={styles.text}><span>3</span>
              <p><FormattedMessage id="landing.page.hdWorkSection.paragraph3"/></p>
            </div>
            <div className={styles.image}>
              <img src={image3} alt="logo icon"/>
            </div>
          </div>
          <div className={styles.hdWorkCard}>
            <div className={styles.text}><span>4</span>
              <p><FormattedMessage id="landing.page.hdWorkSection.paragraph4"/></p>
            </div>
            <div className={styles.image}>
              <img src={image4} alt="logo icon"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}