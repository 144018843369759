import React from 'react';
import styles from '../Reasons/reasons-section.module.scss';
import { FormattedMessage } from "react-intl";

export class SecondReasonsSection extends React.PureComponent {
  render() {
    return (
      <section className={styles.helfyReasons}>
        <h1><FormattedMessage id="landing.page.secondReasonsSections.heading"/></h1>
        <div className={styles.flex}>
          <div className={styles.helfyCard}>
            <p><FormattedMessage id="landing.page.secondReasonsSections.paragraph1"/></p>
          </div>
          <div className={styles.helfyCard}>
            <p><FormattedMessage id="landing.page.secondReasonsSections.paragraph2"/></p>
          </div>
          <div className={styles.helfyCard}>
            <p><FormattedMessage id="landing.page.secondReasonsSections.paragraph3"/></p>
          </div>
        </div>
      </section>
    );
  }
}