import React, { Children, useEffect } from "react";

interface Props extends React.PropsWithChildren {
    step: number,
    setTotalSteps: (total: number) => void,
    steps: JSX.Element[]
}

//todo: refactor helfysteps
//todo: ne radi dobro sa live refreshom skuziti zasto
export const HelfySteps = ({ step, children, setTotalSteps, steps }: Props) => {
    //const childrenArray = Children.toArray(children);
    const childrenArray = steps;

    useEffect(() => {
        setTotalSteps(childrenArray.length);
    }, [childrenArray.length, setTotalSteps]);

    const child = childrenArray[step];

    return (
        <>
            {child}
        </>)
}

// export const HelfySteps = ({ step, children, setTotalSteps }: Props) => {
//     const a = Children.toArray(children);
//     setTotalSteps(a.length);
//     const el = Children.map(a, (c, i) => {
//         return (
//             step == i && c
//         )
//     });

//     return (
//         <>
//             {el}
//         </>)
// }