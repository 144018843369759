import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import styles from "../../Styles/slides.module.scss";
import badActive from '../../../../Helfy.Assets/Images/img_sleep_bad_active.svg'
import averageActive from '../../../../Helfy.Assets/Images/img_sleep_average_active.svg'
import goodActive from '../../../../Helfy.Assets/Images/img_sleep_good_active.svg'
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { HelfyImageButton } from "../../../../Helfy.Components/Select/HelfyImageButton";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { ISteps } from '../../../CounselorOnBoarding/Types';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { setPartialState } from '../../Redux/Reducer';

interface Props extends ISteps {
}

export const Sleeping = ({ move }: Props) => {
  const selected = useAppSelector(s => s.patientOnBoarding.sleepingHabits)
  const dispatch = useAppDispatch();
  const setSelected = (selected: number) => dispatch(setPartialState({ sleepingHabits: selected }))

  const handleSubmit = () => {
    move(1)
  }

  return (
    <OnBoardingCardHolder>
      <div className={styles.slide4}>
        <h1 className={styles.title}><FormattedMessage id="on.boarding.patient.sleeping.habits.title" /></h1>
        <div className={styles.content}>
          <div className={styles.answersArray}>
            <HelfyImageButton id={0} selected={selected} image={badActive} imageAlt="Bad" translationId="on.boarding.patient.bad" setSelected={setSelected} />
            <HelfyImageButton id={1} selected={selected} image={averageActive} imageAlt="Average" translationId="on.boarding.patient.average" setSelected={setSelected} />
            <HelfyImageButton id={2} selected={selected} image={goodActive} imageAlt="Good" translationId="on.boarding.patient.good" setSelected={setSelected} />
          </div>
        </div>
        <div className={styles.buttonHolder}>
          <SecondaryButton textId="on.boarding.patient.prev.text" onClick={() => move(-1)} />
          <PrimaryButton textId="on.boarding.patient.button.next" onClick={() => move(1)} disabled={selected === undefined} />
        </div>
      </div>
    </OnBoardingCardHolder>
  )
}