import React from 'react';
import { GreenLoader } from "./GreenLoader";

interface Props {
  show: boolean;
  children: any;
}

export const ScreenLoadingIndicator = React.memo(({ show, children }: Props) => {

  if (!show) return children;

  return (
    <div style={{ position: 'relative', display: 'flex', flex: 1, opacity: 0.5, zIndex: 2 }}>
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <GreenLoader height={50} width={50} isWhiteBackGround={true} />
      </div>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }} />
      {children}
    </div>
  );
});
