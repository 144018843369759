import React from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss'
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { TextAreaField } from "../../../../Helfy.Components/Formik/TextAreaField";
import { ISteps } from '../../Types';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
}

export function Questions1View({ move, isValid, handleSubmit }: Props) {
  function onSubmit() {
    handleSubmit();
    move(1);
  }

  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>My Profile Questions 1</h3>
          <div className={styles.profileContent2}>
            <FastField
              id="coverLetterInput"
              name="coverLetter"
              type="text"
              label="Cover Letter"
              placeholder="Cover Letter"
              component={TextAreaField} />
            <FastField
              id="licenceInformationInput"
              name="licenseInformation"
              type="text"
              label="Please provide your licensing information"
              placeholder="Licensing information"
              component={TextAreaField} />
            <FastField
              id="yearsOfPracticingInput"
              name="yearsOfPracticing"
              type="text"
              label="How much time have you been practicing counseling ?"
              placeholder="Years of practicing"
              component={TextAreaField} />
            <FastField
              id="hoursOfWorkInput"
              name="hoursOfWork"
              type="text"
              label="Do you have at least 2000 hours of providing counseling service ?"
              placeholder="2000 hours or more?"
              component={TextAreaField} />
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId="counselor.on.boarding.button.next" onClick={onSubmit} disabled={!isValid} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}