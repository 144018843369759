//todo: trebat ce se 
import { IAccount, IAccountInfo } from "../Types";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IAccount = {
  isBusy: false,
  errorMessage: "",
  showError: false,
  accountInfo: {
    credits: 0,
    fullName: "",
    profilePhoto: "",
    userName: "",
    userHasFilledProfile: false
  }
};
//todoredux ovako to treba recimo ili slicno
const accountInfoSlice = createSlice({
  name: "accountinfo",
  initialState: initialState,
  reducers: {
    setIsBusy: (state, action: PayloadAction<boolean>) => {
      state.isBusy = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      // todo: this should have to be changed when we finally fix this
      state.errorMessage = action.payload || ""
      state.showError = action.payload != null
    },
    setAccountInfo(state, action: PayloadAction<IAccountInfo>) {
      state.accountInfo = action.payload
    }
  }
})

export const { setIsBusy, setError, setAccountInfo } = accountInfoSlice.actions
export default accountInfoSlice.reducer