import storage from 'redux-persist/lib/storage'
import { applyMiddleware, combineReducers, compose } from "redux"
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from "redux-persist";
import { reducers } from './Reducers';

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['app'] // only navigation will be persisted 
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ //todo: dali je ovo potrebno?
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
//todo: dali nam treba persistor tu i tamo? nemam pojma. kada dodjes na termu perzistencije
//https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/
//to cemo viditi kada cemo stvarno testirati perzistiranje.
