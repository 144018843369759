import { useState } from "react";

export function tryParseNumber(value: any, fallback: number = 0): number {
	const parsedValue = Number(value);
	return isNaN(parsedValue) ? fallback : parsedValue;
}

export const getLocalDateString = (date: string) => {
	return new Date(date).toLocaleDateString();
}

export const useHelfyState = () => {
	const [error, setError] = useState<string | null>("")
	const [isBusy, setIsBusy] = useState(false)

	return { error, setError, isBusy, setIsBusy }
}