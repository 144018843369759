import axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { LogManager } from "../../../Helfy.Managers/LogManager";
import { ErrorManager } from "../../../Helfy.Managers/ErrorManager";

export const GET_COUNSELOR_REQUESTED = "GET_COUNSELOR_REQUESTED";
export const GET_COUNSELOR_SUCCESS = "GET_COUNSELOR_SUCCESS";
export const GET_COUNSELOR_FAILURE = "GET_COUNSELOR_FAILURE";

export const COUNSELING_REQUEST_REQUESTED = 'COUNSELING_REQUEST_REQUESTED';
export const COUNSELING_REQUEST_SUCCESS = 'COUNSELING_REQUEST_SUCCESS';
export const COUNSELING_REQUEST_FAILURE = 'COUNSELING_REQUEST_FAILURE';

export const CLEAR_COUNSELOR_DETAILS_ERRORS = "CLEAR_COUNSELOR_DETAILS_ERRORS"

export const getCounselorAsync = (counselorId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_COUNSELOR_REQUESTED});

      const response = await axios.get(API_URL + '/provider/GetCounselor/' + counselorId)
      dispatch({type: GET_COUNSELOR_SUCCESS, payload: response.data})
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      LogManager.logError("Error while fetching counselor", e);
      dispatch({type: GET_COUNSELOR_FAILURE, payload: error});
    }
  }
};

export const sendCounselingRequestAsync = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: COUNSELING_REQUEST_REQUESTED});
      const response = await axios.post(API_URL + '/counselingRequest/PostCounselingRequest/', data)
      dispatch({type: COUNSELING_REQUEST_SUCCESS, payload: response.data})
      return true;
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      LogManager.logError("Error while fetching counselor", e);
      dispatch({type: COUNSELING_REQUEST_FAILURE, payload: error});
      return false;
    }
  }
}

export const clearCounselorDetailsError = () => (dispatch: Dispatch) => dispatch({type: CLEAR_COUNSELOR_DETAILS_ERRORS})
