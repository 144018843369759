import React from 'react';
import { Formik } from "formik";
import { Questions1View } from "./Questions1View";
import { Questions1Schema } from "./Questions1Schema";
import { ISteps } from '../../Types';
import { setState } from '../../Redux/Reducer';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';

interface Props extends ISteps {
}

export const Questions1 = (p: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.counselorOnBoarding);

  const handleSubmit = (values: any) => {
    dispatch(setState(values))
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={state} validationSchema={Questions1Schema} validateOnMount>
      {props => <Questions1View {...props} {...p} />}
    </Formik>
  )
}