import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6)
    .required(),
  repeatPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match') //todo: i've changed this there was a null after yup.ref but it didn't work with the latest version of yup and also i don't know if it's needed or not. so if there is a problem with repeat password then fix it here.
});
