import React from 'react';
import styles from './Styles/button-loaders.module.scss';

interface Props {
	width: number;
	height: number;
	isWhiteBackGround: boolean;
}

export const GreenLoader = React.memo(({ width, height, isWhiteBackGround }: Props) => {
	const color = isWhiteBackGround ? styles.greenBackgroundWhite : styles.green;

	return <div style={{ width, height }} className={`${styles.loader} ${color}`} />
});
