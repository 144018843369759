import React from 'react';
import styles from "../../../Helfy.Components/Notifications/Styles/notification-list-item.module.scss"
import { useNavigate } from 'react-router-dom';
import { HelfyAvatar } from '../../../Helfy.Components/Avatar/HelfyAvatar';
import { NotificationEnum } from '../../../Helfy.Enums/NotificationEnum';
import { ReviewRequest } from '../../../Helfy.Components/Notifications/ReviewRequest';
import { GoToChatButton } from '../../../Helfy.Components/Notifications/GoToChat';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { getTime } from 'date-fns';

interface Props {
    notification: any;
    goToChat(userId: number): any;
    onReviewRequestClick(id: number): any;
}

// Pretvaranje stringa u datumski objekat

export const SessionRequestItem = ({ notification, goToChat, onReviewRequestClick }: Props) => {
    const { id, profilePhoto, chatUserId, displayName, notificationTime, type, title, notificationText } = notification;

    const date = new Date(notificationTime ?? "").toLocaleString();

    return (
        <div className={`${styles["notification-item"]} ${styles.active}`}>
            <div className={styles.top}>
                <div className={styles.left}>
                    <div className={styles.avatar}>
                        <HelfyAvatar fullName={displayName} profilePhoto={profilePhoto} />
                    </div>
                    <p className={styles.name}>{displayName}</p>
                    {/* todo:not sure if i commented this out of it was commented out */}
                    <p className={styles.date}>{date}</p>
                </div>
                <div className={styles.right}>
                    {{
                        [NotificationEnum.SessionRequested]: <ReviewRequest id={id} onClick={onReviewRequestClick} />,
                        [NotificationEnum.SessionModified]: <button className={styles.green}><FormattedMessage id="generic.messages.accept" /></button>,
                        [NotificationEnum.SessionAccepted]: <GoToChatButton goToChat={goToChat} userId={chatUserId} />
                    }[type as number]}
                </div>
            </div>
            <div className={styles.body}>
                {{
                    [NotificationEnum.Standard]: <h3 className={styles.title}>{title}</h3>,
                    [NotificationEnum.SessionRequested]: <h3 className={styles.title}>{title}</h3>,
                    [NotificationEnum.SessionModified]: <h3 className={styles.title}>{title}</h3>,
                    [NotificationEnum.SessionAccepted]: <h3 className={styles.title}>{title}</h3>,
                    [NotificationEnum.SessionRejected]: <h3 className={`${styles.title} ${styles.declined}`}>{title}</h3>,
                }[type as number]}
                {notificationText}
            </div>
        </div>
    );
}
