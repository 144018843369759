import React from 'react';
import styles from './Styles/button-loaders.module.scss';

interface Props {
  show: boolean;
}

export class InputLoader extends React.PureComponent<Props> {

  render() {
    if (!this.props.show) return null;
    return (
      <div className={`${styles.loader} ${styles.greenBackgroundWhite} ${styles.positionRight}`}/>
    );
  }
}