import React from 'react';
import InfiniteScroll from "react-infinite-scroller";
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { ICounselor } from "../Types";
import { loadCounselorsAsync } from "./Redux/Actions";
import styles from '../Styles/search-counselor.module.scss';
import { CounselorGridCard } from "../Components/CounselorGridCard";
import { GreenLoader } from "../../../Helfy.Components/Loaders/GreenLoader";

interface Props {
  show: boolean;
}

export const CounselorGridListView = React.memo(({show}: Props) => {
  const dispatch = useAppDispatch();
  const {isBusy, pageNumber, hasMore, counselors, pageSize} = useAppSelector(state => state.searchCounselors)

  function renderLoader() {
    return (
      <div key={-1} style={{marginBottom: '20px', display: 'flex'}}>
        <GreenLoader isWhiteBackGround={false} width={45} height={45}/>
      </div>
    )
  }

  async function loadMoreAsync(page: number) {
    await dispatch(loadCounselorsAsync(page, pageSize))
  }

  if (!show) return null;

  return (
    <InfiniteScroll threshold={500} pageStart={pageNumber} useWindow={false} hasMore={hasMore} initialLoad={false} loader={renderLoader()} loadMore={loadMoreAsync}>
      <div className={styles['cards-container']}>
        {counselors.map((c: ICounselor, index: number) => <CounselorGridCard counselor={c} key={index}/>)}
      </div>
    </InfiniteScroll>
  )
});


