import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from "react-router-dom";
import { ICounselor } from "../Types";
import styles from '../Styles/cards-counselor.module.scss'
import cardStyle from "../Styles/cards-counselor.module.scss";
import { HelfyRating } from "../../../Helfy.Components/HelfyRating";
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";

interface Props {
  counselor: ICounselor
}

export const CounselorGridCard = React.memo(({ counselor }: Props) => {
  const intl = useIntl();
  return (
    <NavLink to={`/counselor-details/${counselor.id}`}>
      <div className={styles['card-counselor']}>
        <div className={cardStyle.avatar}><HelfyAvatar fullName={counselor.fullName} profilePhoto={counselor.profilePhoto} /></div>
        <div className={cardStyle["stars-container"]}>
          <HelfyRating rating={counselor.overallRating} />
        </div>
        <h3 className={cardStyle.name}>{counselor.fullName}</h3>
        <p className={cardStyle.response}>{counselor.averageResponseTime}</p>
        <div className={cardStyle["price-container"]}>
          <p className={cardStyle["credits-top"]}>{counselor.pricePerHour} $</p>
          <p className={cardStyle["credits-bottom"]}>per hour</p>
        </div>
        <div className={cardStyle.experties}>
          <p className={cardStyle.title}>{intl.formatMessage({ id: "generic.messages.expertises" })}</p>
          <span className={cardStyle.text}>{counselor.specialtiesDisplay} </span>
        </div>
        <div className={cardStyle.languages}>
          <p className={cardStyle.title}>{intl.formatMessage({ id: "generic.messages.languages" })}</p>
          <p className={cardStyle.list}>
            <span className={cardStyle.text}>{counselor.spokenLanguagesDisplay}</span>
          </p>
        </div>
      </div>
    </NavLink>
  )
});