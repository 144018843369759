import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import styles from "../../Styles/slides.module.scss";
import yesCounseling from '../../../../Helfy.Assets/Images/img_yes_counseling.svg'
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import noCounseling from '../../../../Helfy.Assets/Images/img_no counseling_active.svg';
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { HelfyImageButton } from "../../../../Helfy.Components/Select/HelfyImageButton";
import { ISteps } from '../../../CounselorOnBoarding/Types';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { setPartialState } from '../../Redux/Reducer';
import { setState } from '../../../CounselorOnBoarding/Redux/Reducer';

interface Props extends ISteps {
}

export const Counseling = ({ move }: Props) => {
  //todo: null or undefined. figure out for whole project where what. i've been abusing nulls i think
  const selected = useAppSelector(s => s.patientOnBoarding.counselingBefore)
  const dispatch = useAppDispatch();
  const setSelected = (selected: number) => dispatch(setPartialState({ counselingBefore: selected }))

  const handleSubmit = () => {
    move(1)
  }

  return (
    <OnBoardingCardHolder>
      <div className={styles.slide4}>
        <h1 className={styles.title}><FormattedMessage id="on.boarding.patient.counseling.before.title" /></h1>
        <div className={styles.content}>
          <div className={styles.answersArray}>
            <HelfyImageButton id={0} selected={selected} image={noCounseling} imageAlt="No counseling" translationId="on.boarding.patient.counseling.before.bnt.no" setSelected={setSelected} />
            <HelfyImageButton id={1} selected={selected} image={yesCounseling} imageAlt="Yes counseling" translationId="on.boarding.patient.counseling.before.bnt.yes" setSelected={setSelected} />
          </div>
        </div>
        <div className={styles.buttonHolder}>
          <SecondaryButton textId="on.boarding.patient.prev.text" onClick={() => move(-1)} />
          <PrimaryButton textId="on.boarding.patient.button.next" onClick={handleSubmit} disabled={selected === undefined} />
        </div>
      </div>
    </OnBoardingCardHolder>
  )
}