import React, { useState } from 'react';
import { Formik } from "formik";
import { RegisterView } from "../Views/RegisterView";
import { RegisterSchema } from "../Container/ValidationSchema";
import { setCurrentEmailInvite } from "../../../Helfy.App/Redux/Reducer";
import { useNavigate } from 'react-router-dom';
import { getLoading } from '../../../features/emailLookup/emailLookupSlice';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { apiSimpleSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';

interface Props {
  openLogin(): void;
  onCloseClick(): any;
  activeRole: number
}

type FormValues = {
  email: string,
  firstName: string,
  LastName: string,
  password: string
}

export const RegisterStepTwo = ({ openLogin, onCloseClick, activeRole }: Props) => {
  const navigate = useNavigate();
  const showEmailLoader = useAppSelector(getLoading);
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useAppDispatch();

  const onSuccess = (email: string) => {
    dispatch(setCurrentEmailInvite(email))
    navigate("/accept-email-invite")
    onCloseClick()
  }

  const handleSubmitAsync = async (values: any, actions: any) => {
    values.role = activeRole;
    const err = await apiSimpleSubmitAsync(values, actions, setError, setIsBusy, "/Auth/Register", "REGISTER")
    if (err.error !== null) return;

    onSuccess(values.email);
  }

  const initialValues: FormValues = {
    email: "",
    firstName: "",
    LastName: "",
    password: ""
  }

  return (
    <Formik validateOnBlur={true} validateOnMount initialValues={initialValues} onSubmit={handleSubmitAsync} validationSchema={RegisterSchema} enableReinitialize={true}>
      {props => <RegisterView {...props} emailLoading={showEmailLoader} openLogin={openLogin} error={error} onCloseClick={onCloseClick} />}
    </Formik>
  );
}
