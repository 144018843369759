import React from 'react';

import styles from './sidenav.module.scss'
import { ISideNavLinkType } from "./Types/Types";
import { SidenavListItemComponent } from "./SidenavListItemComponent";


interface Props {
  links: Array<ISideNavLinkType>
}

export const SidenavListComponent = ({ links }: Props) => {
  return (
    <div className={styles.sideNavHolder}>
      <ul>
        {links.map((item, i) => <SidenavListItemComponent link={item} key={i} />)}
      </ul>
    </div>
  );
}
