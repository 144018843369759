//todo: completely clean up too much garrbage
import Video, { AudioTrack, Participant, Room, VideoTrack } from 'twilio-video';
import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { useNavigate } from 'react-router-dom';
import { Timer } from "../Components/Timer";
// import { useStartCall } from "../Hooks/StartCall";
import { ISelectedRoom } from "../../Chat/Types";
// import { useHangUpCall } from "../Hooks/HangUpCall";
import { RedButton } from "../Components/RedButton";
// import { clearVideoRoomState } from "../Redux/Actions";
import { IAccountInfo } from "../../AccountInfo/Types";
import { GreenButton } from "../Components/GreenButton";
import styles from '../Styles/video-session.module.scss'
import { RoleEnum } from "../../../Helfy.Enums/RoleEnum";
// import { VideoCallContext } from "../Context/VideoCallContext";
// import { clearSelectedMessages } from "../../Chat/Redux/Actions";
import backgroundImage from "../../../Helfy.Assets/Images/bg_helfy.svg";
// import { LoopingAudioCall } from "../../../Helfy.Components/Audio/LoopingAudio";
// import { RetryCameraAndMicSource } from "../../../Helfy.Components/Popup/RetryCameraAndMicSource";
// import { AnswerButton } from "../Components/AnswerButton";
// import { useDeclineCall } from "../Hooks/DeclineCall";
// import { ParticipantIsConnecting } from "../../../Helfy.Components/PopupNotifications/ParticipantIsConnecting";
import { SidebarChatHolder } from "../Components/SidebarChatHolder";
import { ChatOpener } from "../Components/ChatOpener";
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import axios from 'axios';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { API_URL } from '../../../Helfy.Config/AppSettings';
import { CallButton } from '../Components/CallButton';
import { ToggleCallButton } from '../Components/ToggleCallButton';
import icAudioOn from '../../../Helfy.Assets/Images/ic_audio_on.svg'
import icAudioOff from '../../../Helfy.Assets/Images/ic_audio_off.svg'
import icVideoOn from '../../../Helfy.Assets/Images/ic_video_on.svg'
import icVideoOff from '../../../Helfy.Assets/Images/ic_video_off.svg'
import icBack from '../../../Helfy.Assets/Images/ic_back.svg'

// import { is } from 'date-fns/locale';
import { useSession } from '@talkjs/react';
import { ReviewSessionContainer } from '../../ReviewSession/Container/ReviewSessionContainer';
// import { NoReviews } from '../../Reviews/Components/NoReviews';
// import { setAccountInfo, setError } from '../../AccountInfo/Redux/Reducer';
// import { TransactionViewModel } from '../../Transactions/Types/TransactionViewModel';

type EnterLeaveCallViewModel = {
	roomId: number,
	myId: number,
	hasEntered: boolean
}

type TokenResponse = {
	token: string;
};

const getVideoToken = async (name: string, room: string) => {
	try {
		//todo: redo this this is just for testing
		const address = "/call/token";
		const url = API_URL + address;
		const response = await axios.post<TokenResponse>(url, {
			identity: name,
			room: room
		})
		return response.data.token;
	}
	catch (e) {
		console.log(e);
	}
};

export const VideoCallContainer = React.memo(() => {
	const intl = useIntl();
	const navigate = useNavigate()
	const dispatch = useAppDispatch();
	// const {hangUpCall} = useHangUpCall();
	// const {declineCall} = useDeclineCall();
	// const {onStartVideoCall} = useStartCall()
	// const localVideoRef = React.useRef(null);
	const localMediaDiv = useRef<HTMLDivElement>(null);
	const remoteMediaDiv = useRef<HTMLDivElement>(null);
	// const localVideoRef = useRef<HTMLVideoElement | null>(null);;
	// const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
	// const remoteVideoRef = React.useRef(null);
	const [isSupported, setSupported] = useState(true);
	const [showSidebar, setShowSidebar] = useState(true);
	const role = useAppSelector(state => state.app.role);
	const accountInfo: IAccountInfo = useAppSelector(state => state.account.accountInfo)
	const videoRoom: ISelectedRoom = useAppSelector(state => state.participant.selectedRoom);
	//const id = useAppSelector(state => state.app.userId);
	const id = videoRoom.myId;
	const identity = accountInfo.userName; //todo: mozda treba nesto bolje?
	const roomName = videoRoom.externalId;
	const roomId = videoRoom.roomId;
	const [isAudioOn, setIsAudioOn] = useState(true);
	const [isVideoOn, setIsVideoOn] = useState(true);
	const [isCalling, setIsCalling] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const prevIsConnectedRef = useRef(isConnected);
	const [showReview, setShowReview] = useState(false);
	const [callStateId, setCallStateId] = useState<number | null>(null);
	const [room, setRoom] = useState<Room | null>(null);
	const session = useSession(); // Talk.Session | undefined
	const selectedRoom: ISelectedRoom = useAppSelector(state => state.participant.selectedRoom);

	useEffect(() => {
		if (prevIsConnectedRef.current !== isConnected) {
			if (!isConnected && role === RoleEnum.Patient.stringValue) {
				setShowReview(true);
			}
			prevIsConnectedRef.current = isConnected;
		}
	}, [isConnected])

	// const [localParticipant, setLocalParticipant] = useState<LocalParticipant | null>(null);

	// const {error, isBusy, createPreviewTracks, hasTrackError, stopAllLocalTracks, leaveRoom, answerToCall}: any = useContext(VideoCallContext);
	// const {isCalling, isCallStarted, isOfferedCall, isConnectingToRoom, isAnswerToCallDisabled} = useAppSelector(state => state.videoRoom);

	// useEffect(() => {
	//   setSupported(Video.isSupported);

	//   async function dummy() {
	//     // createPreviewTracks();
	//   }

	//   dummy();

	//   return () => {
	//     stopAllLocalTracks();
	//     dispatch(clearVideoRoomState())
	//     dispatch(clearSelectedMessages())
	//   }
	// }, []);

	// function leaveConversation() {
	//   navigate(-1);
	// }

	const errorMessage = { displayMessage: intl.formatMessage({ id: "generic.messages.not.supported" }) }

	const stopReview = () => setShowReview(false)

	const callEnterLeaveCallApiAsync = async (hasEntered: boolean) => {
		const values: EnterLeaveCallViewModel = {
			hasEntered: hasEntered,
			myId: id,
			roomId: roomId
		}

		const { error, r } = await apiSubmitAsync(() => values, null, null, null, "/CallState/EnterLeaveCall", "Enter leave call", null) //todo: i'm using this maybe its easier to just use axious directly

		//todo: delete comments below we will be doing this through notification via messages
		if (!error) {
			const newCallStateId = r?.data?.callStateId;
			setCallStateId(newCallStateId);
		}

		//const { error, r } = gore await
		// const transaction: TransactionViewModel = r?.data;

		// if (transaction) {
		//   const amountAdded = role === RoleEnum.Patient.stringValue ? -transaction.amount : transaction.amount

		//   accountInfo.credits += amountAdded;
		//   dispatch(setAccountInfo(accountInfo))
		// }

		return { error, r };
	}

	const connectedToRoom = (room: Room) => {
		console.log(`Successfully joined a Room: ${room}`);
		// setLocalParticipant(room.localParticipant);
		setRoom(room);
		callEnterLeaveCallApiAsync(true); //todo: ovo treba pozvati vise puta ili prekinuti poziv

		room.on('participantConnected', participant => {
			console.log(`A remote Participant connected: ${participant}`);
		});

		// Log your Client's LocalParticipant in the Room
		const localParticipant = room.localParticipant;
		console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

		// Log any Participants already connected to the Room
		room.participants.forEach(participant => {
			console.log(`Participant "${participant.identity}" is connected to the Room`);
		});

		if (room.participants.size > 0) { //if more than one participants exists then we are connected
			setIsConnected(true)
		}

		// Log new Participants as they connect to the Room
		room.once('participantConnected', participant => {
			console.log(`Participant "${participant.identity}" has connected to the Room`);
		});

		// Log Participants as they disconnect from the Room
		room.once('participantDisconnected', participant => {
			console.log(`Participant "${participant.identity}" has disconnected from the Room`);
		});

		room.on('participantConnected', participant => {
			setIsConnected(true)
			console.log(`Participant connected: ${participant.identity}`);
		});

		room.on('participantDisconnected', participant => {
			setIsConnected(false)
			console.log(`Participant disconnected: ${participant.identity}`);
		});

		room.on('participantConnected', participant => {
			console.log(`Participant "${participant.identity}" connected`);
			attachParticipant(participant);
		});

		room.on('disconnected', room => {
			// Detach the local media elements
			//detach video
			room.localParticipant.videoTracks.forEach(publication => detachTrack(publication.track));

			//detach audio
			room.localParticipant.audioTracks.forEach(publication => detachTrack(publication.track));
		});

		room.participants.forEach(attachParticipant);

		//attach video tracks
		room.localParticipant.videoTracks.forEach(publication => attachTrack(localMediaDiv, publication.track))

		//attach audio tracks
		room.localParticipant.audioTracks.forEach(publication => attachTrack(localMediaDiv, publication.track))
	};

	const detachTrack = (track: VideoTrack | AudioTrack) => {
		const attachedElements = track.detach();
		attachedElements.forEach(element => element.remove());
	}

	const attachParticipant = (participant: Participant) => {
		participant.on('trackSubscribed', track => {
			attachTrack(remoteMediaDiv, track)
		});

		participant.on('trackUnsubscribed', track => detachTrack(track));
	}

	const attachTrack = (ref: React.RefObject<HTMLDivElement>, track: VideoTrack | AudioTrack) => {
		ref.current?.appendChild(track.attach());
	}

	const connectToRoom = async (token: string) => {
		await Video.connect(token, {
			name: roomName,
			video: true,
			audio: true,
		}).then(connectedToRoom,
			error => {
				console.error(`Unable to connect to Room: ${error.message}`);
			});
	};

	// //todo: i don't know if this is the best way to do this. i am doing this because in debug mode it renders twice and second time i get 2 children for videos. maybe there is a better way to do this?  
	// const [hasAttemptedConnection, setHasAttemptedConnection] = useState(false);

	// useEffect(() => {
	//   if (!hasAttemptedConnection) {
	//     setHasAttemptedConnection(true);
	//     getVideoToken(identity, roomName).then(token => {
	//       if (token) {
	//         connectToRoom(token);
	//       }
	//     });
	//   }

	//   return () => {
	//     const a = 5;
	//   }

	// }, [identity, roomName, hasAttemptedConnection]);

	// useEffect(() => {
	//   return () => {
	//     endCall();
	//   }
	// }, []);

	const endCall = async () => {
		await callEnterLeaveCallApiAsync(false) //todo: ako se ovo ne izvrsi pitanje je dali ce druga strana znati da je gotov poziv. trebamo barem za sada zabraniti da se izvrsi ostatak

		await sendTalkJsMessage(false) //todo: this shouldn't be hardly connected to TalJs create some kind of service pattern and connecting with talkjs somewhere else. the same for control

		if (room) {
			disconnect();
			setRoom(null);
		}

		setIsCalling(false);
		setIsConnected(false);
	};

	const disconnect = () => {
		room?.disconnect();
	}

	const exit = async () => {
		if (isCalling)
			await endCall();

		navigate("/")
	};

	const startCall = async () => {
		await sendTalkJsMessage(true) //todo: this shouldn't be hardly connected to TalJs create some kind of service pattern and connecting with talkjs somewhere else. the same for control
		setIsCalling(true);

		const token = await getVideoToken(identity, roomName)
		if (token)
			connectToRoom(token);
	};

	const toggleVideo = (isVideoOn: boolean) => {
		if (room) {
			room.localParticipant.videoTracks.forEach(publication => {
				if (isVideoOn)
					publication.track.disable();
				else
					publication.track.enable();
			});
		}
	}

	const toggleAudio = (isAudioOn: boolean) => {
		if (room) {
			room.localParticipant.audioTracks.forEach(publication => {
				if (isAudioOn)
					publication.track.disable();
				else
					publication.track.enable();
			});
		}
	}

	const createToggleFunction = (isFirstState: boolean, setState: (isFirstState: boolean) => void, toogleFunction: (isFirstState: boolean) => void) => {
		return () => {
			toogleFunction(isFirstState);
			setState(!isFirstState);
		}
	};

	const sendTalkJsMessage = async (isCalling: boolean) => {
		const start = intl.formatMessage({ id: "generic.messages.incoming.call.started" })
		const end = intl.formatMessage({ id: "generic.messages.incoming.call.ended" })
		const message = isCalling ? intl.formatMessage({ id: "generic.messages.incoming.call.started" }) : intl.formatMessage({ id: "generic.messages.incoming.call.ended" });

		if (session?.isAlive) {
			await session.getOrCreateConversation(roomName).sendMessage(message, { custom: { notificationType: "call" } }); //todo: this should be done way better not hardcodede and maybe even done from backend
			//session.getOrCreateConversation(roomName).sendMessage(isCalling ? start : end, { custom: { notificationType: "call" } }); 
		}
	}

	return (
		<div className={styles.videoCallContainer}>
			<div className={styles.main}>
				<div className={styles.wrapper}>
					<div className={styles.mainContent}>
						<div className={styles.mainScreen}>
							{/* <ParticipantIsConnecting firstName={videoRoom.firstName} lastName={videoRoom.lastName} show={isConnectingToRoom}/>
              <RetryCameraAndMicSource isBusy={isBusy} onActionBtnClick={createPreviewTracks} notificationText={error} show={hasTrackError}/> */}
							<img src={backgroundImage} alt="backgroundImage" />
							<div className={styles.videos}>
								<div ref={remoteMediaDiv} id="remote-media" className={styles.secondaryScreen}>
									{/* todo : make a better timer {role === RoleEnum.Patient.stringValue && <Timer show={isCallStarted} counselorHourlyRate={10} credits={accountInfo.credits} isTrial={videoRoom.isTrial} start={isCallStarted} />} */}
								</div>
								<Draggable nodeRef={localMediaDiv}>
									<div ref={localMediaDiv} id="local-media" className={styles.localScreen} />
								</Draggable>
							</div>
							{/* <LoopingAudioCall play={isCalling} audio={require("../../../Helfy.Assets/Audio/outgoing-call1.mp3")}/> */}
							<div className={styles.bottomRow}>
								<Timer show={isConnected} credits={role === RoleEnum.Patient.stringValue ? accountInfo.credits : undefined} counselorHourlyRate={selectedRoom.pricePerHour} />
								<div className={styles.buttonWrapper}>
									{/* ikonica pokazuje kakvo je stanje sada a text kakvo će biti kada se stisne */}
									<ToggleCallButton onClick={createToggleFunction(isVideoOn, setIsVideoOn, toggleVideo)} isFirstState={isVideoOn} titleId1='generic.messages.video.off' titleId2='generic.messages.video.on' imgSrc1={icVideoOn} imgSrc2={icVideoOff} />
									<ToggleCallButton onClick={createToggleFunction(isAudioOn, setIsAudioOn, toggleAudio)} isFirstState={isAudioOn} titleId1='generic.messages.audio.off' titleId2='generic.messages.audio.on' imgSrc1={icAudioOn} imgSrc2={icAudioOff} />
									{/* <CallButton show={true} onClick={hangupCall} titleId="generic.messages.hang.up" /> */}
									<RedButton show={isCalling} onClick={endCall} titleId="generic.messages.hang.up" />
									<GreenButton show={!isCalling} onClick={startCall} />
									<CallButton show onClick={exit} titleId='chat.header.exit.video.room' imgSrc={icBack} />
									{/* 
                <RedButton show={isCalling} hangUpCall={hangUpCall} titleId="generic.messages.hang.up"/>
                <RedButton show={isOfferedCall} hangUpCall={declineCall} titleId="generic.messages.decline"/>
                <RedButton show={!isCalling && isCallStarted} hangUpCall={leaveRoom} titleId="generic.messages.leave"/>
                <RedButton show={!isCalling && !isCallStarted && !isOfferedCall} hangUpCall={leaveConversation} titleId="generic.messages.leave"/>
                <AnswerButton show={isOfferedCall && !isCallStarted && !isAns      {showReview &      {showReview && <ReviewSession onClose={stopReview} /> as React.ReactElement<{ onClose: () => void }>}ent<{ onClose: () => void }>nPress={answerToCall}/>
                <GreenButton show={!isCalling && !isOfferedCall && !isCallStarted && !isConnectingToRoom} titleId="generic.messages.start.call" onButtonPress={onStartVideoCall}/> 
                */}
								</div>
							</div>
						</div>
						<ChatOpener show={showSidebar} toggleSidebar={() => setShowSidebar(!showSidebar)} />
					</div>
					<SidebarChatHolder show={showSidebar} />
				</div>
			</div>
			{showReview && <ReviewSessionContainer onClose={stopReview} callStateId={callStateId} />}
			<HelfyErrorModal show={!isSupported} message={errorMessage} onConfirmClick={() => setSupported(false)} />
		</div>
	)
});