import { useIntl } from "react-intl";
import { ISessionRequest } from "../Types";
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { CounselingRequestStatusEnum } from "../Config";
import { IParticipant } from "../../Participants/Types";
import styles from '../Styles/session-request.module.scss';
import { NoSessionRequests } from "../Components/NoSessionRequests";
import { DeclineSessionRequest } from "../Components/DeclineSessionRequest";
import { SessionRequestDetails } from "../Components/SessionRequestDetails";
import { HelfyFullHeightContainer } from "../../../Helfy.Layout/HelfyContainer";
import { BackNavigation } from "../../../Helfy.Components/NavBar/BackNavigation";
import { NotificationItem } from "../../../Helfy.Components/Notifications/NotificationItem";
import { HelfySuccessModal } from "../../../Helfy.Components/UIStates/HelfySuccessModal";
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import { clearSessionRequestError, loadRequestDetailsAsync, loadSessionRequestAsync, respondToCounselingRequest } from "../Redux/Actions";
import { loadParticipantsAsync, loadRoomForUserAsync } from "../../Participants/Redux/Actions";
import { SessionRequestItem } from "../Components/SessionRequestItem";


export const SessionRequestContainer = React.memo(() => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [declineMode, setDeclineMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showSuccessAcceptModal, setShowSuccessAcceptModal] = useState(false);
  const [showSuccessDeclineModal, setShowSuccessDeclineModal] = useState(false);
  const participants = useAppSelector(state => state.participant.participants);
  const { requests, isBusy, errorMessage, showError, newSessionRequest, selectedRequest, isModalBusy } = useAppSelector(state => state.sessionRequest);

  useEffect(() => {
    async function _dummy() {
      await dispatch(loadSessionRequestAsync(1, 50));
    }

    _dummy();
  }, []);

  async function goToChat(userId: number) {
    //todo: tu se pak participant gleda da se dobije roomid a poslije se to vraća nazad? glup je cijeli koncept
    const participant = participants.find(p => p.userId === userId);
    if (!participant)
      return;

    await dispatch(loadRoomForUserAsync(participant.roomId));
  }

  async function reviewRequestAsync(id: number) {
    await dispatch(loadRequestDetailsAsync(id));
    setShowModal(true)
  }

  async function refreshAsync() {
    setDeclineMode(false)
    setShowDeclineModal(false);
    await dispatch(loadParticipantsAsync())
    await dispatch(loadSessionRequestAsync(1, 50));
  }

  async function acceptRequest() {
    const request: ISessionRequest = selectedRequest;
    const result = await dispatch(respondToCounselingRequest({ id: request.id, status: CounselingRequestStatusEnum.Accepted.intValue }))
    if (!!result) {
      setShowModal(false);
      setShowSuccessAcceptModal(true);
      await refreshAsync();
    }
  }

  async function rejectRequest(message: string) {
    const request: ISessionRequest = selectedRequest;
    const result = await dispatch(respondToCounselingRequest({ id: request.id, status: CounselingRequestStatusEnum.Rejected.intValue, message }))
    if (!!result) {
      await refreshAsync();
      setShowSuccessDeclineModal(true);
    }
    else
      setShowDeclineModal(false);
  }

  function onBackPress() {
    setShowModal(true);
    setDeclineMode(false);
    setShowDeclineModal(false);
  }

  function promptRejectionMessage() {
    setShowModal(false);
    setDeclineMode(true);
    setShowDeclineModal(true)
  }

  function hideError() {
    dispatch(clearSessionRequestError())
    if (declineMode) setShowDeclineModal(true);
  }

  const successDeclineTitle = intl.formatMessage({ id: "generic.messages.decline.success.title" });
  const successAcceptTitle = intl.formatMessage({ id: "generic.messages.accept.success.title" });
  const successAcceptMessage = intl.formatMessage({ id: "generic.messages.accept.success.description" });
  const successDeclineMessage = intl.formatMessage({ id: "generic.messages.decline.success.description" });

  //todo: skroz je krivo napravljeno ovo. request uopce nije tipa isessionrequest neznam kako to uopce radi? u biti no je notification. 
  //cak i backend vraca notificationviewmmodel? (kao i za notification) a to su dva koncepta - session requests i notification. 
  //oni su tu spojeni ali po meni trebaju biti odvojeni jer su drugaciji tipovi i sluze drugacijijm stvarima.

  return (
    <>
      <BackNavigation title={intl.formatMessage({ id: "sessions.requests.title" })} navigateTo="/" show />
      <HelfyFullHeightContainer>
        <div className={styles.notificationsList}>
          {requests.length === 0 && <NoSessionRequests />}
          {requests.map((item: ISessionRequest, index: number) => <SessionRequestItem goToChat={goToChat} onReviewRequestClick={reviewRequestAsync} key={index} notification={item} />)}
        </div>
        <SessionRequestDetails show={showModal} isBusy={isBusy} isError={showError} reject={promptRejectionMessage} accept={acceptRequest} request={selectedRequest} />
        <DeclineSessionRequest show={showDeclineModal} onBackPress={onBackPress} rejectRequest={rejectRequest} isBusy={isBusy} />
        <HelfySuccessModal show={showSuccessAcceptModal} title={successAcceptTitle} message={successAcceptMessage} onConfirmClick={() => setShowSuccessAcceptModal(false)} />
        <HelfySuccessModal show={showSuccessDeclineModal} title={successDeclineTitle} message={successDeclineMessage} onConfirmClick={() => setShowSuccessDeclineModal(false)} />
        <HelfyErrorModal show={showError} message={errorMessage} onConfirmClick={hideError} />
      </HelfyFullHeightContainer>
    </>
  )
});