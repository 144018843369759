import React from 'react';
import { Label } from "./Components/Label";
import { RowHolder } from "./Components/RowHolder";
import styles from './Styles/row-holder.module.scss'

interface Props {
	id: string;
	field: any;
	form: any,
	label: string;
}

export const TextAreaField = ({ label, id, field, form, ...rest }: Props) => {
	return (
		<RowHolder form={form} field={field}>
			<Label form={form} field={field} label={label} id={id} />
			<textarea className={styles.textArea} id={id}{...field}{...rest} />
		</RowHolder>
	);
}