import axios from "axios";
import React from 'react';
import { useAppSelector } from "../Helfy.Store/Hooks";
import { RootState } from "../Helfy.Store/Store";

export const HelfyAuthorization = () => {
  const token = useAppSelector(state => state.app.authToken);

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return <React.Fragment />
}