import React from 'react';
import { Label } from "./Components/Label";
import { RowHolder } from "./Components/RowHolder";
import { HelfySelect } from "../Select/HelfySelect";
import { singleStyles } from "../Select/Styles";

interface Props {
  id: string;
  options: Array<any>
  form: any;
  field: any;
  errors: any;
  touched: any;
  label: string;
  placeholder: string;
}

export const HelfySelectField = ({ label, id, field, options, form, ...rest }: Props) => {
  const handleChange = (value: any) => {
    form.setFieldValue(field.name, value);
  };

  const handleBlur = () => {
    form.setFieldTouched(field.name);
  };

  return (
    <RowHolder field={field} form={form} >
      <Label id={id} label={label} form={form} field={field} />
      <HelfySelect
        {...rest}
        name={field.name}
        options={options}
        styles={singleStyles}
        onBlur={handleBlur}
        onChange={handleChange}
        value={field.value}
      />
    </RowHolder>
  );
}

