import React from 'react';
import { IParticipant } from "../Types";
import styles from '../Styles/participant.module.scss'
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";
import { NetworkStatusEnum } from "../../../Helfy.Enums/NetworkStatusEnum";
import { NetworkStatusIndicator } from "../../../Helfy.Components/NetworkStatus/NetworkStatusIndicator";

interface Props {
  participant: IParticipant,
  onUserClick(roomId: number): any;
}

export const ParticipantItem = ({ participant, onUserClick }: Props) => {
  const handleSelect = () => {
    onUserClick(participant.roomId)
  }

  const getStylesForUser = () => {
    const color = getColorForStatus(participant.networkStatus);
    return `${styles.participantItem} ${color}`
  }

  return (
    <button onClick={handleSelect} className={styles.participantButton}>
      <div className={getStylesForUser()}>
        <div className={styles.avatar}>
          <HelfyAvatar fullName={participant.name} profilePhoto={participant.profilePhoto || ""} />
          <NetworkStatusIndicator status={participant.networkStatus} />
        </div>
        <p className={styles.counselorName}>{participant.name}</p>
        {participant.noOfMessages > 0 && <div className={styles.newMessage}>{participant.noOfMessages}</div>}
        <span className={styles.dots}>⋮</span>
      </div>
    </button>
  )
}

const getColorForStatus = (status: number) => {
  if (status === NetworkStatusEnum.Online.intValue)
    return styles.online;
  if (status === NetworkStatusEnum.Offline.intValue)
    return styles.offline;
  if (status === NetworkStatusEnum.InCall.intValue)
    return styles.inCall;
  if (status === NetworkStatusEnum.DoNotDisturb.intValue)
    return styles.doNotDisturb;
}