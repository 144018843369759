import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { HelfyAvatar } from "../Avatar/HelfyAvatar";
import styles from './dashboard-navbar.module.scss'
import logo from '../../Helfy.Assets/Images/ic_logo_white.svg'
import icNotify from '../../Helfy.Assets/Images/ic_notifications_white.svg'
import { IAccountInfo } from "../../Helfy.Containers/AccountInfo/Types";
import { useAppSelector } from '../../Helfy.Store/Hooks';

export const DashboardNavBar = () => {
  const [showCredits, setShowCredits] = useState(false)
  const accountInfo = useAppSelector(state => state.account?.accountInfo || {});

  const newNotificationCount = useAppSelector(state => state.notification.newNotificationCount);

  const renderCredits = () => {
    if (showCredits) return <>{accountInfo.credits.toFixed(2)} $</>;
    else return <FormattedMessage id="dashboard.nav.show.credits" />
  }

  const toggleCredits = () => {
    setShowCredits(!showCredits)
  }

  return (
    <nav className={styles.dashboardNav}>
      <div className={styles.navLeft}>
        <NavLink to="/"><img src={logo} alt="Helfy logo" /></NavLink>
        <NavLink to="/help/faq" className={styles.helpLink}><FormattedMessage id="dashboard.nav.help.link" /></NavLink>
      </div>
      <div className={styles.navRight}>
        <button onClick={toggleCredits} className={styles.creditsBtn} title="Credits" name="Credits">
          {renderCredits()}
        </button>
        <NavLink className={styles.iconNotifyContainer} to="/notifications">
          <img src={icNotify} alt="Notification icon" />
          {newNotificationCount > 0 && <div className={styles.newNotification} />}
        </NavLink>
        <NavLink to="/account/information">
          <HelfyAvatar fullName={accountInfo.fullName} profilePhoto={accountInfo.profilePhoto} />
        </NavLink>
      </div>
    </nav>
  );
}