import React from 'react';
import { LoadingCard } from "./LoadingCard";
import styles from "../Styles/search-counselor.module.scss";

interface Props {
  show: boolean;
}

export const SearchCounselorsInitialEmptyState = React.memo(({show}: Props) => {
  if (!show) return null;
  return (
    <div className={styles['cards-container']}>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
      <LoadingCard/>
    </div>
  )
});