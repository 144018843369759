import React from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styles from './sidenav.module.scss';
import { ISideNavLinkType } from "./Types/Types";

interface Props {
  link: ISideNavLinkType
}

export const SidenavListItemComponent = ({ link }: Props) => {
  return (
    <NavLink end to={link.to} className={({ isActive }) => isActive ? styles.active : undefined}>
      <li>
        <img className={styles.icon} src={link.icon} alt={link.icon} />
        <span className="text"><FormattedMessage id={link.textId} /></span>
      </li>
    </NavLink >
  );
}