//todo: delete wjat is not needed.
// todo: općenito cijeli chat folder treba iskasapiti jer se skoor cijeli nepotreban možda?
//možda i nije provkeri
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { ISelectedRoom } from "../Types";
import { useChat } from "../Hooks/ChatHooks";
import styles from '../Styles/chat.module.scss';
import { ChatHeader } from "./Header/ChatHeader";
import { IMessage, IParticipant } from "../../Participants/Types";
import { loadMoreMessagesAsync } from "../Redux/Actions";
import { GreenLoader } from "../../../Helfy.Components/Loaders/GreenLoader";
import { MessageItem } from "./MessageItem";
import { NoChatHistory } from "./NoChatHistory";

import icSend from '../../../Helfy.Assets/Images/ic_send.svg'
import icAttachment from '../../../Helfy.Assets/Images/ic_attachment.svg'
import Talk from 'talkjs';
import { Chatbox } from '@talkjs/react';

interface Props {
  isSidebarChat?: boolean
}

export const ChatHolder = React.memo(({ isSidebarChat }: Props) => {
  const selectedUser: IParticipant = useAppSelector(state => state.participant.selectedUser);
  const userId = useAppSelector(state => state.app.userId);
  const name = useAppSelector(state => state.app.givenName);
  const selectedRoom: ISelectedRoom = useAppSelector(state => state.participant.selectedRoom);
  const [talkLoaded, markTalkLoaded] = useState(false);
  const chatboxEl = useRef<HTMLDivElement>(null);
  const role = useAppSelector(state => state.app.role);

  //todo: remove  commented out old version when ready
  // useEffect(() => {
  //   const initializeTalkJS = async () => {
  //     if (!talkLoaded) {
  //       await Talk.ready;
  //       markTalkLoaded(true);
  //     }

  //     const me = new Talk.User({
  //       id: userId,
  //       name: name,
  //       role: "default"
  //     });

  //     const session = new Talk.Session({
  //       appId: "th85DENk",
  //       me: me
  //     });

  //     const conversation = session.getOrCreateConversation(selectedRoom.externalId);
  //     const chat = session.createChatbox();
  //     chat.select(conversation);
  //     chat.mount(chatboxEl.current);
  //     return () => session.destroy();
  //   }

  //   initializeTalkJS().catch(e => console.error(e));

  // }, [talkLoaded, selectedRoom]); // Just talkLoaded and selectedRoom

  const syncConversation = useCallback((session: Talk.Session) => {
    // regular vanilla TalkJS code here
    const conversation = session.getOrCreateConversation(selectedRoom.externalId);
    //conversation.setParticipant(session.me); //todo: mozda treba?
    return conversation;
  }, [selectedRoom.externalId]);

  //todo: conversationId ili syncconversation? sta je bolje?
  return (
    <>
      <div className={styles.chatContainer}>
        <ChatHeader
          role={role}
          show={!isSidebarChat}
          selectedRoom={selectedRoom}
          id={selectedRoom.participantId}
          lastName={selectedRoom.lastName}
          firstName={selectedRoom.firstName}
          pricePerHour={selectedRoom.pricePerHour}
          hasFreeSession={selectedUser?.hasFreeSession || false}
        />
        <Chatbox
          // conversationId={selectedRoom.externalId}
          className={styles.chatArea}
          syncConversation={syncConversation}
          loadingComponent={<h1>Loading..</h1>}
        />
      </div>
    </>
  )
});



{/* 
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const messageEnd: any = useRef();
  const { isSendButtonDisabled, onMessageSent } = useChat();
  const [message, setMessage] = useState("");
  const role = useAppSelector(state => state.app.role);
  const { forceRender, selectedMessages, hasMoreMessages } = useAppSelector(state => state.chat);

function scrollToBottom() {
    if (!messageEnd) return;
    if (!messageEnd?.current) return;

    messageEnd.current.scrollIntoView({ behavior: "smooth" })
  }

  async function onLocalMessageSend() {
    await onMessageSent(message);
    scrollToBottom();
    setMessage("");
  }

  async function onEnterPress(e: any) {
    if (e.which === 13) await onLocalMessageSend();
  }

  async function getMoreMessages(page: number) {
    await dispatch(loadMoreMessagesAsync(selectedRoom.roomId, page))
    scrollToBottom();
  }

  function renderLoader() {
    return (
      <div key={-1} style={{ marginTop: '20px', display: 'flex' }}>
        <GreenLoader isWhiteBackGround={true} width={45} height={45} />
      </div>
    )
  }




todo: delete when done below and above.
<div className={styles.chatContainer}>
<div className={styles.open}>
  <ChatHeader
    role={role}
    show={!isSidebarChat}
    selectedRoom={selectedRoom}
    id={selectedRoom.participantId}
    lastName={selectedRoom.lastName}
    firstName={selectedRoom.firstName}
    pricePerHour={selectedRoom.pricePerHour}
    hasFreeSession={selectedUser?.hasFreeSession || false}
  />
</div>
<div className={`${styles.chatBody} ${selectedMessages.length > 0 ? styles.receivedMessage : ''} ${isSidebarChat ? styles.isSidebarChat : ''}`}>
  <InfiniteScroll
    threshold={2}
    pageStart={0}
    isReverse={true}
    useWindow={false}
    initialLoad={true}
    loader={renderLoader()}
    key={selectedRoom.roomId}
    hasMore={hasMoreMessages}
    loadMore={getMoreMessages}>
    {selectedMessages.map((item: IMessage, i: number) => <MessageItem myId={selectedRoom.myId} message={item} key={i} />)}
  </InfiniteScroll>
  {selectedMessages.length === 0 && !hasMoreMessages && <NoChatHistory />}
  <div id="messages-end" ref={messageEnd} />
</div>
</div>
<div className={styles.messageBar}>
<button className={styles.attachmentButton} title="Attachment"><img src={icAttachment} alt="Attachment" /></button>
<input value={message} onChange={(e: any) => setMessage(e.target.value)} onKeyPress={onEnterPress} type="text" name="messageToSend" placeholder={intl.formatMessage({ id: "generic.messages.send.message.placeholder" })} />
<button onClick={onLocalMessageSend} disabled={isSendButtonDisabled} className={styles.sendButton} title="Send"><img src={icSend} alt="Send" /></button>
</div> */}
