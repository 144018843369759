import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from "react-router-dom";
import { ICounselor } from "../Types";
import styles from '../Styles/list-counselor.module.scss'
import cardStyle from "../Styles/cards-counselor.module.scss";
import { HelfyRating } from "../../../Helfy.Components/HelfyRating";
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";

interface Props {
  counselor: ICounselor
}

export const CounselorListCard = React.memo(({ counselor }: Props) => {
  const intl = useIntl()

  const { id, profilePhoto, fullName, pricePerHour, specialtiesDisplay } = counselor;
  const { spokenLanguagesDisplay, averageResponseTime, overallRating } = counselor;
  return (
    <NavLink to={`/counselor-details/${id}`}>
      <div className={styles["list-counselor"]}>
        <div className={styles["info-left"]}>
          <div className={styles.avatar}>
            <HelfyAvatar fullName={fullName} profilePhoto={profilePhoto} />
          </div>
          <div className={styles["info-left-block"]}>
            <div className={styles["stars-name"]}>
              <h3 className={styles.name}>{fullName} &nbsp;</h3>
              <div className={cardStyle["stars-container"]}>
                <HelfyRating rating={overallRating} />
              </div>
            </div>
            <p className={styles.response}>{averageResponseTime}</p>
            <div className={styles.experties}>
              <p className={cardStyle.title}>{intl.formatMessage({ id: "generic.messages.expertises" })}</p>
              <p className={styles.list}><span className={styles.text}>{specialtiesDisplay}</span></p>
            </div>
          </div>
        </div>
        <div className={styles["info-right"]}>
          <div className={styles["price-container"]}>
            <p className={styles["credits-top"]}>{pricePerHour} $</p>
            <p className={styles["credits-bottom"]}>per hour</p>
          </div>
          <div className={styles.languages}>
            <p className={styles.title}>{intl.formatMessage({ id: "generic.messages.languages" })}</p>
            <p className={styles.list}><span>{spokenLanguagesDisplay}</span></p>
          </div>
        </div>
        <div>
        </div>
      </div>
    </NavLink>
  )
});