import React from 'react';
import { useIntl } from 'react-intl';
import { CallButton } from './CallButton';
import styles from '../Styles/video-session.module.scss';

interface Props {
    isFirstState: boolean;
    titleId1: string;
    titleId2: string;
    imgSrc1: string;
    imgSrc2: string;
    // buttonStyle1?: string;
    // buttonStyle2?: string;
    onClick(isFirstState: boolean): void;
}

export const ToggleCallButton = ({ isFirstState, onClick, titleId1, titleId2, imgSrc1, imgSrc2 }: Props) => {
    const buttonStyle1 = styles.toggleButtonOn;
    const buttonStyle2 = styles.toggleButtonOff;

    return (
        <CallButton onClick={() => onClick(isFirstState)} show={true} titleId={isFirstState ? titleId1 : titleId2} imgSrc={isFirstState ? imgSrc1 : imgSrc2} buttonStyle={isFirstState ? buttonStyle1 : buttonStyle2} />
    )
};