import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import styles from './back-nav.module.scss'
import backIc from '../../Helfy.Assets/Images/ic_back.svg'

interface Props {
  title: string;
  show?: boolean;
  navigateTo?: string;
}

export const BackNavigation = ({ navigateTo, title, show }: Props) => {
  const navigate = useNavigate();

  if (!show) return null;

  const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!navigateTo) {
      event.preventDefault();

      navigate(-1);
    }
  };

  return (
    <nav className={styles.backNavigation}>
      <NavLink to={navigateTo || "#"} onClick={handleNavigation}>
        <span className={styles.navItem}>
          <img src={backIc} alt="arrow-left" />
          <span className={styles.navItemText}>{title}</span>
        </span>
      </NavLink>
    </nav>
  );
}