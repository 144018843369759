//todo: probably delete all of this
import axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { ErrorManager } from "../../../Helfy.Managers/ErrorManager";
import { LogManager } from "../../../Helfy.Managers/LogManager";
import { SET_USER_HAS_FILLED_PROFILE } from "../../../Helfy.App/Redux/Actions";
import { apiGetPostAsync } from "../../../Helfy.Api/Helpers/Helpers";
import { RootState } from "../../../Helfy.Store/Store";
import { IParticipant } from "../../Participants/Types";
import { setPartialState } from "./Reducer";

// export const SET_PATIENT_ANSWERS_SUCCESS = "SET_PATIENT_ANSWERS_SUCCESS";
// export const SET_PATIENT_ANSWERS_FAILURE = "SET_PATIENT_ANSWERS_FAILURE";
// export const SET_PATIENT_ANSWERS_REQUESTED = "SET_PATIENT_ANSWERS_REQUESTED";

// export const SET_PATIENT_ANSWERS_CLOSE_ERROR = "SET_PATIENT_ANSWERS_CLOSE_ERROR";
// export const SET_PATIENT_ANSWERS_CLEAR_STATE = "SET_PATIENT_ANSWERS_CLEAR_STATE";
// export const closeError =  () => (dispatch:Dispatch) => dispatch({type: SET_PATIENT_ANSWERS_CLOSE_ERROR});
// export const clearState =  () => (dispatch:Dispatch) => dispatch({type: SET_PATIENT_ANSWERS_CLEAR_STATE});

//todo: di ovo staviti u akcije ili u samu komponentu? ili taj react query...
export const loadAnswersAsync = (setError: (error: string | null) => void, setIsBusy: (isBusy: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    //todo: nema errora ako se dobro ne učita
    await apiGetPostAsync(true, null, null, setError, setIsBusy, "/Account/GetPatientOnBoardingAnswers/", "GetPatientOnboardingAnswers", (r) => {
      dispatch(setPartialState(r.data))
      return null
    })
  }
}
// export const setAnswersAsyncOld = (data: any) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       dispatch({type: SET_PATIENT_ANSWERS_REQUESTED});
//       await axios.post(`${API_URL}/Account/SetPatientOnBoardingQuestions`, data);
//       dispatch({type: SET_PATIENT_ANSWERS_SUCCESS});
//       dispatch({type: SET_USER_HAS_FILLED_PROFILE});
//       return true;
//     } catch (e) {
//       const error: any = ErrorManager.getErrorMessageForDisplay(e);
//       LogManager.logError("SET_PATIENT_ANSWERS", error);
//       dispatch({type: SET_PATIENT_ANSWERS_FAILURE, payload: error});
//       return false;
//     }
//   }
// };
