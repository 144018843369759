import React from 'react';
import styles from './on-boarding-card-holder.module.scss';

interface Props extends React.PropsWithChildren {
}

export const OnBoardingCardHolder = ({ children }: Props) => {
  return (
    <>
      <div className={styles.carouselCard}>
        <div className={styles.carousel}>
          <div className={styles.slide}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}
