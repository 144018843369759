import React from 'react';
import { useIntl } from 'react-intl';
import styles from "../Styles/search-counselor.module.scss";
import noResult from '../../../Helfy.Assets/Images/img_no_results.svg'

interface Props {
  show: boolean;
}

export const NoCounselors = React.memo(({show}: Props) => {
  const intl = useIntl()

  if (!show) return null;

  return (
    <div className={styles["central-img-container"]}>
      <div className={styles["img-text"]}>
        <img src={noResult} alt="No results"/>
        <p>{intl.formatMessage({id: "generic.messages.no.counselors.search"})}</p>
      </div>
    </div>
  )
});