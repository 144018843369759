import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Required field'
  },
  string: {
    email: 'Must be valid email address',
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Minimum ${min} characters!',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Maximum ${max} characters!'
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Minimum value is ${min}!',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Minimum value is ${max}!'
  },
  array: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Minimum ${min} item selected!',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Maximum ${max} items selected!'
  }
});

export default Yup;