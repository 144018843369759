import React from 'react';
import styles from './modal.module.scss';
import icClose from '../../Helfy.Assets/Images/ic_close.svg';

interface Props {
  show: boolean;
  text: string;
  onCloseClick(): void;
}

export const ModalHeader = ({ show, text, onCloseClick }: Props) => {
  if (!show) return null;
  return (
    <div className={styles.modalHeader}>
      <div className={styles.firstHeader}><h2>{text}</h2></div>
      <div className={styles.closeIconHolder}>
        <span><img src={icClose} alt="Close icon" onClick={onCloseClick} /></span>
      </div>
    </div>
  );
}