import { useIntl } from "react-intl";
import React, { useState } from 'react';
import { Field, FormikProps } from "formik";
import styles from '../Styles/basic-info.module.scss';
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { ScreenLoadingIndicator } from "../../../Helfy.Components/Loaders/ScreenLoadingIndicator";
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";
import { useNavigate } from "react-router-dom";

interface Props extends FormikProps<any> {
}

export const BasicInformationComponent = React.memo(({ isSubmitting, values, submitForm, isValid }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate()
  const [isEdit, setEdit] = useState(false);
  const [btnText, setBtnText] = useState("basic.information.edit.account.btn.text");

  function logOut() {
    navigate("/logout")
  }

  async function handleChange() {
    if (isEdit)
      await onSubmit();
    else
      handleEdit()
  }

  function handleEdit() {
    setEdit(true);
    setBtnText("generic.messages.save.changes");
  }

  async function onSubmit() {
    await submitForm();
    setBtnText("basic.information.edit.account.btn.text");
    setEdit(false);
  }

  return (
    <div className={styles["my-account-content"]}>
      <div className={styles["info-content"]}>
        <ScreenLoadingIndicator show={isSubmitting}>
          <div className={styles.left}>
            <div className={`${styles.info}`}>
              <Field
                id="firstNameInput"
                name="firstName"
                type="text"
                label="First name"
                disabled={!isEdit}
                placeholder="First name"
                component={InputField}
              />
              <Field
                id="lastNameInput"
                name="lastName"
                type="text"
                label="Last name"
                disabled={!isEdit}
                placeholder="Last name"
                component={InputField}
              />
              <Field
                id="emailInput"
                name="email"
                type="email"
                label="Email"
                disabled={true}
                placeholder="Email"
                component={InputField} />
            </div>
            <div className={styles["sign-out"]}>
              <button className={styles["btn-sign-out"]} onClick={logOut}>
                <p>{intl.formatMessage({ id: "generic.messages.sign.out" })}</p>
              </button>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.picture}>
              <div className="avatar-img">
                <HelfyAvatar fullName={`${values.firstName} ${values.lastName}`} profilePhoto={values.profilePhoto} />
              </div>
            </div>
          </div>
        </ScreenLoadingIndicator>
      </div>
      <div className={`${styles["buttons-holder"]} ${styles.one}`}>
        <PrimaryButton textId={btnText} isBusy={isSubmitting} onClick={handleChange} disabled={!isValid || isSubmitting} />
      </div>
    </div>
  )
});
