import axios from 'axios';
import { Dispatch } from "redux";
// @ts-ignore
import fileDownload from 'js-file-download';
import { LogManager } from "../../../Helfy.Managers/LogManager";
import { API_URL } from "../../../Helfy.Config/AppSettings";

export const LOAD_TRANSACTIONS_REQUESTED = "LOAD_TRANSACTIONS_REQUESTED";
export const LOAD_TRANSACTIONS_SUCCESS = "LOAD_TRANSACTIONS_SUCCESS";
export const LOAD_TRANSACTIONS_FAILURE = "LOAD_TRANSACTIONS_FAILURE";


export const loadTransactionsAsync = (query: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: LOAD_TRANSACTIONS_REQUESTED});
      const response = await axios.get(API_URL + '/Transactions/GetTransactions', {params: query});
      dispatch({type: LOAD_TRANSACTIONS_SUCCESS, payload: response.data})
    } catch (e) {
      dispatch({type: LOAD_TRANSACTIONS_FAILURE, payload: e});
      LogManager.logError("LoadTransactions", e)
    }
  }
};

export const downloadHistoryAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(API_URL + '/Transactions/DownloadHistory', {responseType: 'arraybuffer'});
      fileDownload(response.data, 'Helfy transactions history.xlsx');
    } catch (e) {
      LogManager.logError("LoadTransactions", e)
    }
  }
};