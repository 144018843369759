import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from './cookie-policy.module.scss'

export const CookiePolicyContainer = () => {
  return (
    <div className={styles.container} data-testid="cookie-policy-container">
      <h1><FormattedMessage id="cookie.policy.title" /></h1>
      <p><FormattedMessage id="cookie.policy.lastUpdate" values={{ lastUpdate: "June 06, 2024" }} /></p>
      <p><FormattedMessage id="cookie.policy.paragraph1" /></p>
      <p><FormattedMessage id="cookie.policy.paragraph2" /></p>
      <h2><FormattedMessage id="cookie.policy.heading2" /></h2>
      <p><FormattedMessage id="cookie.policy.paragraph3" /></p>
      <p><FormattedMessage id="cookie.policy.paragraph4" /></p>
      <h2><FormattedMessage id="cookie.policy.heading3" /></h2>
      <p><FormattedMessage id="cookie.policy.paragraph5" /></p>
      <p><FormattedMessage id="cookie.policy.paragraph6" /></p>
      <ul>
        <li>
          <p><FormattedMessage id="cookie.policy.paragraph7" /></p>
          <p><FormattedMessage id="cookie.policy.paragraph8" /></p>
          <p><FormattedMessage id="cookie.policy.paragraph9" /></p>
        </li>
      </ul>

      <h2><FormattedMessage id="cookie.policy.heading4" /></h2>
      <p>
        <FormattedMessage id="cookie.policy.paragraph10" /> &nbsp;
        <a href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR">
          <FormattedMessage id="cookie.policy.anchor1" />
        </a>.
      </p>
      <p><FormattedMessage id="cookie.policy.paragraph11" /></p>
      <ul>
        <li>
          <p><FormattedMessage id="cookie.policy.paragraph12" />
            <a href="https://support.google.com/accounts/answer/32050">&nbsp;
              <FormattedMessage id="cookie.policy.anchor2" />
            </a>
          </p>
        </li>
        <li>
          <p>
            <FormattedMessage id="cookie.policy.paragraph13" />
            <a href="http://support.microsoft.com/kb/278835">&nbsp;
              <FormattedMessage id="cookie.policy.anchor3" />
            </a>
          </p>
        </li>
        <li>
          <p><
            FormattedMessage id="cookie.policy.paragraph14" />&nbsp;
            <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
              <FormattedMessage id="cookie.policy.anchor4" />
            </a>
          </p>
        </li>
        <li>
          <p>
            <FormattedMessage id="cookie.policy.paragraph15" />
            <a href="https://support.apple.com/kb/PH21411?locale=en_US">
              <FormattedMessage id="cookie.policy.anchor4" />
            </a>
          </p>
        </li>
        <li>
          <p><FormattedMessage id="cookie.policy.paragraph16" /></p>
        </li>
      </ul>

      <h2><FormattedMessage id="cookie.policy.heading5" /></h2>

      <p><FormattedMessage id="cookie.policy.paragraph17" /></p>

      <ul>
        <li>
          <p><FormattedMessage id="cookie.policy.paragraph18" />
            <a href="http://www.allaboutcookies.org/">&nbsp;
              <FormattedMessage id="cookie.policy.anchor6" />
            </a></p>
        </li>
      </ul>
    </div>
  );
}