import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { EnsureUserHasFilledProfileGuard } from '../../Helfy.Guards/EnsureUserHasFilledProfile';
import { LogoutView } from '../Logout/View/LogoutView';
import { RoleEnum } from '../../Helfy.Enums/RoleEnum';
import { PatientLayout } from '../../Helfy.App/Layouts/PatientLayout';
import { LandingPageLayout } from '../LandingPage/LandingPageLayout';
import { HelfyAuthorization } from '../../Helfy.Boostrap/HelfyAuthorization';
import { CounselorLayout } from '../../Helfy.App/Layouts/CounselorLayout';
import { useAppSelector } from '../../Helfy.Store/Hooks';
import { LoginContainer } from '../Login/Container/LoginContainer';
import { RegisterContainer } from '../Register/Container/RegisterContainer';
import { Navigate } from 'react-router-dom';
import { PrivacyPolicyContainer } from '../PrivacyPolicy/PrivacyPolicyContainer';

export const MainPage = () => {
    const isAuthenticated = useAppSelector(s => s.app.isAuthenticated)
    const role = useAppSelector(s => s.app.role)

    const navigate = useNavigate();
    const { search } = useLocation();
    const [showLogin, setShowLogin] = useState(false)
    const [showRegister, setShowRegister] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(search);
        const loginParam = (params.get('login') ?? "").toLowerCase() === "true";
        const acceptWait = (params.get('accept-wait') ?? "").toLowerCase() === "true";

        setShowLogin(loginParam);
        if (acceptWait)
            navigate("/accept-wait")
    }, [search]);

    const openLogin = () => {
        setShowLogin(true)
        setShowRegister(false)
    }

    const openRegister = () => {
        setShowRegister(true)
        setShowLogin(false)
    }

    const closeLogin = () => setShowLogin(false);
    const closeRegister = () => setShowRegister(false);

    const main =
        <EnsureUserHasFilledProfileGuard>
            {isAuthenticated && role === RoleEnum.Patient.stringValue && <PatientLayout />}
            {isAuthenticated && role === RoleEnum.Counselor.stringValue && <CounselorLayout />}
            {!isAuthenticated && <LandingPageLayout openLogin={openLogin} openRegister={openRegister} />}
            {isAuthenticated && <HelfyAuthorization />}
        </EnsureUserHasFilledProfileGuard>

    //todo: pogledaj dali ima jos neke rute koje smo bezveze troplicirali na sva 3 layouta?    
    const MainPageRoot = () => {
        return (
            <Routes>
                <Route path="*" element={main} />
            </Routes>
        )
    }

    // const LoginView = () => {
    //     return (
    //         <Navigate to="/?login=true" />
    //     )
    // }

    return (
        <>
            <Routes>
                <Route path="logout" element={<LogoutView />} />
                {/* <Route path="login" element={<LoginView />} /> */}
                <Route path="*" element={<MainPageRoot />} />
            </Routes>
            {showLogin && <LoginContainer openRegister={openRegister} onCloseClick={closeLogin} />}
            {showRegister && <RegisterContainer openLogin={openLogin} onCloseClick={closeRegister} />}
        </>)
}