import axios from 'axios';
import { API_URL } from "../../../../Helfy.Config/AppSettings";
import { LogManager } from "../../../../Helfy.Managers/LogManager";
import { ErrorManager } from "../../../../Helfy.Managers/ErrorManager";
//todo: make this better. no need for 2 functions also

export const CLEAR_COUNSELORS = 'CLEAR_COUNSELORS';

export const LOAD_COUNSELORS_REQUESTED = 'LOAD_COUNSELORS_REQUESTED';
export const LOAD_COUNSELORS_SUCCESS = 'LOAD_COUNSELORS_SUCCESS';
export const LOAD_COUNSELORS_ERROR = 'LOAD_COUNSELORS_ERROR';

export const loadCounselorsAsync = (pageNumber: number, pageSize: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOAD_COUNSELORS_REQUESTED });
      const response = await axios.get(API_URL + '/provider/getCounselors', { params: { page: pageNumber, pageSize: pageSize } });
      dispatch({ type: LOAD_COUNSELORS_SUCCESS, payload: response.data });
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      dispatch({ type: LOAD_COUNSELORS_ERROR, payload: error })
      LogManager.logError("Error while loading counselors", e)
    }
  }
};


export const searchCounselorsAsync = (data: any, shouldCleanAllCounselors: boolean) => {
  return async (dispatch: any) => {
    try {
      const query = {
        gender: data.gender?.value,
        rating: data.rating,
        priceTo: data.priceTo,
        page: data.pageNumber,
        pageSize: data.pageSize,
        priceFrom: data.priceFrom,
        languageId: data.language?.value,
        specialtyId: data.specialty?.value,//todo: so far we allow only one language and specialty not sure if we should do OR or AND if we allow
        searchTerm: data.searchTerm,
      };

      if (shouldCleanAllCounselors) dispatch({ type: CLEAR_COUNSELORS }); //todo: izgleda da ih prvo brišemo i onda ih učitavamo. ne kužim zašto. ovo je definitivno kandidat za react query

      dispatch({ type: LOAD_COUNSELORS_REQUESTED });

      const response = await axios.get(API_URL + '/provider/getCounselors', { params: query });
      dispatch({ type: LOAD_COUNSELORS_SUCCESS, payload: response.data })
    } catch (e) {
      const error = ErrorManager.transformErrorMessages(e);
      dispatch({ type: LOAD_COUNSELORS_ERROR, payload: error })
      LogManager.logError("Error while searching counselors", e)
    }
  }
};

