import { HelfyHubContext, initialState } from "./HelfyHubContext";
import React, { useCallback, useEffect, useState } from "react";
import { Session } from "@talkjs/react";
import Talk from "talkjs";
import { useAppDispatch, useAppSelector } from "../Helfy.Store/Hooks";
import { setParticipant } from "../Helfy.Containers/Participants/Redux/Reducer";
import { tryParseNumber } from "../Helfy.Utility/GeneralUtilities";
import { UnreadInfo } from "./UnreadInfo";
import { HelfyHubState } from "./HelfyHubState";
import { NotificationEnum } from "../Helfy.Enums/NotificationEnum";
import { incrementNotificationsCountBy } from "../Helfy.Containers/Notifications/Redux/Actions";
import notificationSound from "../Helfy.Assets/Audio/notification-sound1.mp3"
import { TransactionViewModel } from "../Helfy.Containers/Transactions/Types/TransactionViewModel";
import { RoleEnum } from "../Helfy.Enums/RoleEnum";
import { setAccountInfo } from "../Helfy.Containers/AccountInfo/Redux/Reducer";

//todo: figure out where to store types. as in c# every type new filename or index.ts in types folder or what?
//todo: remove unread messages that changes participants from here. that should be done in the children logic

interface Props extends React.PropsWithChildren {
}

export const HelfyHub = ({ children }: Props) => {
    const [isPlaying, setPlaying] = useState(false);
    const [state, setState] = useState(initialState);
    const userId = useAppSelector(state => state.app.userId);
    const role = useAppSelector(state => state.app.role);
    const name = useAppSelector(state => state.app.givenName);
    const accountInfo = useAppSelector(state => state.account.accountInfo)

    // const participants = useAppSelector(state => state.participant.participants);
    const dispatch = useAppDispatch();

    const syncUser = useCallback(
        () =>
            // regular vanilla TalkJS code here
            new Talk.User({
                id: userId,
                name: name,
                role: "default"
            }),
        []
    );

    const convertToNativeType = (messages: Talk.UnreadConversation[]): UnreadInfo[] => {
        return messages.map(msg => ({
            senderId: tryParseNumber(msg.lastMessage.senderId, -1),
            unreadMessageCount: msg.unreadMessageCount
        }));
    };

    const onUnreadsChange = (messages: Talk.UnreadConversation[]) => {
        const convertedData = convertToNativeType(messages);
        setState(prev => ({
            ...prev,
            unreadMessages: convertedData
        }))

        // const updatedParticipants = participants.map(p => ({ ...p, ...{ noOfMessages: 0 } }));

        // messages.forEach(i => {
        //     const senderId = tryParseNumber(i.lastMessage.senderId, -1)
        //     const p = updatedParticipants.find(p => p.userId == senderId)
        //     if (p)
        //         p.noOfMessages = i.unreadMessageCount;
        // })

        // dispatch(setParticipant({ participants: updatedParticipants }))
    }

    const onMessage = async (message: Talk.Message) => {
        if (!message.isByMe) { //todo: we are watching incoming messages only
            const type = message.custom.notificationType;

            //todo: make a helper function out of this or do it better
            const getKeyByValue = (value: number): string | undefined => {
                return Object.keys(NotificationEnum).find(key => NotificationEnum[key as keyof typeof NotificationEnum] === value);
            }

            //todo: dali da stavljamo === svuda ili?
            if (type == getKeyByValue(NotificationEnum.SessionRequested) || type == getKeyByValue(NotificationEnum.SessionModified) || type == getKeyByValue(NotificationEnum.SessionAccepted)) {
                //play sound -- todo: this should be held somewhere else srp attack here
                const sound = new Audio(notificationSound);
                sound.play().catch(error => console.error('Error playing sound:', error));

                await dispatch(incrementNotificationsCountBy(1))
            }


            if (type == "call_ended") {
                const transaction: TransactionViewModel = JSON.parse(message.custom.transaction);

                if (transaction) {
                    const amountAdded = role === RoleEnum.Patient.stringValue ? -transaction.Amount : transaction.Amount

                    dispatch(setAccountInfo({
                        ...accountInfo,
                        credits: accountInfo.credits + amountAdded
                    }))
                }
            }
        }
    }

    useEffect(() => {
    });

    //todo: maybe we should make another component for all the messaging stuff like before realtimenotificationprovider or something
    //todo: appId should go into some kind of other place
    return (
        <HelfyHubContext.Provider value={state}>
            <Session appId="th85DENk" syncUser={syncUser} onUnreadsChange={onUnreadsChange} onMessage={onMessage}>
                {children}
            </Session>
        </HelfyHubContext.Provider>
    );
}