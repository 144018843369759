import React from 'react';
import styles from './checkbox.module.scss';

interface Props {
  name: string;
  checked: boolean;
  handleChange(name: string): void;
}

export const Checkbox = React.memo(({checked, name, handleChange}: Props) => {

  function onChange() {
    handleChange(name)
  }

  function getStyles() {
    if (!checked) return styles["checkbox-holder"];
    else return `${styles["checkbox-holder"]} ${styles.active}`;
  }

  return (
    <div className={getStyles()} onClick={onChange}>
      <div className={styles.checkbox}/>
    </div>
  );
});

