import React from "react";
import { components } from "react-select";
import icClear from "../../../Helfy.Assets/Images/ic_clear.svg";

export function HelfyMultiValueRemove(props: any) {
  return (
    <components.MultiValueRemove {...props}>
      <img src={icClear} alt="Clear"/>
    </components.MultiValueRemove>
  )
}
