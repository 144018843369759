//todo: zasto ovo nije enum bilo bi puno lakse
export const NotificationEnum = Object.freeze({
  NewMessage: 0,
  SessionRequested: 1,
  SessionModified: 2,
  SessionAccepted: 3,
  SessionRejected: 4,
  SessionStarted: 5,
  SessionPaused: 6,
  SessionStopped: 7,
  Standard: 8,
  UpdateProfile: 9,
  CompleteProfile: 10,
  VerifyEmail: 11,
});
