import React from 'react';
import { NavLink, Outlet } from 'react-router-dom'
import { FormattedMessage } from "react-intl";
import styles from './landing-page-header.module.scss';
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { SecondaryButton } from "../../Buttons/SecondaryButton";
import logo from '../../../Helfy.Assets/Images/helfy-logo.svg';

interface Props {
  onLoginClick: () => void,
  onRegisterClick: () => void
}

export const LandingPageHeaderComponent = ({ onLoginClick, onRegisterClick }: Props) => {
  return (
    <>
      <nav className={styles.navigation}>
        <div className={styles.left}>
          <NavLink to="/"><img src={logo} alt="Helfy logo" /></NavLink>
          <NavLink to="help/faq"><FormattedMessage id="header.navigation.button.help" /></NavLink>
          <NavLink to="help/contact"><FormattedMessage id="header.navigation.button.contactUs" /></NavLink>
        </div>
        <div className={styles.right}>
          <SecondaryButton textId="header.navigation.button.login" type="button" onClick={onLoginClick} />
          <PrimaryButton textId="header.navigation.button.register" type="button" onClick={onRegisterClick} />
        </div>
      </nav>
      <Outlet />
    </>
  );
}