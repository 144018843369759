import * as actions from './Actions';
import {ISessionRequestProps} from "../Types";
import {LOAD_COUNSELOR_INITIAL_DATA_SUCCESS} from "../../../Helfy.App/Redux/Actions";

const initialState: ISessionRequestProps = {
  isBusy: false,
  showError: false,
  errorMessage: "",
  isModalBusy: false,
  newSessionRequest: 0,
  requests: [],
  selectedRequest: {
    id: 0,
    sleepingHabits: "",
    physicalHealth: "",
    mentalHealth: "",
    gender: "",
    eatingHabits: "",
    counselingBefore: "",
    birthDay: new Date(),
  }
};


export default (state = initialState, action: any) => {
  switch (action.type) {
    // Get list
    case actions.COUNSELOR_REQUESTS_REQUESTED:
      return {...state, isBusy: true};
    case actions.COUNSELOR_REQUESTS_SUCCESS:
      return {...state, isBusy: false, requests: action.payload};
    case actions.COUNSELOR_REQUESTS_FAILURE:
      return {...state, isBusy: false, error: action.payload};

    case actions.GET_COUNSELOR_REQUEST_REQUESTED:
      return {...state, isModalBusy: true, isError: false,};
    case actions.GET_COUNSELOR_REQUEST_SUCCESS:
      return {...state, isModalBusy: false, selectedRequest: action.payload};
    case actions.GET_COUNSELOR_REQUEST_FAILURE:
      return {...state, isModalBusy: false, isError: true};

    case actions.RESPOND_TO_COUNSELING_REQUEST_REQUESTED:
      return {...state, isBusy: true};
    case actions.RESPOND_TO_COUNSELING_REQUEST_SUCCESS:
      return {...state, isBusy: false}
    case actions.RESPOND_TO_COUNSELING_REQUEST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};

    case actions.INCREMENT_SESSION_REQUEST_COUNT:
      return {...state, newSessionRequest: state.newSessionRequest + action.payload};

    /*
   Handling when counselor is mounted.
   Method is called in Dashboard of Counselor
   */
    case LOAD_COUNSELOR_INITIAL_DATA_SUCCESS:
      return {...state, newSessionRequest: action.payload.newSessionRequest};

    case actions.CLEAR_SESSION_REQUEST_STATE:
      return {...state, ...initialState};
    case actions.CLEAR_SESSION_REQUEST_ERRORS:
      return {...state, showError: false, errorMessage: ''}

    default:
      return state
  }
}
