//todo: delete once it won't be neccesary
import { Dispatch } from "redux";

/**
 *  Set user as authenticated
 */
// export const APP_LOGIN_SUCCESS = "APP/APP_LOGIN_SUCCESS";


/**
 *  Set user as unauthenticated
 */
//export const APP_LOGIN_SIGN_OUT = "APP/APP_LOGIN_SIGN_OUT";

/**
 * Sets current email invitation
 */
// export const SET_CURRENT_EMAIL_INVITE = "APP/APP_SET_CURRENT_EMAIL_INVITE";
// export const setCurrentEmailInvite = (email: string) => (dispatch: Dispatch) => dispatch({type: SET_CURRENT_EMAIL_INVITE, payload: email});

//export const APP_TOGGLE_CREDITS = "APP/APP_TOGGLE_CREDITS";
export const SET_USER_HAS_FILLED_PROFILE = "APP/SET_USER_HAS_FILLED_PROFILE";

// Counselor data
export const LOAD_COUNSELOR_INITIAL_DATA_SUCCESS = "LOAD_COUNSELOR_INITIAL_DATA_SUCCESS";
export const LOAD_COUNSELOR_INITIAL_DATA_FAILURE = "LOAD_COUNSELOR_INITIAL_DATA_FAILURE";
export const LOAD_COUNSELOR_INITIAL_DATA_REQUESTED = "LOAD_COUNSELOR_INITIAL_DATA_REQUESTED";