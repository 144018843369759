//todo: remove extra when done
import { boolean } from "yup";
import { ISelectedRoom } from "../../Chat/Types";
import { IParticipant, IParticipantListProps } from "../Types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

//todo: ovo trebam skužiti šta će mi sve ovo  još nije skroz isto u apiu. tamo je data participants i tak.
const initialState: IParticipantListProps = {
  page: 0,
  error: null,
  nextPage: 1,
  pageSize: 25,
  hasMore: true,
  participants: [],
  selectedUser: {},
  selectedRoom: {} as ISelectedRoom,
  isBusy: false
};

const participantSlice = createSlice({
  name: "participant",
  initialState: initialState,
  reducers: {
    setParticipant: (state, action: PayloadAction<Partial<IParticipantListProps>>) => {
      const newState = { ...state, ...action.payload }
      return newState
    },
    setIsBusy: (state, action: PayloadAction<boolean>) => {
      state.isBusy = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    // setError: (state, action: PayloadAction<string | null>) => {
    //   // todo: this should have to be changed when we finally fix this
    //   state.errorMessage = action.payload || ""
    //   state.showError = action.payload != null
    // },
    // setAccountInfo(state, action: PayloadAction<IAccountInfo>) {
    //   state.accountInfo = action.payload
    // }
  }
})
export const { setParticipant, setError, setIsBusy } = participantSlice.actions

// export const { setIsBusy, setError, setAccountInfo } = participantSlice.actions
export default participantSlice.reducer


// export default (state: IParticipantListProps = initialState, action: any) => {
//   switch (action.type) {
//     // Get participants
//     case actions.LOAD_PARTICIPANTS_REQUESTED:
//       return { ...state, isBusy: true };
//     case actions.LOAD_PARTICIPANTS_SUCCESS:
//       const { data, ...rest } = action.payload;
//       return { ...state, participants: data, ...rest, isBusy: false };
//     case actions.LOAD_PARTICIPANTS_FAILURE:
//       return { ...state, error: action.payload, isBusy: false };
//     // Room
//     case actions.LOAD_ROOM_FOR_USER_SUCCESS:
//       return { ...state, selectedRoom: action.payload };
//     case actions.SELECTED_USER_CHANGED:
//       return { ...state, selectedUser: state.participants.find((p: IParticipant) => p.roomId === action.payload) };

//     case actions.SET_INDICATOR_FOR_INCOMING_MESSAGE:
//       const participants = handleIncomingMessage(state.participants, action);
//       return { ...state, participants: [...participants] };

//     case actions.CHANGE_USER_NETWORK_STATUS:
//       const userConnectedParticipants = handleUserStatus(state.participants, action);
//       return { ...state, participants: [...userConnectedParticipants] };

//     case actions.SET_NEW_MESSAGES_COUNT_TO_ZERO:
//       const participantsMessageCountZero = handleMessagesCountToZero(state.participants, action);
//       return { ...state, participants: [...participantsMessageCountZero] };

//     case actions.CLEAR_SELECTED_USER:
//       return { ...state, selectedUser: {}, selectedRoom: {} };

//     case actions.CLEAR_STATE:
//       return { ...state, ...initialState };

//     default:
//       return state;
//   }
// }


// const handleMessagesCountToZero = (participants: Array<IParticipant>, action: any) => {
//   let index = participants.findIndex(x => x.roomId === action.payload);

//   // If we did not found any participant return same participants
//   if (index === -1)
//     return participants;

//   let participant = participants[index];

//   participant.hasNewMessages = false;
//   participant.noOfMessages = 0;

//   participants[index] = participant;

//   return participants;
// };

// const handleIncomingMessage = (participants: Array<IParticipant>, action: any) => {
//   let index = participants.findIndex(x => x.roomId === action.payload.roomId);

//   // If we did not found any participant return same participants
//   if (index === -1)
//     return participants;

//   let participant = participants[index];

//   participant.messages.push(action.payload);

//   participant.hasNewMessages = true;

//   participant.noOfMessages += 1;

//   participants.splice(index, 1);

//   participants.unshift(participant);

//   return participants;
// };

// const handleUserStatus = (participants: Array<IParticipant>, action: any) => {
//   let index = participants.findIndex(x => x.userId === action.payload.id);

//   // If we did not found any participant return same participants
//   if (index === -1) {
//     return participants;
//   }

//   let participant = participants[index];

//   participant.networkStatus = action.payload.status;

//   let newParticipants = participants;

//   newParticipants[index] = participant;

//   return newParticipants;
// };

