import React from 'react'
import ReactPaginate from 'react-paginate';
import styles from './helfy-pagination.module.scss';
import arrowLeftIc from '../../Helfy.Assets/Images/ic_arrow_left.svg'
import arrowRightIc from '../../Helfy.Assets/Images/ic_arrow_right.svg'

interface Props {
	totalPages: number;
	currentPage: number;

	onPageChange(page: number): void;
}

export const HelfyPagination = ({ totalPages, currentPage, onPageChange }: Props) => {
	const handlePageClick = (data: any) => {
		const { selected } = data;
		onPageChange(selected + 1);
	}

	if (totalPages === 0) return null;

	return (
		<ReactPaginate
			breakLabel={'...'}
			pageRangeDisplayed={5}
			marginPagesDisplayed={3}
			breakClassName={'break-me'}
			pageCount={totalPages}
			activeClassName={styles.active}
			nextClassName={styles.arrowRight}
			disabledClassName={styles.disabled}
			previousClassName={styles.arrowLeft}
			containerClassName={styles.container}
			onPageChange={handlePageClick}
			nextLabel={<ArrowRight />}
			previousLabel={<ArrowLeft />}
		/>
	);
}

const ArrowRight = () => {
	return (
		<div className={styles.iconHolder}>
			<img src={arrowRightIc} alt="arrow-right" />
		</div>
	)
};

const ArrowLeft = () => {
	return (
		<div className={styles.iconHolder}>
			<img src={arrowLeftIc} alt="arrow-left" />
		</div>
	)
};