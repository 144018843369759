import React from 'react';
import { Formik } from "formik";
import { ISteps } from '../../Types';
import { setState } from '../../Redux/Reducer';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { HelfyQuestions3View } from './HelfyQuestions3View';
import { HelfyQuestions3Schema } from './HelfyQuestions3Schema';

interface Props extends ISteps {
  onSubmit(values: any): Promise<void>;
  isBusy: boolean
}

export const HelfyQuestions3 = (p: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.counselorOnBoarding);

  const handleSubmit = async (values: any,) => {
    dispatch(setState(values))
    await p.onSubmit(values)
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={state} validationSchema={HelfyQuestions3Schema} validateOnMount>
      {props => <HelfyQuestions3View {...props} {...p} />}
    </Formik>
  )
}