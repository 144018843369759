import axios from "axios";
import { setState } from "../../../features/emailLookup/emailLookupSlice";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { store } from "../../../Helfy.Store/Store";
import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

//todo: store.dispatch should not be used frequently. bettter to use something like const actionCreator = () => { return async (dispatch) => {
let emailValue = '';
let isEmailUnique = false;

export const emailLookup = (value: any) => {
  return new Promise(resolve => {
    const emailLookupSuccess = (resolve: any, value: any) => {
      emailValue = value;
      isEmailUnique = true;
      resolve(true);
      store.dispatch(setState({ loading: false }));
    };

    const emailLookupFailure = (resolve: any, value: any) => {
      emailValue = value;
      isEmailUnique = false;
      resolve(false);
      store.dispatch(setState({ loading: false }));
    };

    if (!value) return resolve(false);

    const validation = Yup.string().email().required();
    const isValid = validation.isValidSync(value);

    if (!isValid) return resolve(false);
    if (emailValue === value && !isEmailUnique) return resolve(false);
    else if (emailValue === value && isEmailUnique) return resolve(true);
    else {
      store.dispatch(setState({ loading: true }));
      axios.post(API_URL + `/auth/isEmailUnique`, { email: value })
        .then(() => emailLookupSuccess(resolve, value))
        .catch(() => emailLookupFailure(resolve, value))
    }
  })
};

