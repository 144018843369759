import React from 'react';
import { Col, Row } from "react-grid-system";
import icAccount from '../../Helfy.Assets/Images/ic_account.svg';
import icContact from '../../Helfy.Assets/Images/ic_questionnaire.svg';
import { ISideNavLinkType } from "../../Helfy.Components/Sidenav/Types/Types";
import { SidenavListComponent } from "../../Helfy.Components/Sidenav/SidenavListComponent";
import { HelfyContainer } from "../../Helfy.Layout/HelfyContainer";
import { BackNavigation } from "../../Helfy.Components/NavBar/BackNavigation";
import { Outlet } from 'react-router-dom';

//todo dali se ovo moze nekako kroz parametre funkcije? ja neznam kako iskreno
interface Props {
  showBackNavigation?: boolean;
}

//todo: za šta će nam parametar showbacknavigation? ne kužim
export const HelpSectionContainer = ({ showBackNavigation }: Props) => {
  return (
    <>
      <BackNavigation title="Help" navigateTo="/" show={showBackNavigation} />
      <HelfyContainer paddedTop >
        <Row justify="center">
          <Col md={8} lg={8} xs={12}>
            <Row>
              <Col md={3} lg={3}>
                <SidenavListComponent links={links} />
              </Col>
              <Col md={9} lg={9}>
                <Outlet />
              </Col>
            </Row>
          </Col>
        </Row>
      </HelfyContainer >
    </>
  );
}

const links: Array<ISideNavLinkType> = [
  {
    icon: icAccount,
    to: "faq",
    textId: "sidenav.help.faq.title"
  },
  {
    icon: icContact,
    to: "contact",
    textId: "sidenav.help.contact.title"
  }
];
