import React from 'react';
import { Label } from "./Components/Label";
import { RowHolder } from "./Components/RowHolder";
import Creatable from "react-select/creatable";
import { HelfyMultiValueRemove } from "./Components/HelfyMultiRemove";

interface Props {
  id: string;
  options: Array<any>
  form: any;
  field: any;
  errors: any;
  touched: any;
  label: string;
  placeholder: string;
}


export const CreatableField = ({ label, id, field, options, form, ...rest }: Props) => {
  const handleChange = (value: any) => {
    form.setFieldValue(field.name, value);
  }

  const handleBlur = () => {
    form.setFieldTouched(field.name);
  }

  return (
    <RowHolder field={field} form={form} >
      <Label id={id} label={label} form={form} field={field} />
      <Creatable
        {...rest}
        isMulti={true}
        isClearable
        styles={selectStyles}
        options={options}
        onBlur={handleBlur}
        onChange={handleChange}
        value={field.value}
        components={{ MultiValueRemove: HelfyMultiValueRemove }}
      />
    </RowHolder >
  );
}

const brandColor = '#59c7e0';

const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    fontSize: '1em',
    maxWidth: '330px',
    lineHeight: '19px',
    fontFamily: '"Exo 2", sans-serif',
    borderRadius: "10px",
    marginBottom: '1em',
    border: "1px solid #e0e0e0",
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: null
    }
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: '"Exo 2", sans-serif',
  }),

  multiValue: (base: any) => ({
    ...base,
    borderRadius: "13px"
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    fontFamily: '"Exo 2", sans-serif',
  })
};


