import React, { useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom";
import { signOut } from "../../../Helfy.App/Redux/Reducer";
import { useAppDispatch } from "../../../Helfy.Store/Hooks"

export const LogoutView = () => {
    const dispatch = useAppDispatch();

    const { search } = useLocation();
    const [url, setUrl] = React.useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(search);
        const acceptWait = (params.get('accept-wait') ?? "").toLowerCase() === "true";
        const waitUrl = "accept-wait";
        const loginUrl = "login"
        const url = ("/?" + (acceptWait ? waitUrl : loginUrl) + "=true")
        setUrl(url)

        dispatch(signOut())
    }, [])

    return (
        url ?
            <Navigate to={url} />
            :
            <></>
    )
}