import React, { useEffect } from 'react';
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { incrementNotificationsCountBy, loadNotifications } from "../Redux/Actions";
import styles from '../Styles/notifications.module.scss';
import { IParticipant } from "../../Participants/Types";
import { ISessionRequest } from "../../SessionRequest/Types";
import { NoNotifications } from "../Components/NoNotifications";
import { loadRoomForUserAsync } from "../../Participants/Redux/Actions";
import { BackNavigation } from "../../../Helfy.Components/NavBar/BackNavigation";
import { HelfyFullHeightContainer } from "../../../Helfy.Layout/HelfyContainer";
import { NotificationItem } from "../../../Helfy.Components/Notifications/NotificationItem";


export const NotificationsListContainer = React.memo(() => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { participants } = useAppSelector(state => state.participant);
  const { errorMessage, showError, isBusy, newNotificationCount, notifications } = useAppSelector(state => state.notification);

  useEffect(() => {
    async function _dummy() {
      //todo: dummy is usually used where and elsewhere because you have to call await dispatch(blaa) or without await it doesn't work ok and useeffect doesn't allow await
      //it's done in a dummy function. maybe it can be done better
      await dispatch(loadNotifications(false))
      await dispatch(incrementNotificationsCountBy(-1)) //todo: simple hack should be better. we just use -1 for this value and also we just immediately make everything read
    }

    _dummy();
  }, [])

  //todo: dali treba key= index svuda ili nešto bolje? kao nije idealno navodno
  return (
    <>
      <BackNavigation title={intl.formatMessage({ id: "notifications.manager.title" })} navigateTo="/" show />
      <HelfyFullHeightContainer>
        <div className={styles.notificationsList}>
          {notifications.length === 0 && <NoNotifications />}
          {notifications.map((item: ISessionRequest, index: number) => <NotificationItem key={index} notification={item} />)}
        </div>
      </HelfyFullHeightContainer>
    </>
  )
});