import React, { useState } from 'react';
import { Formik } from "formik";
import { LoginView } from "../View/LoginView";
import { LoginSchema } from "./ValidationSchema";
import { HelfyModal } from "../../../Helfy.Components/Modal/HelfyModal";
import jwt from 'jsonwebtoken';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../Helfy.Store/Hooks';
import { logIn, signOut } from '../../../Helfy.App/Redux/Reducer';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { MARK_ALL_AS_READ_REQUESTED } from '../../Notifications/Redux/Actions';
import { User } from 'talkjs/all';

interface Props {
  onCloseClick(): void
  openRegister(): void
}

type FormValues = {
  userName: string,
  password: string
}

const EMAIL_ERROR = "email error"
//const ACCEPT_ERROR = "accept error" //probably not needed any more

//todo: do we need history i removed it from the paramenters
export const LoginContainer = ({ onCloseClick, openRegister }: Props) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useAppDispatch();

  const logOut = () => {
    localStorage.removeItem("userCredentials"); //todo: neznam šta je ovo. i ovo nitko ne koristi šta će nam
    dispatch(signOut());
  };

  const handleSubmitAsync = async (values: any, actions: any) => {
    const { error, r } = await apiSubmitAsync(() => values, actions, setError, setIsBusy, "/Auth/Login", "LOGIN",
      (r) => {
        if (r?.data?.needsConfirmationOfEmail === true)
          return EMAIL_ERROR;

        // if (r?.data?.userIsAccepted === false)
        //   return ACCEPT_ERROR;

        const decoded: any = jwt.decode(r.data.authToken);

        dispatch(logIn({
          userId: decoded.user_id,
          role: decoded.role,
          userName: decoded.nameid,
          givenName: decoded?.given_name,
          authToken: r.data.authToken,
          userHasFilledProfile: r.data.userHasFilledProfile,
          userIsAccepted : r.data.userIsAccepted
        }));
        return null;
      });

    var url: string | null = null;

    if (error === null) {
      url = "/"
    }
    else if (error === EMAIL_ERROR) {
      url = "/accept-email-invite" //todo:check if navigates will be with / or without
    }
    // else if (error === ACCEPT_ERROR) {
    //   url = "/accept-wait" //todo:check if navigates will be with / or without
    // }

    if (url !== null) {
      navigate(url);
      onCloseClick()
    }
  }

  const initialValues: FormValues = {
    userName: "",
    password: ""
  }
  // onSubmit={props.handleSubmit} //todo: seems like this is not needed

  return (
    <HelfyModal isOpen={true} >
      <Formik validateOnMount validateOnBlur initialValues={initialValues} onSubmit={handleSubmitAsync} validationSchema={LoginSchema}>
        {props =>
          <form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.handleSubmit();
              }
            }}>
            <LoginView {...props} openRegister={openRegister} error={error} onCloseClick={onCloseClick} />
          </form>
        }
      </Formik>
    </HelfyModal>
  );
}