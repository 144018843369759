import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from './break-section.module.scss';
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";

interface Props {
  onRegisterClick(): void;
}

export class BreakSection extends React.PureComponent<Props> {

  render() {
    return (
      <section className={styles.breakSection}>
        <h1><FormattedMessage id="landing.page.breakSection.heading"/></h1>
        <PrimaryButton buttonStyles={styles.breakSectionBtn} textId="landing.page.register.button" onClick={this.props.onRegisterClick}/>
      </section>
    );
  }
}