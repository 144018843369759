import React, { useState } from "react";
import { useIntl } from "react-intl";
import styles from "../Styles/modal-windows.module.scss";
import icBack from '../../../Helfy.Assets/Images/ic_back.svg';
import { HelfyModal } from "../../../Helfy.Components/Modal/HelfyModal";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";

interface Props {
  show: boolean;
  isBusy: boolean;

  onBackPress(): void;

  rejectRequest(message: string): void;
}

export const DeclineSessionRequest = React.memo(({ show, onBackPress, rejectRequest, isBusy }: Props) => {
  const intl = useIntl();
  const [message, setMessage] = useState("");

  function handleMessage(e: any) {
    setMessage(e.target.value);
  }

  return (
    <HelfyModal isOpen={show}>
      <div className={`${styles['modal-window']} ${styles["decline-request-session"]}`}>
        <div className={styles.header}>
          <img src={icBack} alt="Back" onClick={onBackPress} />
          <h3>{intl.formatMessage({ id: "generic.messages.decline.session.request" })}</h3>
        </div>
        <div className={styles["text-box"]}>
          <label htmlFor="message">{intl.formatMessage({ id: "generic.messages.decline.message.description" })}</label>
          <textarea name="message" onChange={handleMessage} rows={13} id="message" />
        </div>
        <div className={`${styles["buttons-holder"]} ${styles["right"]}`}>
          <PrimaryButton textId="generic.messages.send.message" isBusy={isBusy} disabled={isBusy} onClick={() => rejectRequest(message)} type={"submit"} />
        </div>
      </div>
    </HelfyModal>
  )
});