import { Dispatch } from "redux"
import { apiGetPostAsync, apiSubmitAsync } from "../../../Helfy.Api/Helpers/Helpers"
import { setState } from "./Reducer"
import { IntlShape } from "react-intl"
import { getGenderOptions } from "../../../Helfy.Enums/Gender"


//todo: sve ovo popraviti imena su all ove the place react query triba
export const getCounselorOnboardingAsync = (setError: (error: string | null) => void, setIsBusy: (isBusy: boolean) => void, intl: IntlShape) => {
    return async (dispatch: Dispatch) => {
        //todo: nema errora ako se dobro ne učita
        await apiGetPostAsync(true, null, null, setError, setIsBusy, "/account/GetCounselorOnBoardingAnswers", "GetCounselorOnboardingAnswers", (r) => {
            //todo: find a better way to do this
            // we will convert a little bit

            const genderOptions = getGenderOptions(intl);
            const gender = genderOptions.find(g => g.value === r.data.gender)
            const newState = { ...r.data, gender: gender }
            dispatch(setState(newState))
            return null
        })
    }
}

export const setCounselorOnboardingAsync = async (data: any, setError: (error: string | null) => void, setIsBusy: (isBusy: boolean) => void) => {
    const dataToSend = { ...data, gender: data.gender.value }; //todo: maybe a better way of doing this? maybe we should either make it on the backend that you have only labels or here only values. now its a mix of both
    await apiSubmitAsync(() => dataToSend, null, setError, setIsBusy, "/Account/SetCounselorOnBoardingAnswers", "SET_COUNSELOR_ANSWERS", () => null)
}