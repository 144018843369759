import React from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss'
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { TextAreaField } from "../../../../Helfy.Components/Formik/TextAreaField";
import { ISteps } from '../../Types';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
}

export const HelfyQuestions2View = ({ move, isValid, handleSubmit }: Props) => {
  function onSubmit() {
    handleSubmit();
    move(1);
  }

  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>Helfy Questions 2</h3>
          <div className={styles.profileContent2}>
            <FastField
              type="text"
              id="howOftenCheckYourEmail"
              name="howOftenCheckYourEmail"
              label="How may times a week do you check your email?"
              placeholder="Email checking frequency"
              component={TextAreaField} />
            <FastField
              type="text"
              id="requiredSuperVisioned"
              name="requiredSuperVisioned"
              label="Are you currently required to have supervision in order to provide counseling services?"
              placeholder="Supervision?"
              component={TextAreaField} />
            <FastField
              type="text"
              id="detailsAboutInvestigation"
              name="detailsAboutInvestigation"
              label="Have you ever been subject to an investigation or review by your licensing board? If so, please provide details ?"
              placeholder="Investigation details"
              component={TextAreaField} />
            <FastField
              type="text"
              id="disciplineInvestigations"
              name="disciplineInvestigations"
              label="Have you ever been subject to discipline actions by your licensing board? If so, please provide details ?"
              placeholder="Discipline details"
              component={TextAreaField} />
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId="counselor.on.boarding.button.next" disabled={!isValid} onClick={onSubmit} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}