import React from 'react';
import { FastField } from "formik";
import { NavLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import styles from '../Styles/login.module.scss';
import { ModalBody } from "../../../Helfy.Components/Modal/ModalBody";
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { ModalHeader } from "../../../Helfy.Components/Modal/ModalHeader";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { getErrorMessageFromApi } from "../../../Helfy.Components/ApiErrors";

interface Props {
  isValid: boolean;
  error: string | null;
  isSubmitting: boolean;

  openRegister(): void;
  handleSubmit(): void;
  onCloseClick(): void;
}

export const LoginView = ({ isValid, isSubmitting, handleSubmit, onCloseClick, openRegister, error }: Props) => {
  const intl = useIntl();

  return (
    <ModalBody bodyStyle={{ width: '620px', overflow: 'hidden' }}>
      <ModalHeader show={true} text={intl.formatMessage({ id: "login.title" })} onCloseClick={onCloseClick} />
      {(error != null && error != "") && <div className={styles.errorMessage}><p>{getErrorMessageFromApi(error)}</p></div>}
      <div className={styles.modalContent}>
        <FastField
          autoFocus
          type="email"
          id="inputEmail"
          name="userName"
          component={InputField}
          label={intl.formatMessage({ id: "login.email.label" })}
          placeholder={intl.formatMessage({ id: "login.email.placeholder" })}
        />
        <FastField
          type="password"
          id="inputPassword"
          name="password"
          component={InputField}
          label={intl.formatMessage({ id: "login.password.label" })}
          placeholder={intl.formatMessage({ id: "login.password.placeholder" })}
        />
        <NavLink to="/request-password-reset" onClick={onCloseClick} className={styles.forgotPassword}><FormattedMessage id="login.forgot.password" /></NavLink>
      </div>
      <div className={styles.footer}>
        <PrimaryButton
          disabled={!isValid}
          isBusy={isSubmitting}
          onClick={handleSubmit}
          textId="login.button.loginText"
          buttonStyles={styles.buttonSignIn}
        />
        <p>
          <FormattedMessage id="login.not.having.account" />
          <button onClick={openRegister} className={styles.buttonSignUp}><FormattedMessage id="sign.up.title" /></button>
        </p>
      </div>
    </ModalBody>
  );
}