import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroller";
import { IParticipant } from "../Types";
import * as clientMethods from '../Constants';
import styles from '../Styles/participant.module.scss';
import { WebRtcContext } from "../../../Helfy.HubOld/WebRtcContext";
import { ParticipantItem } from "../Components/ParticipantItem";
import { NoParticipantsComponent } from "../Components/NoParticipants";
import { GreenLoader } from "../../../Helfy.Components/Loaders/GreenLoader";
import { apiGetPostAsync, apiSimpleSubmitAsync, apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { setParticipant } from '../Redux/Reducer';
import { HelfyHubContext } from '../../../Helfy.Hub/HelfyHubContext';
import { loadParticipantsAsync, loadRoomForUserAsync } from '../Redux/Actions';
// import { changeUserNetworkStatus, clearState, loadChatUsersAsync, loadRoomForUserAsync } from "../Redux/Actions";


export const ParticipantListContainer = React.memo(() => {
  const ctx = useContext(HelfyHubContext);
  const dispatch = useAppDispatch();
  // const [error, setError] = useState<string | null>("")
  // const [isBusy, setIsBusy] = useState(false)

  const isBusy = useAppSelector(state => state.participant.isBusy);
  const error = useAppSelector(state => state.participant.error);
  const nextPage = 1;//useAppSelector(state => state.participant.nextPage); //todo: big hack i have to figure out how this works for infinite scroll. maybe do it better. currently the backend adds the number +1
  const selectedUser = useAppSelector(state => state.participant.selectedUser);
  const participants = useAppSelector(state => state.participant.participants);

  useEffect(() => {

    // ctx.connection.on(clientMethods.SERVER_TO_CLIENT_NETWORK_STATUS_CHANGED, handleNetworkStatusChange);//todo:fix

    async function _dummy() {
      await dispatch(loadParticipantsAsync());
      // await dispatch(loadChatUsersAsync(nextPage, 25))
    }

    _dummy();

    return () => {
      // ctx.connection.off(clientMethods.SERVER_TO_CLIENT_NETWORK_STATUS_CHANGED, handleNetworkStatusChange); //todo:fix
      //dispatch(clearState()) // This will clear out all participants and user wont navigate from session request to chat
    }
  }, []);

  useEffect(() => {
    const updatedParticipants = participants.map(p => ({ ...p, ...{ noOfMessages: 0 } }));
    ctx.unreadMessages.forEach(i => {
      const p = updatedParticipants.find(p => p.userId == i.senderId)
      if (p)
        p.noOfMessages = i.unreadMessageCount;
    })

    dispatch(setParticipant({ participants: updatedParticipants }))
  }, [ctx.unreadMessages])

  async function loadMore() {
    // await dispatch(loadChatUsersAsync(nextPage, 25))
  }

  function handleNetworkStatusChange(msg: any) {
    // dispatch(changeUserNetworkStatus(msg));
  }

  async function onUserClick(roomId: number) {
    await dispatch(loadRoomForUserAsync(roomId))
  }

  function isActiveConversation(userId: number) {
    return selectedUser.userId === userId;
  }

  function renderLoader() {
    return (
      <div key={-1} style={{ marginTop: '20px', display: 'flex' }}>
        <GreenLoader isWhiteBackGround={false} width={45} height={45} />
      </div>
    )
  }

  return (
    <div className={styles.listHolder}>
      {isBusy && renderLoader()}
      {
        !isBusy &&
        <InfiniteScroll
          threshold={2}
          pageStart={0}
          isReverse={false}
          useWindow={false}
          initialLoad={false}
          loadMore={loadMore}
          loader={renderLoader()}
          className={styles.infiniteScroll}>
          {participants.length === 0 && <NoParticipantsComponent />}
          {participants.map((par: IParticipant) => <ParticipantItem participant={par} key={par.userId} onUserClick={onUserClick} />)}
        </InfiniteScroll>
      }
    </div>
  );
});