import { IntlShape } from 'react-intl';
import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

export const PayoutSchemaGetter = (intl: IntlShape, credits: number) => {
  return Yup.object().shape({
    credits: Yup
      .number(),
    amount: Yup.number()
      .required()
      .min(1, intl.formatMessage({ id: "generic.messages.payout.minimum.10.dollars" }))
      .max(credits, intl.formatMessage({ id: "generic.messages.payout.nice.try" }))
  })
}