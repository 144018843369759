import axios from 'axios';
import { Dispatch } from "redux";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { ErrorManager } from "../../../Helfy.Managers/ErrorManager";
import { LogManager } from "../../../Helfy.Managers/LogManager";

export const GET_PREFERENCES_FAILURE = "GET_PREFERENCES_FAILURE";
export const GET_PREFERENCES_SUCCESS = "GET_PREFERENCES_SUCCESS";
export const GET_PREFERENCES_REQUESTED = "GET_PREFERENCES_REQUESTED";

export const UPDATE_PREFERENCES_FAILURE = "UPDATE_PREFERENCES_FAILURE";
export const UPDATE_PREFERENCES_SUCCESS = "UPDATE_PREFERENCES_SUCCESS";
export const UPDATE_PREFERENCES_REQUESTED = "UPDATE_PREFERENCES_REQUESTED";

export const getPreferencesAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_PREFERENCES_REQUESTED});
      const response = await axios.get(API_URL + "/UserPreferences/GetCounselorPreferences");
      dispatch({type: GET_PREFERENCES_SUCCESS, payload: response.data});
    } catch (e) {
      const error = ErrorManager.getErrorMessageForDisplay(e);
      LogManager.logError("Get preferences async", e);
      dispatch({type: GET_PREFERENCES_FAILURE, payload: error});
    }
  }
};

export const updatePreferencesAsync = (preferences: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_PREFERENCES_REQUESTED});
      const response = await axios.post(API_URL + "/UserPreferences/UpdateCounselorPreferences", preferences);
      dispatch({type: UPDATE_PREFERENCES_SUCCESS, payload: response.data});
    } catch (e) {
      const error = ErrorManager.getErrorMessageForDisplay(e);
      LogManager.logError("UPDATE preferences async", e);
      dispatch({type: UPDATE_PREFERENCES_FAILURE, payload: error});
    }
  }
};

// UTILITY
export const CLEAR_ERROR = "PREFERENCES/CLEAR_ERROR";
export const CLEAR_STATE = "PREFERENCES/CLEAR_STATE";
export const clearState = () => (dispatch: Dispatch) => dispatch({type: CLEAR_STATE});
export const clearError = () => (dispatch: Dispatch) => dispatch({type: CLEAR_ERROR});