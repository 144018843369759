import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { loadAccountInfoAsync } from "../../AccountInfo/Redux/Actions";
import { DashboardNavBar } from "../../../Helfy.Components/NavBar/DashboardNav";
import { ContentHolder } from "../Components/ContentHolder";
import { RootState } from '../../../Helfy.Store/Store';
import styles from '../Styles/patient-dashboard.module.scss';

export const PatientDashboardContainer = () => {
  useEffect(() => {
    loadAccountInfoAsync().then()
  }, [])


  return (
    <>
      <div className={styles.container}>
        <DashboardNavBar />
        <ContentHolder />
      </div>
    </>
  )
}