import React from 'react';
import { ISelectedRoom } from "../../Types";
import styles from '../../Styles/chat.module.scss'
import { CreditsDisplay } from "./CreditsDisplay";
import { RoleEnum } from "../../../../Helfy.Enums/RoleEnum";
import { StartSessionButton } from "./StartSessionButton";
import { CounselorDetailsButton } from "./CounselorDetailsButton";

interface Props {
  id: number;
  show: boolean;
  role: string;
  lastName: string;
  firstName: string;
  pricePerHour: number;
  hasFreeSession: boolean;
  selectedRoom: ISelectedRoom,
}

export const ChatHeader = React.memo(({ firstName, lastName, role, id, pricePerHour, hasFreeSession, selectedRoom, show }: Props) => {
  if (!show) return null;
  return (
    <div className={styles.chatHeader}>
      <div className={styles.headerLeft}>
        <div className={styles.headerName}>{firstName} {lastName}</div>
        <CounselorDetailsButton id={id} show={role === RoleEnum.Patient.stringValue} />
      </div>
      <div className={styles.headerRight}>
        <CreditsDisplay show={role === RoleEnum.Patient.stringValue} pricePerHour={pricePerHour} />
        <StartSessionButton hasFreeSession={hasFreeSession} selectedRoom={selectedRoom} />
      </div>
    </div>
  )
});