import React from 'react';
import styles from '../Styles/buy-credits.module.scss'

import privacy from '../../../Helfy.Assets/Images/ic_privacy.svg';
import stripe from '../../../Helfy.Assets/Images/stripe-logo.svg';

interface Props {
  selectedPackage: any;
}

export const ChoosePaymentMethod = React.memo(({ selectedPackage }: Props) => {
  return (
    <div className={`${styles["buy-credits-container"]} ${styles["choose-method"]}`}>
      <div className={styles["method-holder"]}>
        <h3>Choose payment method</h3>
        <div className="img-holder">
          <div className={styles["img-paypal"]}>
            <img src={stripe} alt="paypalLogo" />
          </div>
        </div>
        <div className={styles["checkout-content"]}>
          <div className={styles.left}>
            <img src={privacy} alt="privacy" />
            <p className={styles.top}>Secure and Convenient Payment</p>
            <p className={styles.bottom}>Complete your purchase with confidence. Your payment information is encrypted, and your transaction is secure.</p>
          </div>
          <div className={styles.right}>
            <div className="name">
              <p className={styles.title}>Package name</p>
              <p className={styles.content}>{selectedPackage.name} package</p>
            </div>
            <div className="minutes">
              <p className={styles.title}>Credits purchased</p>
              <p className={styles.content}>{selectedPackage.value} credits</p>
            </div>
            <div className={styles["total-holder"]}>
              <p className={styles.text}>Total</p>
              <p className={styles.number}>{selectedPackage.currency} {selectedPackage.value}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});