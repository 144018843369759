import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../Helfy.Config/AppSettings";
import { ErrorManager } from "../../Helfy.Managers/ErrorManager";
import { LogManager } from "../../Helfy.Managers/LogManager";

//todo: this should be merged with the function below
export const apiGetPostAsync = async (isGet: boolean, paramsGetter: (() => any) | null, actions: any, setError: ((error: string | null) => void) | null, setIsBusy: ((isBusy: boolean) => void) | null, address: string, errorMessage: string, processResponse: ((response: any) => string | null) | null) => {
    setIsBusy && setIsBusy(true);
    let error: any = null;
    let r: AxiosResponse<any, any> | null = null;

    try {
        const url = `${API_URL}${address}`
        const params = paramsGetter?.() ?? {};
        r = !isGet ? await axios.post(url, params) : await axios.get(url, params)

        error = processResponse && processResponse(r) //todo: dali ovo radi?
    } catch (e) {
        //todo: this says 500 for instance and 'Cannot assign to read only property 'resume' of object '#<Object>'' is what it really is so not really accurate
        const err: any = ErrorManager.getErrorMessageForDisplay(e);
        LogManager.logError(errorMessage, err);

        error = err;
    }

    setError && setError(error);
    setIsBusy && setIsBusy(false);

    //todo: when do we need to do this and when not? for now we pass null sometimes into actions...
    actions?.setSubmitting(false)

    return { error, r }
}

export const apiSubmitAsync = async (valuesGetter: (() => any) | null, actions: any, setError: ((error: string | null) => void) | null, setIsBusy: ((isBusy: boolean) => void) | null, address: string, errorMessage: string, processResponse: ((response: any) => string | null) | null) => {
    setIsBusy && setIsBusy(true);
    let error: any = null;
    let r: AxiosResponse<any, any> | null = null;

    try {
        const url = `${API_URL}${address}`
        r = valuesGetter != null ? await axios.post(url, valuesGetter()) : await axios.get(url)

        error = processResponse && processResponse(r) //todo: dali ovo radi?
    } catch (e) {
        //todo: this says 500 for instance and 'Cannot assign to read only property 'resume' of object '#<Object>'' is what it really is so not really accurate
        const err: any = ErrorManager.getErrorMessageForDisplay(e);
        LogManager.logError(errorMessage, err);

        error = err;
    }

    setError && setError(error);
    setIsBusy && setIsBusy(false);

    //todo: when do we need to do this and when not? for now we pass null sometimes into actions...
    actions?.setSubmitting(false)

    return { error, r }
}

export const apiSimpleSubmitAsync = async (values: any, actions: any, setError: (error: string | null) => void, setIsBusy: (isBusy: boolean) => void, address: string, errorMessage: string) =>
    apiSubmitAsync(() => values, actions, setError, setIsBusy, address, errorMessage, (r) => null);

// export async function apiCall(valuesGetter: () => any, actions: any, setError: (error: string | null) => void, setIsBusy: (isBusy: boolean) => void, address: string, errorMessage: string) {
//     setIsBusy(true);

//     try {
//         await axios.post(`${API_URL}${address}`, valuesGetter());
//         setError(null);
//     } catch (e) {
//         const error: any = ErrorManager.getErrorMessageForDisplay(e);
//         LogManager.logError(errorMessage, error);
//         setError(error);
//     }

//     setIsBusy(false);

//     actions.setSubmitting(false);
// }