import { IntlShape } from "react-intl";

export enum Gender {
    Female = 0,
    Male = 1,
    Other = 2
}

export const GenderStringKeys: { [key in Gender]: string } = {
    [Gender.Female]: "helfy.shared.female",
    [Gender.Male]: "helfy.shared.male",
    [Gender.Other]: "helfy.shared.other",
};

export const getGenderOptions = (intl: IntlShape) => Object.entries(GenderStringKeys).map(([key, value]) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: value })
}));
