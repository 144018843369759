import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { INotificationPreferences } from "../Types";
import { clearError, clearState, GET_PREFERENCES_SUCCESS, getPreferencesAsync, updatePreferencesAsync } from "../Redux/Actions";
import styles from '../Styles/counselor-notificaitons.module.scss';
import { Checkbox } from "../../../Helfy.Components/Checkbox/CheckBoxComponent";
import { PrimaryButton } from '../../../Helfy.Components/Buttons/PrimaryButton';
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import { HelfySuccessModal } from "../../../Helfy.Components/UIStates/HelfySuccessModal";
import { FormattedMessage, useIntl } from "react-intl";


export const NotificationsSettings = React.memo(() => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [isEdit, setEdit] = useState(false);
  const text = intl.formatMessage({ id: 'generic.messages.success.modal.text.update' });
  const title = intl.formatMessage({ id: 'generic.messages.success.modal.title.update' });
  const errorTitle = intl.formatMessage({ id: 'generic.messages.error.modal.title' });
  const preferences = useAppSelector(state => state.preferences.preferences);
  const { isBusy, errorMessage, showError, isSuccess } = useAppSelector(state => state.preferences);

  useEffect(() => {
    async function _dummy() {
      await dispatch(getPreferencesAsync());
    }

    _dummy();
  }, []);

  function handleChange(name: string) {
    if (!isEdit) return;
    const copyPreferences: INotificationPreferences = preferences;
    if (name === "sendSmsWhenClientContactsMe") copyPreferences.sendSmsWhenClientContactsMe = !copyPreferences.sendSmsWhenClientContactsMe;
    if (name === "sendEmailWhenClientContactsMe") copyPreferences.sendEmailWhenClientContactsMe = !copyPreferences.sendEmailWhenClientContactsMe;
    if (name === "sendSmsWithRemindersOfScheduledSession") copyPreferences.sendSmsWithRemindersOfScheduledSession = !copyPreferences.sendSmsWithRemindersOfScheduledSession;
    if (name === "sendEmailWithRemindersOfScheduledSession") copyPreferences.sendEmailWithRemindersOfScheduledSession = !copyPreferences.sendEmailWithRemindersOfScheduledSession;

    dispatch({ type: GET_PREFERENCES_SUCCESS, payload: copyPreferences });
  }

  async function handleEditMode() {
    if (!isEdit) return setEdit(true);
    await dispatch(updatePreferencesAsync(preferences))
  }

  function _clearError() {
    dispatch(clearError())
  }

  async function handleSuccess() {
    dispatch(clearState());
    await dispatch(getPreferencesAsync());
    setEdit(false);
  }

  return (
    <div className={`${styles.myAccountContent} ${styles.notifications}`}>
      <HelfySuccessModal show={isSuccess} title={title} message={text} onConfirmClick={handleSuccess} />
      <HelfyErrorModal show={showError} title={errorTitle} message={errorMessage} onConfirmClick={_clearError} />
      <div className={`${styles.row} row1`}>
        <p className="text1"><FormattedMessage id="notification.preferences.send.email.on.contact" /></p>
        <Checkbox handleChange={handleChange} name="sendEmailWhenClientContactsMe" checked={preferences.sendEmailWhenClientContactsMe} />
      </div>
      <div className={`${styles.row} row2`}>
        <p className="text1"><FormattedMessage id="notification.preferences.send.email.reminder" /></p>
        <Checkbox handleChange={handleChange} name="sendEmailWithRemindersOfScheduledSession" checked={preferences.sendEmailWithRemindersOfScheduledSession} />
      </div>
      <div className={`${styles.row} row2`}>
        <p className="text1"><FormattedMessage id="notification.preferences.send.sms.on.contact" /></p>
        <Checkbox handleChange={handleChange} name="sendSmsWhenClientContactsMe" checked={preferences.sendSmsWhenClientContactsMe} />
      </div>
      <div className={`${styles.row} row2`}>
        <p className="text1"><FormattedMessage id="notification.preferences.send.sms.reminder" /></p>
        <Checkbox handleChange={handleChange} name="sendSmsWithRemindersOfScheduledSession" checked={preferences.sendSmsWithRemindersOfScheduledSession} />
      </div>
      <div className={`${styles.buttonsHolder} ${styles.one}`}>
        <PrimaryButton textId={isEdit ? "generic.messages.save" : "generic.messages.edit"} isBusy={isBusy} onClick={handleEditMode} disabled={isBusy} />
      </div>
    </div>
  )
});
