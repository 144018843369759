import React from 'react';
import { FormattedDate, FormattedTime } from "react-intl";
import tableStyle from "../Styles/transactions.module.scss";
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";
import { TransactionStatusEnum } from '../Types/TransactionStatusEnum';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { Receipt } from '../../Receipt/Receipt';
import { saveAs } from 'file-saver';
import { PrimaryButton } from '../../../Helfy.Components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../Helfy.Components/Buttons/SecondaryButton';

interface Props {
  transaction: any;
}

//todo: kada nam treba react.memo?

export const TransactionItem = ({ transaction }: Props) => {
  const generatePdf = async () => {
    const receipt = <Receipt transaction={transaction} />
    const blob = await pdf(receipt).toBlob();
    saveAs(blob, 'receipt.pdf');
  }

  return (
    <tr className={tableStyle["table-row"]}>
      {/* <td className={`${tableStyle["table-data"]} ${tableStyle.bold} ${tableStyle.img}`}>
        <div className={tableStyle["img-h"]}>
          <HelfyAvatar fullName={transaction.name} profilePhoto={transaction.profileImage}/>
        </div>
        <p className={tableStyle.data}>{transaction.name}</p>
      </td>*/}
      <td className={tableStyle["table-data"]}>{transaction.fromName}</td>
      <td className={tableStyle["table-data"]}>{transaction.toName}</td>
      <td className={tableStyle["table-data"]}>{transaction.description}</td>
      <td className={tableStyle["table-data"]}>{<FormattedDate value={transaction.dateTime} />} &nbsp; {<FormattedTime value={transaction.dateTime} />}</td>
      {/* <td className={`${tableStyle["table-data"]} ${tableStyle.bold}`}>{transaction.credits}</td> */}
      <td className={`${tableStyle["table-data"]} ${tableStyle.bold}`}>{transaction.amount} $</td>
      <td className={tableStyle["table-data"]}>{TransactionStatusEnum[transaction.status]}</td>
      <td className={tableStyle["table-data"]}>{transaction.status === TransactionStatusEnum.Complete && <SecondaryButton textId="generic.messages.download" onClick={generatePdf} />}</td>
    </tr>
  );
};