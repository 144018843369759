//todo: this should be renamed and moved to another folder. or move everything else to another folder. part of the discussion of folder structure
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../Helfy.Store/Store';

//todo: ovo je mozda samo api u rtku jer on ima loading u sebi i ostalo.
interface IEmailLookup {
    loading: boolean
}

const initialState: IEmailLookup = {
    loading: false
}

const registerSlice = createSlice({
    name: "emaillookup",
    initialState: initialState,
    reducers: {
        setState: (state, action: PayloadAction<IEmailLookup>) => {
            const { loading } = action.payload;
            state.loading = loading;
        }
    }
})

export const { setState } = registerSlice.actions
export const emailLookup = registerSlice.reducer
export const getLoading = (state: RootState) => state.emailLookup.loading;
