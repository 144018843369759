import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import styles from './faq.module.scss';
import icRight from '../../Helfy.Assets/Images/icon-arrow-right.svg';

interface Props extends React.PropsWithChildren {
  textId: string;
  headerId: string;
}

interface State {
  show: boolean;
}

export const FaqListItemComponent = ({ textId, headerId, children }: Props) => {
  const [show, setshow] = useState(true);

  const handleClick = () => {
    setshow(!show);
  }

  return (
    <div className={styles.faqListItem} onClick={handleClick}>
      <div className={styles.content}>
        <p><FormattedMessage id={headerId} /></p>
        <img className={show ? styles.opened : styles.closed} src={icRight} alt={headerId} />
      </div>
      {
        show &&
        <div className={styles.description}>
          <p><FormattedMessage id={textId} /></p>
          {children}
        </div>
      }
    </div>
  );
}