import React from 'react';
import { Col, Row } from "react-grid-system";

export const ResourcesContainer = () => {
  return (
    <Row justify="center">
      <Col lg={8} md={8} xs={12} sm={12}>
        <h1>Resources</h1>
        <p>If you are in a crisis or if someone else may be in danger, do not use this site. These resources can provide immediate help:</p>
        <ul>
          <li>Argentina: 101</li>
          <li>Australia: 000 (112 on cell phone)</li>
          <li>Brazil: 911</li>
          <li>Canada: 911</li>
          <li>Chile: 131</li>
          <li>China: 120</li>
          <li>European Union: 112</li>
          <li>Hong Kong: 999</li>
          <li>India: 102</li>
          <li>Indonesia: 118</li>
          <li>Japan: 119</li>
          <li>México: 065</li>
          <li>New Zealand: 111</li>
          <li>Russia: 112</li>
          <li>United Kingdom: 999 or 112</li>
          <li>United States: 911</li>
        </ul>

        <h2>Global Suicide Hotline Resources</h2>
        <p>Suicide hotlines provide help to those in need. Contact a hotline if you need support yourself or need help supporting a friend. If you're concerned about a friend, please encourage the person to contact a hotline as well. If your country is not listed, please contact your local emergency services.</p>
        
        <h3>United States</h3>
        <ul>
          <li>National Suicide Prevention Lifeline
            <ul>
              <li>Website: <a href="http://www.suicidepreventionlifeline.org">www.suicidepreventionlifeline.org</a></li>
              <li>Phone: 1 800 273 TALK (8255)</li>
            </ul>
          </li>
          <li>Lifeline Crisis Chat
            <ul>
              <li>Website: <a href="http://www.suicidepreventionlifeline.org/gethelp/lifelinechat.aspx">www.suicidepreventionlifeline.org/gethelp/lifelinechat.aspx</a></li>
            </ul>
          </li>
          <li>Veterans/Military Crisis Line (for active U.S. service members, veterans, and family members)
            <ul>
              <li>Website: <a href="http://www.veteranscrisisline.net">www.veteranscrisisline.net</a></li>
              <li>Phone: 1 800 273 8255, Press 1</li>
            </ul>
          </li>
          <li>The Trevor Project (for LGBT youth, friends and family members)
            <ul>
              <li>Website: <a href="http://www.thetrevorproject.org">www.thetrevorproject.org</a></li>
              <li>Phone: 1-866-488-7386</li>
            </ul>
          </li>
        </ul>

        <h3>Worldwide</h3>
        <ul>
          <li>Befrienders
            <ul>
              <li>Website: <a href="http://www.befrienders.org">www.befrienders.org</a></li>
            </ul>
          </li>
        </ul>

        <h3>Australia</h3>
        <ul>
          <li>Lifeline Australia
            <ul>
              <li>Website: <a href="http://www.lifeline.org.au">www.lifeline.org.au</a></li>
              <li>Phone: 13 11 14</li>
            </ul>
          </li>
          <li>Kids Helpline
            <ul>
              <li>Website: <a href="http://www.kidshelp.com.au">www.kidshelp.com.au</a></li>
              <li>Phone: 1800 55 1800</li>
            </ul>
          </li>
          <li>Headspace
            <ul>
              <li>Website: <a href="http://www.eheadspace.org.au">www.eheadspace.org.au</a></li>
              <li>Phone: 1800 650 890</li>
            </ul>
          </li>
        </ul>

        <h3>Austria</h3>
        <ul>
          <li>Rat auf Draht
            <ul>
              <li>Website: <a href="http://rataufdraht.orf.at">rataufdraht.orf.at</a></li>
              <li>Phone: 147</li>
            </ul>
          </li>
          <li>TelefonSeelsorge – Notruf 142
            <ul>
              <li>Website: <a href="http://www.telefonseelsorge.at">www.telefonseelsorge.at</a></li>
              <li>Phone: 142 (24 hours/day)</li>
            </ul>
          </li>
        </ul>

        <h3>Belgium</h3>
        <ul>
          <li>Centrum Ter Preventie Van Zelfdoding
            <ul>
              <li>Website: <a href="http://www.zelfmoord1813.be">www.zelfmoord1813.be</a></li>
              <li>Phone: 1813</li>
            </ul>
          </li>
        </ul>

        <h3>Brazil</h3>
        <ul>
          <li>CVV
            <ul>
              <li>Website: <a href="http://www.cvv.org.br">www.cvv.org.br</a></li>
              <li>Phone: 188</li>
              <li>Email: <a href="mailto:atendimento@cvv.org.br">atendimento@cvv.org.br</a></li>
            </ul>
          </li>
        </ul>

        <h3>Canada</h3>
        <ul>
          <li>Kids Help Phone (for youth under 20)
            <ul>
              <li>Phone: 1 800 6686868</li>
              <li>For people over 20, find a crisis center that serves your area: <a href="http://suicideprevention.ca/thinking-aboutsuicide/find-a-crisis-centre">suicideprevention.ca/thinking-aboutsuicide/find-a-crisis-centre</a></li>
            </ul>
          </li>
        </ul>

        <h3>Czech Republic</h3>
        <ul>
          <li>Linka bezpečí
            <ul>
              <li>Website: <a href="https://www.linkabezpeci.cz">www.linkabezpeci.cz</a></li>
              <li>Phone: 116 111</li>
            </ul>
          </li>
          <li>Pražská linka důvěry
            <ul>
              <li>Website: <a href="https://www.csspraha.cz">www.csspraha.cz</a></li>
              <li>Phone: 222 580 697</li>
            </ul>
          </li>
          <li>Linka důvěry Ostrava
            <ul>
              <li>Website: <a href="https://www.mnof.cz">www.mnof.cz</a></li>
              <li>Phone: 596 618 908</li>
              <li>Phone: 737 267 939</li>
            </ul>
          </li>
          <li>Linka duševní tísně Most
            <ul>
              <li>Website: <a href="http://www.mostknadeji.eu/linka-dusevni-tisne">www.mostknadeji.eu/linka-dusevni-tisne</a></li>
              <li>Phone: 476 701 444</li>
              <li>Skype: ldt.most</li>
            </ul>
          </li>
          <li>Linka důvěry DKC
            <ul>
              <li>Website: <a href="https://www.ditekrize.cz">www.ditekrize.cz</a></li>
              <li>Phone: 241 484 149</li>
              <li>Skype: ld_dkc</li>
            </ul>
          </li>
        </ul>

        <h3>Denmark</h3>
        <ul>
          <li>Livslinien
            <ul>
              <li>Website: <a href="http://www.livslinien.dk">www.livslinien.dk</a></li>
              <li>Phone: 70 201 201</li>
            </ul>
          </li>
          <li>Børne
            <ul>
              <li>Website: <a href="https://bornetelefonen.dk/ring">bornetelefonen.dk/ring</a></li>
              <li>Phone: 116 111 (Child Helpline is open daily from 11:00-23:00)</li>
            </ul>
          </li>
        </ul>

        <h3>Finland</h3>
        <ul>
          <li>Suomen Mielenterveysseura
            <ul>
              <li>Website: <a href="https://mieli.fi">mieli.fi</a></li>
              <li>Phone: 09 2525 0111</li>
            </ul>
          </li>
        </ul>

        <h3>France</h3>
        <ul>
          <li>S.O.S Amitié
            <ul>
              <li>Website: <a href="https://www.sos-amitie.com">www.sos-amitie.com</a></li>
              <li>Phone: 09 72 39 40 50</li>
            </ul>
          </li>
        </ul>

        <h3>Germany</h3>
        <ul>
          <li>Telefonseelsorge
            <ul>
              <li>Website: <a href="http://www.telefonseelsorge.de">www.telefonseelsorge.de</a></li>
              <li>Phone: 0800 111 0 111</li>
              <li>Phone: 0800 111 0 222</li>
            </ul>
          </li>
          <li>Nummer gegen Kummer
            <ul>
              <li>Website: <a href="https://www.nummergegenkummer.de">www.nummergegenkummer.de</a></li>
              <li>Phone: 0800 111 0 550 (adults)</li>
              <li>Phone: 0800 111 0 333 (children)</li>
            </ul>
          </li>
        </ul>

        <h3>Greece</h3>
        <ul>
          <li>Klimaka NGO
            <ul>
              <li>Website: <a href="http://www.klimaka.org.gr">www.klimaka.org.gr</a></li>
            </ul>
          </li>
          <li>Suicide Help Greece
            <ul>
              <li>Website: <a href="http://www.suicide-help.gr">www.suicide-help.gr</a></li>
              <li>Phone: 1018</li>
            </ul>
          </li>
        </ul>

        <h3>Hong Kong</h3>
        <ul>
          <li>The Samaritan Befrienders Hong Kong
            <ul>
              <li>Website: <a href="http://www.help4suicide.com.hk">www.help4suicide.com.hk</a></li>
            </ul>
          </li>
          <li>The Samaritan Befrienders Hong Kong
            <ul>
              <li>Website: <a href="http://www.sbhk.org.hk">www.sbhk.org.hk</a></li>
              <li>Phone: 2389 2222</li>
            </ul>
          </li>
          <li>The Samaritans Hong Kong
            <ul>
              <li>Website: <a href="http://www.samaritans.org.hk">www.samaritans.org.hk</a></li>
              <li>Phone: 2896 0000</li>
            </ul>
          </li>
        </ul>

        <h3>Hungary</h3>
        <ul>
          <li>Magyar Lelki Elsősegély Telefonszolgálatok Szövetsége
            <ul>
              <li>Website: <a href="http://www.sos505.hu">www.sos505.hu</a></li>
              <li>Phone: 116 123</li>
              <li>Email: <a href="mailto:sos@sos505.hu">sos@sos505.hu</a></li>
            </ul>
          </li>
          <li>Kek Vonal
            <ul>
              <li>Phone: 116-111</li>
            </ul>
          </li>
        </ul>

        <h3>India</h3>
        <ul>
          <li>AASRA
            <ul>
              <li>Website: <a href="http://www.aasra.info">www.aasra.info</a></li>
              <li>Phone: 91-22-27546669</li>
              <li>Phone: 91-22-27546667</li>
              <li>Email: <a href="mailto:aasrahelpline@yahoo.com">aasrahelpline@yahoo.com</a></li>
            </ul>
          </li>
        </ul>

        <h3>Israel</h3>
        <ul>
          <li>ERAN
            <ul>
              <li>Website: <a href="http://www.eran.org.il">www.eran.org.il</a></li>
              <li>Phone: Free call within Israel: 1201</li>
              <li>Phone: Out of Israel: 972-76-8844400</li>
            </ul>
          </li>
          <li>SAHAR (Emotional support chat line) (Hebrew)
            <ul>
              <li>Website: <a href="http://www.sahar.org.il">www.sahar.org.il</a></li>
            </ul>
          </li>
          <li>SAHAR (Emotional support chat line) (Arabic)
            <ul>
              <li>Website: <a href="http://www.sahar.org.il/?categoryId=63068">www.sahar.org.il/?categoryId=63068</a></li>
            </ul>
          </li>
        </ul>

        <h3>Italy</h3>
        <ul>
          <li>Telefono Azzurro
            <ul>
              <li>Website: <a href="https://www.azzurro.it">www.azzurro.it</a></li>
              <li>Phone: 19696</li>
            </ul>
          </li>
          <li>Telefono Amico
            <ul>
              <li>Website: <a href="http://www.telefonoamico.it">www.telefonoamico.it</a></li>
              <li>Phone: 199 284 284</li>
            </ul>
          </li>
        </ul>

        <h3>Latvia</h3>
        <ul>
          <li>Skalbes
            <ul>
              <li>Website: <a href="http://www.skalbes.lv">www.skalbes.lv</a></li>
              <li>Phone: 371 67222922</li>
              <li>Phone: 371 27722292</li>
            </ul>
          </li>
        </ul>

        <h3>Lithuania</h3>
        <ul>
          <li>Lietuvos emocinės paramos tarnybų asociacija
            <ul>
              <li>Website: <a href="http://www.klausau.lt">www.klausau.lt</a></li>
            </ul>
          </li>
          <li>Vaikų linija (Child line)
            <ul>
              <li>Website: <a href="http://www.vaikulinija.lt">www.vaikulinija.lt</a></li>
              <li>Phone: 116 111</li>
            </ul>
          </li>
          <li>Jaunimo linija (Youth line)
            <ul>
              <li>Website: <a href="http://www.jaunimolinija.lt/laiskai/">www.jaunimolinija.lt/laiskai/</a></li>
              <li>Phone: 8 800 28888</li>
            </ul>
          </li>
          <li>Vilties linija (Hope line)
            <ul>
              <li>Website: <a href="https://www.viltieslinija.lt/">www.viltieslinija.lt</a></li>
              <li>Phone: 116 123</li>
              <li>Email: <a href="mailto:116123@viltieslinija.lt">116123@viltieslinija.lt</a></li>
            </ul>
          </li>
          <li>Pagalbos moterims linija (Women's line)
            <ul>
              <li>Website: <a href="http://www.moteriai.lt">www.moteriai.lt</a></li>
              <li>Phone: 8 800 66366</li>
              <li>Email: <a href="mailto:pagalba@moteriai.lt">pagalba@moteriai.lt</a></li>
            </ul>
          </li>
          <li>Linija Doverija (Support for Russian-speaking clients)
            <ul>
              <li>Phone: 8 800 77277</li>
            </ul>
          </li>
        </ul>

        <h3>Luxembourg</h3>
        <ul>
          <li>SOS Détresse – Hëllef iwwer Telefon
            <ul>
              <li>Website: <a href="http://www.454545.lu">www.454545.lu</a></li>
              <li>Phone: 454545</li>
            </ul>
          </li>
          <li>Kanner-Jugendtelefon
            <ul>
              <li>Website: <a href="http://www.kjt.lu">www.kjt.lu</a></li>
              <li>Phone: 116 111</li>
            </ul>
          </li>
        </ul>

        <h3>Mexico</h3>
        <ul>
          <li>Consejo Ciudadano
            <ul>
              <li>Website: <a href="http://consejociudadanomx.org">consejociudadanomx.org</a></li>
              <li>Phone: 55 5533-5533</li>
            </ul>
          </li>
          <li>Instituto Hispanoamericano de Suicidologia, A.C
            <ul>
              <li>Phone: +5255 46313300</li>
              <li>Email: <a href="mailto:info@suicidiologia.com.mx">info@suicidiologia.com.mx</a></li>
            </ul>
          </li>
        </ul>

        <h3>Netherlands</h3>
        <ul>
          <li>Stichting 113 Zelfmoordpreventie
            <ul>
              <li>Website: <a href="http://www.113online.nl">www.113online.nl</a></li>
              <li>Phone: 0800 0113</li>
            </ul>
          </li>
        </ul>

        <h3>New Zealand</h3>
        <ul>
          <li>National Depression Initiative
            <ul>
              <li>Website: <a href="http://www.depression.org.nz">www.depression.org.nz</a></li>
            </ul>
          </li>
          <li>The Lowdown
            <ul>
              <li>Website: <a href="http://www.thelowdown.co.nz">www.thelowdown.co.nz</a></li>
              <li>Phone: 0800 111 757</li>
              <li>SMS: 5626</li>
              <li>Email: <a href="mailto:team@thelowdown.co.nz">team@thelowdown.co.nz</a></li>
            </ul>
          </li>
          <li>Youthline
            <ul>
              <li>Website: <a href="http://www.youthline.co.nz">www.youthline.co.nz</a></li>
              <li>Phone: 0800 376633</li>
              <li>SMS: 234</li>
              <li>Email: <a href="mailto:talk@youthline.co.nz">talk@youthline.co.nz</a></li>
              <li>Email: <a href="mailto:parenttalk@youthline.co.nz">parenttalk@youthline.co.nz</a></li>
            </ul>
          </li>
        </ul>

        <h3>Norway</h3>
        <ul>
          <li>Kirkens SOS
            <ul>
              <li>Website: <a href="http://www.kirkens-sos.no">www.kirkens-sos.no</a></li>
              <li>Phone: 815 33 300</li>
            </ul>
          </li>
        </ul>

        <h3>Poland</h3>
        <ul>
          <li>Fundacja Dzieci Niczyje
            <ul>
              <li>Website: <a href="http://www.116111.pl/napisz">www.116111.pl/napisz</a></li>
              <li>Phone: 116 111</li>
            </ul>
          </li>
        </ul>

        <h3>Portugal</h3>
        <ul>
          <li>SOS voz amiga
            <ul>
              <li>Website: <a href="https://www.sosvozamiga.org/">www.sosvozamiga.org</a></li>
              <li>Phone: 213 544 545 / 912 802 669 / 963 524 660 (Daily from 15:30 to 00:30)</li>
            </ul>
          </li>
        </ul>

        <h3>Russia</h3>
        <ul>
          <li>Samaritans (Cherepovets)
            <ul>
              <li>Phone: 007 (8202) 577-577 (9:00-21:00)</li>
            </ul>
          </li>
          <li>EMERCOM Psychological Service (Интернет-служба экстренной психологической помощи)
            <ul>
              <li>Website: <a href="https://psi.mchs.gov.ru/">psi.mchs.gov.ru</a></li>
              <li>Phone: 007 (495) 989-50-50</li>
            </ul>
          </li>
        </ul>

        <h3>Serbia</h3>
        <ul>
          <li>Centar Srce
            <ul>
              <li>Website: <a href="http://www.centarsrce.org">www.centarsrce.org</a></li>
              <li>Phone: 0800 300 303</li>
              <li>Email: <a href="mailto:vanja@centarsrce.org">vanja@centarsrce.org</a></li>
            </ul>
          </li>
        </ul>

        <h3>Singapore</h3>
        <ul>
          <li>Samaritans of Singapore (SOS)
            <ul>
              <li>Website: <a href="http://samaritans.org.sg">samaritans.org.sg</a></li>
              <li>Phone: 1800 221 4444</li>
              <li>Email: <a href="mailto:pat@samaritans.org.sg">pat@samaritans.org.sg</a></li>
            </ul>
          </li>
        </ul>

        <h3>South Africa</h3>
        <ul>
          <li>The South African Depression and Anxiety Group (SADAG)
            <ul>
              <li>Website: <a href="http://www.sadag.org">www.sadag.org</a></li>
              <li>Phone: 0800 567 567</li>
              <li>SMS: 31393</li>
            </ul>
          </li>
        </ul>

        <h3>South Korea</h3>
        <ul>
          <li>Korea Foundation for Suicide Prevention (한국생명존중희망재단)
            <ul>
              <li>Website: <a href="http://www.kfsp.org/">www.kfsp.org</a></li>
              <li>Phone: 02-3706-0500</li>
            </ul>
          </li>
          <li>보건복지부 보건복지콜센터 (Ministry of Health & Welfare Call Center)
            <ul>
              <li>Phone: 129 (24시간 위기상담 / 24 hour hotline)</li>
            </ul>
          </li>
          <li>정신건강증진센터 정신건강위기상담전화 (Mental Health Center Crisis Counseling)
            <ul>
              <li>Phone: 1577-0199 (24시간 위기상담 / 24 hour hotline)</li>
            </ul>
          </li>
        </ul>

        <h3>Spain</h3>
        <ul>
          <li>Teléfono de la Esperanza
            <ul>
              <li>Website: <a href="http://telefonodelaesperanza.org">telefonodelaesperanza.org</a></li>
              <li>Phone: 717 00 37 17</li>
            </ul>
          </li>
        </ul>

        <h3>Sweden</h3>
        <ul>
          <li>Mind
            <ul>
              <li>Website: <a href="https://mind.se/">mind.se</a></li>
            </ul>
          </li>
        </ul>

        <h3>Switzerland</h3>
        <ul>
          <li>Tel 143 - La Main Tendue – Die Dargebotene Hand – Telefono Amico
            <ul>
              <li>Website: <a href="http://www.143.ch">www.143.ch</a></li>
              <li>Phone: 143</li>
            </ul>
          </li>
        </ul>

        <h3>Taiwan</h3>
        <ul>
          <li>MOHW Suicide Prevention Line (衛生福利部)
            <ul>
              <li>Website: <a href="https://www.mohw.gov.tw/cp-16-48244-1.html">www.mohw.gov.tw/cp-16-48244-1.html</a></li>
            </ul>
          </li>
          <li>Lifeline
            <ul>
              <li>Website: <a href="http://www.life1995.org.tw/">www.life1995.org.tw</a></li>
            </ul>
          </li>
        </ul>

        <h3>United Kingdom / Ireland</h3>
        <ul>
          <li>Samaritans
            <ul>
              <li>Website: <a href="http://www.samaritans.org">www.samaritans.org</a></li>
              <li>Phone: 116 123</li>
              <li>Email: <a href="mailto:jo@samaritans.org">jo@samaritans.org</a></li>
            </ul>
          </li>
        </ul>

        <h3>Other</h3>
        <p>If your country is not listed, please contact your local emergency services for immediate help.</p>

        <h3>Disclaimer</h3>
        <p>Our list may not be complete, and some phone numbers or email addresses might have changed. Please verify the information with your local resources.</p>
      </Col>
    </Row>
  );
}
