import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import styles from "../../Styles/slides.module.scss";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { ISteps } from '../../../CounselorOnBoarding/Types';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { setPartialState } from '../../Redux/Reducer';

interface Props extends ISteps {
  onSubmit(): Promise<void>;
  isBusy: boolean
}

export const Reason = ({ move, onSubmit, isBusy }: Props) => {
  const message = useAppSelector(s => s.patientOnBoarding.message)
  const dispatch = useAppDispatch();
  const setMessage = (message: string) => dispatch(setPartialState({ message: message }))

  const handleSubmit = async () => {
    await onSubmit()
  }

  return (
    <OnBoardingCardHolder>
      <div className={styles.slide9}>
        <h1 className={styles.title}><FormattedMessage id="on.boarding.patient.reason.title" /></h1>
        <div className={styles.info}>
          <p className={styles.label}><FormattedMessage id="on.boarding.patient.reason.label" /></p>
        </div>
        <div className={styles.field}>
          <label htmlFor="name"><FormattedMessage id="on.boarding.patient.reason.message" /></label>
          <textarea onChange={e => setMessage(e.target.value)} name="name" rows={8} cols={80} wrap="soft" value={message} />
        </div>
        <div className={styles.buttonHolder}>
          <SecondaryButton textId="on.boarding.patient.prev.text" onClick={() => move(-1)} />
          <PrimaryButton textId="on.boarding.patient.button.submit" onClick={handleSubmit} disabled={!message || isBusy} isBusy={isBusy} />
        </div>
      </div>
    </OnBoardingCardHolder>
  )
}