import React from 'react';
import { FastField } from "formik";
import { Col, Row } from "react-grid-system";
import { FormattedMessage, useIntl } from "react-intl";
import styles from '../Styles/resend-email.module.scss';
import { InputField } from "../../../Helfy.Components/Formik/InputField";
import { HelfyFormHolder } from "../../../Helfy.Layout/HelfyFormHolder";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";

interface Props {
  isValid: boolean;
  isSubmitting: boolean;

  handleSubmit(): void;
}

export const ResendEmailView = ({ isSubmitting, isValid, handleSubmit }: Props) => {
  const intl = useIntl();
  return (
    <Col xl={5} lg={5} md={5} xs={12} sm={12}>
      <HelfyFormHolder>
        <p><FormattedMessage id="resend.email.title" /></p>
        <div className={styles.resendEmailDesc}><FormattedMessage id="resend.email.description" /></div>
        <FastField
          type="email"
          name="email"
          id="email"
          component={InputField}
          label={intl.formatMessage({ id: "resend.email.label" })}
          placeholder={intl.formatMessage({ id: "resend.email.placeholder" })}
        />
        <Row justify="center">
          <PrimaryButton textId="resend.email.btn.send" onClick={handleSubmit} disabled={!isValid} isBusy={isSubmitting} />
        </Row>
      </HelfyFormHolder>
    </Col>
  );
}