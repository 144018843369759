import React from 'react';
import female from '../../../../Helfy.Assets/Images/img_man_active.svg'
import other from '../../../../Helfy.Assets/Images/img_other_active.svg';
import male from '../../../../Helfy.Assets/Images/img_woman_active.svg';

import { ISteps } from '../../../CounselorOnBoarding/Types';
import { OnBoardingCardHolder } from '../../../../Helfy.Components/Cards/OnBoardingCardHolder';
import styles from '../../Styles/slides.module.scss';
import { SecondaryButton } from '../../../../Helfy.Components/Buttons/SecondaryButton';
import { PrimaryButton } from '../../../../Helfy.Components/Buttons/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import { HelfyImageButton } from '../../../../Helfy.Components/Select/HelfyImageButton';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { setPartialState } from '../../Redux/Reducer';

interface Props extends ISteps {
}

export const Gender = ({ move }: Props) => {
    const selected = useAppSelector(s => s.patientOnBoarding.gender)
    const dispatch = useAppDispatch();
    const setSelected = (selected: number) => dispatch(setPartialState({ gender: selected }))

    const handleSubmit = () => {
        move(1)
    }

    return (
        <OnBoardingCardHolder>
            <div className={styles.slide4}>
                <h1 className={styles.title}><FormattedMessage id="on.boarding.patient.gender.title" /></h1>
                <div className={styles.content}>
                    <div className={styles.answersArray}>
                        <HelfyImageButton id={0} selected={selected} image={female} imageAlt="Female" translationId="helfy.shared.female" setSelected={setSelected} />
                        <HelfyImageButton id={1} selected={selected} image={male} imageAlt="Male" translationId="helfy.shared.male" setSelected={setSelected} />
                        <HelfyImageButton id={2} selected={selected} image={other} imageAlt="Other" translationId="helfy.shared.other" setSelected={setSelected} />
                    </div>
                </div>
                <div className={styles.buttonHolder}>
                    <SecondaryButton textId="on.boarding.patient.prev.text" onClick={() => move(-1)} />
                    <PrimaryButton textId="on.boarding.patient.button.next" onClick={handleSubmit} disabled={selected === undefined} />
                </div>
            </div>
        </OnBoardingCardHolder>);
}