import React from 'react';
import Swal from "sweetalert2";
import { useEffect } from 'react';
import { useIntl } from "react-intl";
import withReactContent from 'sweetalert2-react-content'
import styles from './ui-states.module.scss';
import { history } from "../../Helfy.Store/History";
import { useNavigate } from 'react-router-dom';

interface Props {
  show: boolean;
  title?: string;
  message: any;
  onConfirmClick?(): void;
}

const EnhancedSwal = withReactContent(Swal);

export const HelfyErrorModal = ({ title, message, show, onConfirmClick }: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const reportIssueText = intl.formatMessage({ id: "contact.us.error.modal.reportIssue" });
  const navLink = <button type="button" className={styles.reportProblem} onClick={navigateAndCloseModal}>{reportIssueText}</button>;

  function navigateAndCloseModal() {
    Swal.close()
    // EnhancedSwal.clickConfirm();
    navigate("/privacy"); //todo: zasto na privacy? to bi trebao biti report issue
  }

  function getErrorMessage(errorMessage: any) {
    if (!errorMessage)
      return intl.formatMessage({ id: "helfy.error.defaultMessage" });

    if (errorMessage && !errorMessage.displayMessage)
      return intl.formatMessage({ id: "helfy.error.defaultMessage" });

    if (errorMessage && errorMessage.displayMessage)
      return errorMessage.displayMessage;
  }

  useEffect(() => {
    if (!show) return;

    EnhancedSwal.fire({
      title: title,
      icon: "error",
      footer: navLink,
      allowOutsideClick: false,
      preConfirm: onConfirmClick,
      confirmButtonColor: "#45ccb1",
      text: getErrorMessage(message)
    })
      .then()
      .catch()
  });

  return <React.Fragment />
}

//todo: dali je potrebno da ovakve stvari budu u kontroli? možda je bolje da bude nekakva helper funkcija?