import React from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss'
import { InputField } from "../../../../Helfy.Components/Formik/InputField";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { HelfySelectField } from "../../../../Helfy.Components/Formik/HelfySelectField";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { ISteps } from '../../Types';
import { getGenderOptions } from '../../../../Helfy.Enums/Gender';
import { useIntl } from 'react-intl';
import { UploadButton } from '../../../../Helfy.Components/Formik/UploadButton';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
}

//todo: rename all forms here because they don't make sense i think. questions and page numbes don't match so harder to find. or maybe leave it i dont know
export const BasicInformationView = ({ move, isValid, handleSubmit }: Props) => {
  const intl = useIntl();

  const genderOptions = getGenderOptions(intl);

  const onSubmit = () => {
    handleSubmit();
    move(1);
  }

  //todo: put intl instead of text here
  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>Account Information</h3>
          <div className={styles.infoContent}>
            <div className={styles.left}>
              <FastField
                id="fullNameInput"
                name="fullName"
                type="text"
                label="Full Name"
                placeholder="Full name"
                component={InputField} />
              <FastField
                id="vatInput"
                name="companyId"
                type="text"
                label="VAT ID"
                placeholder="Enter VAT"
                component={InputField} />
              <FastField
                id="inputCompanyName"
                name="companyName"
                type="text"
                label="Company Name"
                placeholder="Enter company name"
                component={InputField} />
              <FastField
                id="countryInput"
                name="country"
                type="text"
                label="Country"
                placeholder="Country"
                component={InputField} />
              <FastField
                id="linkedUrlInput"
                name="linkedInUrl"
                type="text"
                label="Linkedin profile"
                placeholder="Enter linkedid profile"
                component={InputField} />
              <FastField
                id="genderInput"
                name="gender"
                label="Gender"
                placeholder="Gender"
                options={genderOptions}
                component={HelfySelectField}
              />
            </div>
            <div className={styles.right}>
              <div className={styles.picture}>
                <FastField
                  id="profileImage"
                  name="profileImage"
                  type="file"
                  disabled={false}
                  label="Choose Profile Picture"
                  placeholder="Choose Profile Picture"
                  component={UploadButton} />
              </div>
            </div>
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId="counselor.on.boarding.button.next" onClick={onSubmit} disabled={!isValid} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}