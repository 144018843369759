import React from 'react';
import detailsStyles from '../Styles/details-view.module.scss';
import { ICounselorDetails } from "../Types";
import { HelfyRating } from "../../../Helfy.Components/HelfyRating";
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";
import { NetworkStatusIndicator } from "../../../Helfy.Components/NetworkStatus/NetworkStatusIndicator";

interface Props {
  counselor: ICounselorDetails
}

export const DetailsLeft = React.memo(({ counselor }: Props) => {
  const { status, fullName, coverLetter, profilePhoto, overallRating, specialtiesDisplay, averageResponseTime, spokenLanguagesDisplay } = counselor
  return (
    <React.Fragment>
      <div className={detailsStyles["info-1"]}>
        <div className={detailsStyles.avatar}>
          <HelfyAvatar fullName={fullName} profilePhoto={profilePhoto} />
          <NetworkStatusIndicator status={status} />
        </div>
        <div className={detailsStyles.starsContainer}>
          <HelfyRating rating={overallRating} />
        </div>
        <h3 className={detailsStyles.name}>{fullName}</h3>
        <p className={detailsStyles.response}>{averageResponseTime}</p>
      </div>
      <div className={detailsStyles["info-2"]}>
        <div className={detailsStyles.specialities}>
          <p className={detailsStyles.title}>Specialties</p>
          <p className={detailsStyles.list}>{specialtiesDisplay}</p>
        </div>
        <div className={detailsStyles.languages}>
          <p className={detailsStyles.title}>Languages</p>
          <p className={detailsStyles.list}>{spokenLanguagesDisplay}</p>
        </div>
      </div>
      <div className={detailsStyles["info-3"]}>
        <div className="about" />
        <p className={detailsStyles.title}>ABOUT ME</p>
        <p className={`${detailsStyles.content} p1`}>{coverLetter}</p>
      </div>
    </React.Fragment>
  )

});