import { apiSubmitAsync } from "../../../Helfy.Api/Helpers/Helpers";
import { store } from "../../../Helfy.Store/Store";
import { setAccountInfo, setError, setIsBusy } from "./Reducer";

//todoredux ovako to treba recimo ili slicno
export const loadAccountInfoAsync = async () => {
  const { error, r } = await apiSubmitAsync(null, null, null, isBusy => store.dispatch(setIsBusy(isBusy)), "/account/GetUserInfo", "GET ACCOUNT_INFO", r => {
    store.dispatch(setAccountInfo(r?.data))
    return null;
  })

  store.dispatch(setError(error))
}