import React from 'react';
import styles from '../Styles/video-session.module.scss';
import icPhone from '../../../Helfy.Assets/Images/ic_phone.svg'
import { CallButton } from './CallButton';

interface Props {
  show: boolean;
  titleId: string;
  onClick(): void;
}

export const RedButton = ({ show, onClick, titleId }: Props) => {
  return (
    <CallButton show={show} onClick={onClick} titleId={titleId} imgSrc={icPhone} buttonStyle={styles.redBtn} />
  )
}