import React from 'react';
import { FormattedDate, useIntl } from "react-intl";
import { ISessionRequest } from "../Types";
import styles from '../Styles/modal-windows.module.scss';
import { HelfyModal } from "../../../Helfy.Components/Modal/HelfyModal";

interface Props {
  show: boolean;
  isError: boolean;
  isBusy: boolean;
  request: ISessionRequest,

  accept(): void;
  reject(): void;
}

//todo: this whole isbusy is error thing is just plain wrong rewrite it. basically what it does is show a weird form with no data for instance once you click accept.
//but it still has the accept button and decline shown. so its pretty strange. maybe it was meant for loading and stuff but this also works when pressing accept or decline
export const SessionRequestDetails = React.memo(({ show, isBusy, isError, request, accept, reject }: Props) => {
  const intl = useIntl();
  const { birthDay, counselingBefore, eatingHabits, gender, mentalHealth, physicalHealth, sleepingHabits } = request;
  return (
    <HelfyModal isOpen={show}>
      <div className={`${styles['modal-window']} ${styles["review-request"]}`}>
        <h3>{isBusy ? intl.formatMessage({ id: "generic.messages.loading.request" }) : intl.formatMessage({ id: "generic.messages.request.details" })}</h3>
        <div className={styles["review-list"]}>
          {isError && <div className={styles["invalid-request"]}>{intl.formatMessage({ id: 'generic.messages.unable.to.get.request' })}</div>}
          {
            !isBusy && !isError &&
            <ul>
              <li><span>{intl.formatMessage({ id: "generic.message.gender" })}</span><span>{gender}</span></li>
              <li><span>{intl.formatMessage({ id: "generic.messages.birthday" })}</span><span><FormattedDate value={birthDay} /> </span></li>
              <li><span>{intl.formatMessage({ id: "generic.messages.counseling.before" })}</span><span>{counselingBefore}</span></li>
              <li><span>{intl.formatMessage({ id: "generic.messages.physical.health" })}</span><span>{physicalHealth}</span></li>
              <li><span>{intl.formatMessage({ id: "generic.messages.mental.health" })}</span><span>{mentalHealth}</span></li>
              <li><span>{intl.formatMessage({ id: "generic.messages.sleeping.habits" })}</span><span>{sleepingHabits}</span></li>
              <li><span>{intl.formatMessage({ id: "generic.messages.eating.habits" })}</span><span>{eatingHabits}</span></li>
            </ul>
          }
          <div className={styles["buttons-holder"]}>
            <button onClick={reject} className={`${styles.left} ${styles.reject} ${styles.red}`}>{intl.formatMessage({ id: "generic.messages.reject" })}</button>
            <button onClick={accept} className={`${styles.right} ${styles.request}`}>{intl.formatMessage({ id: "generic.messages.accept" })}</button>
          </div>
        </div>
      </div>
    </HelfyModal>
  );
});