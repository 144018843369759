import React from 'react';
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-grid-system";
import styles from '../Styles/accept-wait.module.scss'
import emailSent from '../../../Helfy.Assets/Images/email-sent.svg'
import { HelfyContainer } from "../../../Helfy.Layout/HelfyContainer";
import { HelfyFormHolder } from "../../../Helfy.Layout/HelfyFormHolder";

export const AcceptWaitContainer = () => {
    return (
        <HelfyContainer paddedTop>
            <Row justify="center">
                <Col xl={8} lg={8} md={8} xs={12} sm={12}>
                    <HelfyFormHolder>
                        <p><FormattedMessage id="accept.wait.title" /></p>
                        <img src={emailSent} alt="accept-email-icon" />
                        <div className={styles.description}>
                            <FormattedMessage id="accept.wait.description" />
                        </div>
                    </HelfyFormHolder>
                </Col>
            </Row>
        </HelfyContainer>
    )
}