import React from 'react';
import { useIntl } from 'react-intl';
import styles from '../Styles/video-session.module.scss';

interface Props {
    show: boolean;
    titleId: string;
    imgSrc: string;
    buttonStyle?: string;
    onClick(): void;
}

export const CallButton = ({ show, onClick, titleId, imgSrc, buttonStyle }: Props) => { //todo: should we have react.memo here? it was here before but if so then we should send functions in a different manner with usecallback
    const intl = useIntl();

    if (!show) return null;
    const msg = intl.formatMessage({ id: titleId })

    return (
        <div className={styles.holder}>
            <button className={`${styles.controlBtn} ${buttonStyle ? buttonStyle : ''}`} onClick={onClick}><img alt={msg} src={imgSrc} /></button>
            <div className={styles.text}>{msg}</div>
        </div>
    )
};