import React from 'react';
import { Label } from "./Components/Label";
import { RowHolder } from "./Components/RowHolder";

import styles from './Styles/row-holder.module.scss';

interface Props {
  id: string;
  form: any;
  field: any;
  errors: any;
  touched: any;
  label: string;
  placeholder: string;
}

export const InputField = ({ field, label, id, placeholder, form, ...rest }: Props) => {
  return (
    <RowHolder form={form} field={field} >
      <Label id={id} label={label} form={form} field={field} />
      <input className={styles.input} id={id} {...field} {...rest} placeholder={placeholder} />
    </RowHolder>
  );
}
