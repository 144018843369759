import React from 'react';
import { Formik } from "formik";
import { Questions2View } from "./Questions2View";
import { Questions2Schema } from "./Questions2Schema";
import { ISteps } from '../../Types';
import { setState } from '../../Redux/Reducer';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';

interface Props extends ISteps {
  onSubmit(values: any): Promise<void>;
  isBusy: boolean
}

export const Questions2 = (p: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.counselorOnBoarding);
  const userHasFilledProfile = useAppSelector(state => state.app.userHasFilledProfile);

  const handleSubmit = async (values: any,) => {
    dispatch(setState(values))

    if (userHasFilledProfile) // if filled profile we won't go further this will be the end one
      await p.onSubmit(values)
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={state} validationSchema={Questions2Schema} validateOnMount>
      {props => <Questions2View {...props} {...p} />}
    </Formik>
  )
}