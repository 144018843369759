import React from "react";
import StarRatings from "react-star-ratings";

export function StarRatingComponent(props) {
  return (
    <StarRatings
      numberOfStars={5}
      rating={props.rating}
      name="overallRating"
      starHoverColor="#F7D31B"
      starRatedColor="#F7D31B"
      changeRating={props.handleChange}
      starSpacing={props?.starSpacing || "1px"}
      starDimension={props?.starDimension || "35px"}
    />
  )
}