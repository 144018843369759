export enum MentalHealth {
    Bad = 0,
    Average = 1,
    Good = 2
}

export const MentalHealthStringKeys: { [key in MentalHealth]: string } = {
    [MentalHealth.Bad]: "on.boarding.patient.bad",
    [MentalHealth.Average]: "on.boarding.patient.average",
    [MentalHealth.Good]: "on.boarding.patient.good",
};