import { Formik } from "formik";
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import React, { useEffect, useState } from 'react';
import styles from '../Styles/payout.module.scss';
import { PayoutSchemaGetter } from "../Schema";
import { PayoutView } from "../View/PayoutView";
import { GreenLoader } from "../../../Helfy.Components/Loaders/GreenLoader";
import { BackNavigation } from "../../../Helfy.Components/NavBar/BackNavigation";
import { HelfySuccessModal } from "../../../Helfy.Components/UIStates/HelfySuccessModal";
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import { apiSimpleSubmitAsync, apiSubmitAsync } from "../../../Helfy.Api/Helpers/Helpers";
import { loadAccountInfoAsync } from "../../AccountInfo/Redux/Actions";
import { HelfySimpleErrorModal } from "../../../Helfy.Components/UIStates/HelfySimpleErrorModal";
import { HelfySimpleSuccessModal } from "../../../Helfy.Components/UIStates/HelfySimpleSuccessModal";

export const PayoutContainer = React.memo(() => {
  const intl = useIntl()
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>("")
  const [isBusyPayout, setIsBusyPayout] = useState(false)
  const { accountInfo, isBusy } = useAppSelector(state => state.account)
  const credits = accountInfo.credits;
  const payoutSchema = PayoutSchemaGetter(intl, credits)

  useEffect(() => {
    //todo: this runs twice for some reason
    loadAccountInfoAsync().then() //should we create a better api call that loads less data. We are loading here specifally so that we have more recent credits data
  }, [])

  function handleSuccess() {
    navigate("/")
  }


  async function handleSubmit(values: any, actions: any) {
    const { error } = await apiSimpleSubmitAsync(values, actions, setError, setIsBusyPayout, "/Payment/Payout", "Payout")
  }

  const errorTitle = intl.formatMessage({ id: "generic.messages.payout.error.title" });
  const title = intl.formatMessage({ id: "generic.messages.payout.success.title" })
  const successText = intl.formatMessage({ id: "generic.messages.payout.success.text" });

  const initialValues = {
    amount: 0
  }

  return (
    <>
      <BackNavigation title={intl.formatMessage({ id: "generic.messages.payout" })} navigateTo="/account/payments" show={true} />
      <div className={styles.payout}>
        <section className={styles.holder}>
          <div className={styles.content}>
            {(isBusy || isBusyPayout) && <GreenLoader width={50} height={50} isWhiteBackGround={false} />}
            {!(isBusy || isBusyPayout) && <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize={true} component={PayoutView} validationSchema={payoutSchema} />}

            <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
            <HelfySimpleSuccessModal error={error} title={title} message={successText} onConfirmClick={handleSuccess} />
          </div>
        </section>
      </div>
    </>
  )
});