import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from './hero-section.module.scss';
import imageHero from '../../../../Helfy.Assets/Images/image-hero.webp'
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";

interface Props {
  onRegisterClick(): void;
}

export class HeroSection extends React.PureComponent<Props> {

  render() {
    return (
      <section className={styles.heroSection}>
        <div className={styles.heroLeft}>
          <h1><FormattedMessage id="landing.page.heroSection.heading"/></h1>
          <PrimaryButton buttonStyles={styles.heroBtn} textId="landing.page.register.button" onClick={this.props.onRegisterClick}/>
        </div>
        <div className={styles.heroRight}>
          <img src={imageHero} alt="Hero"/>
        </div>
      </section>
    );
  }
}