import React from 'react';
import { Formik } from "formik";
import { BasicInformationSchema } from "./BasicInformationSchema";
import { BasicInformationView } from "./BasicInformationView";
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { setState } from '../../Redux/Reducer';
import { ISteps } from '../../Types';

interface Props extends ISteps {
}

export const BasicInformation = (p: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.counselorOnBoarding);

  const handleSubmit = (values: any) => {
    dispatch(setState(values))
  }

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={state} validationSchema={BasicInformationSchema} validateOnMount>
      {props => <BasicInformationView {...props} {...p} />}
    </Formik>
  )
}