import React from 'react';
import Modal from 'react-modal';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { setConfiguration } from 'react-grid-system';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from "../Helfy.Store/Store";
import { LocalizationProvider } from "../Helfy.Providers/Localization/LocalizationProvider";
import { MainPage } from '../Helfy.Containers/MainPage/MainPage';

//todo: vjerovatno brisi jer vise ne treba
// interface Props {
//   role: string;
//   authToken: string;
//   isAuthenticated: boolean;
// }

Modal.setAppElement("body"); //todo: is this the best place to put this in?

// Configure grid system
setConfiguration({ gutterWidth: 0 }); //todo: maybe we should make this higher so it looks similar to our older app

//(window as any).soundManager.setup({ debugMode: false }); //this will prevent logs from react sound //todo: dali ovo treba?, izgleda kada ovo upalim sve prestane raditi


//todo: remove ako ne treba, ali mislim da treba nesto vezano za history je ovo?
// const CustomRouter = ({ history, ...props }) => {
//   const [state, setState] = useState({
//     action: history.action,
//     location: history.location
//   });

//   useLayoutEffect(() => history.listen(setState), [history]);

//   return (
//     <Router
//       {...props}
//       location={state.location}
//       navigationType={state.action}
//       navigator={history}
//     />
//   );
// };


//todo: srediti notfound
const getRouter = () => createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='*' index element={<MainPage />} />
    </>
    // <Route path="*" element={<NotFound />} />
    // <Route path='/' index element={<div>blaa</div>} />
  )
)

// {isAuthenticated && role === RoleEnum.Patient.stringValue && <PatientLayout />}
// {isAuthenticated && role === RoleEnum.Counselor.stringValue && <CounselorLayout />}
// {!isAuthenticated && <LandingPageLayout />}
// {isAuthenticated && <HelfyAuthorization />}
// </Route>
export const App = () => {
  const router = getRouter()
  return (
    <div id="main" data-testid='app'>
      <LocalizationProvider defaultLanguage="en">
        <PersistGate persistor={persistor}>
          {/* <Router> history={history}> todo: pogledati tako je prije bilo vjerovatno nece raditi dobro history. ili mozda oce treba skuziti */}
          <RouterProvider router={router} />
        </PersistGate>
      </LocalizationProvider>
    </div>
  );
}



//todo: ovako je prije bilo izbrisi
// export const App = () => {
//   const isAuthenticated = useSelector((s: RootState) => s.app.isAuthenticated)
//   const role = useSelector((s: RootState) => s.app.role)
//   return (
//     <div id="main" data-testid='app'>
//       <LocalizationProvider defaultLanguage="en">
//         <PersistGate persistor={persistor}>
//           {/* <Router> history={history}> todo: pogledati tako je prije bilo vjerovatno nece raditi dobro history. ili mozda oce treba skuziti */}
//           <BrowserRouter>
//             <EnsureUserHasFilledProfileGuard>
//               {!isAuthenticated && <LandingPageLayout />}
//             </EnsureUserHasFilledProfileGuard>
//           </BrowserRouter>
//         </PersistGate>
//       </LocalizationProvider>
//     </div>
//   );
// }



// export const App = () => {
//   const isAuthenticated = useSelector((s: RootState) => s.app.isAuthenticated)
//   const role = useSelector((s: RootState) => s.app.role)
//   return (
//     <div id="main" data-testid='app'>
//       <LocalizationProvider defaultLanguage="en">
//         <PersistGate persistor={persistor}>
//           {/* <Router> history={history}> todo: pogledati tako je prije bilo vjerovatno nece raditi dobro history. ili mozda oce treba skuziti */}
//           <BrowserRouter>
//             <EnsureUserHasFilledProfileGuard>
//               {isAuthenticated && role === RoleEnum.Patient.stringValue && <PatientLayout />}
//               {isAuthenticated && role === RoleEnum.Counselor.stringValue && <CounselorLayout />}
//               {!isAuthenticated && <LandingPageLayout />}
//               {isAuthenticated && <HelfyAuthorization />}
//             </EnsureUserHasFilledProfileGuard>
//           </BrowserRouter>
//         </PersistGate>
//       </LocalizationProvider>
//     </div>
//   );
// }


// export const App = () => {
//   const isAuthenticated = useSelector((s: RootState) => s.app.isAuthenticated)
//   const role = useSelector((s: RootState) => s.app.role)
//   return (
//     <div id="main" data-testid='app'>
//       <LocalizationProvider defaultLanguage="en">
//         <PersistGate persistor={persistor}>
//           {/* <Router> history={history}> todo: pogledati tako je prije bilo vjerovatno nece raditi dobro history. ili mozda oce treba skuziti */}
//           <BrowserRouter>
//             <LandingPageLayout />
//           </BrowserRouter>
//         </PersistGate>
//       </LocalizationProvider>
//     </div>
//   );
// }