import React, { useEffect, useState } from 'react';
import styles from '../Styles/basic-info.module.scss';
import { Formik } from "formik";
import { BasicInfoSchema } from "./BasicInfoSchema";
import { BasicInformationComponent } from "../Components/BasicInformationComponent";
import { AccountContentHolder } from '../../../Helfy.Layout/Account/AccountContentHolder';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { IUserInformation } from '../Types';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { useHelfyIntl } from '../../../Helfy.Utility/TranslationUtilities';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';

//todo: why do we use redux here? why just not normal states?
export const BasicInformationContainer = () => {
  const [error1, setError1] = useState<string | null>("")
  const [error2, setError2] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const defaultState: IUserInformation = { email: "", firstName: "", lastName: "", profilePhoto: "" }
  const [state, setState] = useState(defaultState)
  const intl = useHelfyIntl();

  const loadUserBasicInformation = async () => {
    const { r, error } = await apiSubmitAsync(null, null, setError1, setIsBusy, "/account/GetUserBasicInformation", "Load User Basic information", null)

    if (!error)
      setState(r?.data)
  }

  const updateUserBasicInformation = async (data: any) => {
    const dataWithoutPhoto = { ...data, profilePhoto: "" }; //todo: za sada ne saljemo sliku odavde nego iz questionarea
    const { error, r } = await apiSubmitAsync(() => dataWithoutPhoto, null, setError2, setIsBusy, "/account/SetUserBasicInformation", "Save User Basic information", null);

    if (!error)
      setState(data)
  }

  useEffect(() => {
    loadUserBasicInformation().then();
  }, [])

  const onSubmit = async (values: any, actions: any) => {
    // const newValues = { ...values, profilePhoto: "bla" };
    await updateUserBasicInformation(values);
    actions.setSubmitting(false);
    // await loadUserBasicInformation(); //todo: ovo bilo prije sta ce nam load ako smo mi saveali
  }

  const onConfirmClick = () => {
  }

  return (
    <AccountContentHolder className={styles["basic-info"]}>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={BasicInfoSchema}
        component={BasicInformationComponent}
        initialValues={state}
      />

      <HelfySimpleErrorModal error={error1} setError={setError1} title={intl.formatMessage({ id: "basic.information.error.load" })} />
      <HelfySimpleErrorModal error={error2} setError={setError2} title={intl.formatMessage({ id: "basic.information.error.save" })} />
      <HelfySimpleSuccessModal error={error2} title={intl.formatMessage({ id: "basic.information.success.save.title" })} message={intl.formatMessage({ id: "basic.information.success.save.text" })} onConfirmClick={onConfirmClick} />
    </AccountContentHolder>
  );
}