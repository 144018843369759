import Yup from '../../../../Helfy.Validation/HelfyYupLocalizedValidation'


export const HelfyQuestions1Schema = Yup.object().shape({
  referenceContact: Yup.string()
    .required(),
  estimatedTime: Yup.string()
    .required(),
  experienceOnline: Yup.string()
    .required(),
  reasonForOnlineCounseling: Yup.string()
    .required(),
});
