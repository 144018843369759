let CHAT_URLTMP, API_URLTMP = "";

// if (true) {
if (process.env.NODE_ENV === 'production') {
  const base = "https://helfy-api-beta.azurewebsites.net/";
  CHAT_URLTMP = base + "chat";
  API_URLTMP = CHAT_URLTMP = base + "api";
}
else {
  CHAT_URLTMP = 'http://localhost:8080/chat"';
  API_URLTMP = 'http://localhost:8080/api';
}

export const CHAT_URL = CHAT_URLTMP;
export const API_URL = API_URLTMP;
