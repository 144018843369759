import React, { useEffect, useState } from 'react';
import { Profile } from "../Views/Step 03/Profile";
import { Welcome } from "../Views/Step 01/Welcome";
import { HelfyNavBar } from "../../../Helfy.Components/NavBar/HelfyNavBar";
import styles from "../../PatientOnBoarding/Styles/onboarding-patient.module.scss";
import { StepIndicator } from "../../../Helfy.Components/Steps/StepIndicator";
import { BasicInformation } from "../Views/Step 02/BasicInformation";
import { HelfyQuestions1 } from "../Views/Step 06/HelfyQuestions1";
import { HelfyQuestions2 } from "../Views/Step 07/HelfyQuestions2";
import { HelfySteps } from '../../../Helfy.Components/Steps/HelfySteps';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { setState } from '../Redux/Reducer';
import { useStepState } from '../../../Helfy.Components/Steps';
import { setUserProfileFilled } from '../../../Helfy.App/Redux/Reducer';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { profile } from 'console';
import { setCounselorOnboardingAsync } from '../Redux/Action';
import { Questions1 } from '../Views/Step 04/Questions1';
import { Questions2 } from '../Views/Step 05/Questions2';
import { HelfyQuestions3 } from '../Views/Step 08/HelfyQuestions3';

export const CounselorOnBoardingContainer = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const { step, move, totalSteps, setTotalSteps } = useStepState();
  const intl = useIntl();
  const dispatch = useAppDispatch()
  const userHasFilledProfile = useAppSelector(state => state.app.userHasFilledProfile);
  const navigateUrl = userHasFilledProfile ? "/account/questionnaire" : "/";

  //todo: ovo treba u react query
  const loadSpecialitiesAsync = () => apiSubmitAsync(null, null, null, null, "/Options/GetSpecialties", "LOAD_SPECIALITIES ERROR", () => null)
  const loadLanguagesAsync = () => apiSubmitAsync(null, null, null, null, "/Options/GetLanguages", "LOAD_LANGUAGES ERROR", () => null)

  const givenName = useAppSelector(a => a.app.givenName);
  const state = useAppSelector(a => a.counselorOnBoarding);

  useEffect(() => {
    const fetchAll = async () => {
      const { error: e1, r: r1 } = await loadLanguagesAsync();
      const { error: e2, r: r2 } = await loadSpecialitiesAsync();

      const error = (e1 || "") + (e2 || "")
      return { error, r1, r2 }
    }

    const error = fetchAll().then(r => {
      const { error, r1, r2 } = r;

      //todo: language options i specialties have to go to react query. no need to go to redux
      //todo: this means if load fails you cannot continue ever. for now ok but later definitely no
      dispatch(setState({
        ...state,
        languagesOptions: r1?.data,
        specialtiesOptions: r2?.data,
        fullName: givenName //todo: sta to nije u dati isto?? od statea vec ima neki fullname
      }));

      if (!!error) //todo: promijenio sam ovo iz error!=null u !!error 
        setError(error)
    });
  }, [])  //todo: it would be better one time mode for whole onboarding process 

  const cancel = () => {
    navigate(navigateUrl);
  }

  const handleSuccess = async () => {
    dispatch(setUserProfileFilled());
    navigate(navigateUrl);
  }

  const submitAnswers = async (values: any) => {
    await setCounselorOnboardingAsync(values, setError, setIsBusy)
  }

  const steps = [
    <Welcome move={move} cancel={cancel} />,
    <BasicInformation move={move} />,
    <Profile move={move} />,
    <Questions1 move={move} />,
    <Questions2 move={move} onSubmit={submitAnswers} isBusy={isBusy} />,
    <HelfyQuestions1 move={move} />,
    <HelfyQuestions2 move={move} />,
    <HelfyQuestions3 move={move} onSubmit={submitAnswers} isBusy={isBusy} />
  ]

  return (
    <div style={{ height: '100vh' }}>
      <HelfyNavBar titleLeftId="counselor.onBoarding.navBar.title" />
      <section className={styles.carouselSection}>
        <div className={styles.carouselCounter}>
          <StepIndicator currentStep={step} totalSteps={totalSteps} />
        </div>
        <HelfySteps step={step} setTotalSteps={setTotalSteps} steps={steps}>
          {/* <Welcome move={move} cancel={cancel} />
          <BasicInformation move={move} />
          <Profile move={move} />
          <FirstProfileQuestion move={move} />
          <SecondProfileQuestion move={move} />
          <ThirdQuestion move={move} />
          <FourthProfileQuestion move={move} onSubmit={fillProfileAsync} isBusy={isBusy} /> */}
        </HelfySteps>

        <HelfySimpleErrorModal error={error} setError={setError} title={intl.formatMessage({ id: "counselor.on.boarding.error.title" })} />
        <HelfySimpleSuccessModal error={error} title={intl.formatMessage({ id: "counselor.on.boarding.success.title" })} message={intl.formatMessage({ id: "counselor.on.boarding.success.text" })} onConfirmClick={handleSuccess} />
      </section>
    </div>
  );
}