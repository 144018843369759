import React from 'react';
import Swal from "sweetalert2";
import { useEffect } from 'react';

interface Props {
  show: boolean;
  title: string;
  message: string;
  onConfirmClick(): void;
}

export const HelfySuccessModal = ({ title, message, show, onConfirmClick }: Props) => {
  useEffect(() => {
    if (!show) return;

    Swal.fire({
      title: title,
      text: message,
      icon: "success",
      allowOutsideClick: false,
      preConfirm: onConfirmClick,
      confirmButtonColor: "#45ccb1"
    })
      .then()
      .catch()
  });

  return <React.Fragment />
}