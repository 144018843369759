import React from 'react';
import { ErrorMessage } from "formik";
import styles from '../Styles/row-holder.module.scss';

interface Props {
  form: any;
  field: any;
  id: string;
  label: string;
}

export class Label extends React.PureComponent<Props> {

  render() {
    const {form, field, id, label} = this.props;
    const {errors, touched} = form;
    return (
      <label className={styles.label} htmlFor={id}>
        {errors[field.name] && touched[field.name] ? <ErrorMessage name={field.name}/> : label}
      </label>
    );
  }
}