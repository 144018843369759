import 'react-intl';

const genericError = {
  statusCode: 500,
  displayMessage: "Error has occurred!\nPlease try again later."
};

export class ErrorManager {
  static transformErrorMessages(error: any) {
    if (!error) return genericError;
    if (!error.response) return genericError;
    if (!error.response.data) return genericError;
    return error.response.data;
  }

  static getErrorMessageForDisplay(error: any) {
    if (!error) return genericError;
    if (!error.response) return genericError;
    if (!error.response.data) return genericError;

    const {DisplayMessage} = error.response.data; // todo: ponekada barem iz c# neznam dali je to inace e.response.data bude neki array stringova što su greške pogotovo ako je to na vcalidaciji modela. tu se onda ne vidi dobro error

    return {displayMessage: DisplayMessage};
  }
}




//todo: you need to do it differently like something below. for instance for 401 it doesn't work
// import 'react-intl';
// type HelfyError = {
//   statusCode: number;
//   displayMessage: string;
// }

// const genericError: HelfyError = {
//   statusCode: 500,
//   displayMessage: "Error has occurred!\nPlease try again later."
// };

// export class ErrorManager {
//   static transformErrorMessages(error: any): HelfyError {
//     //todo: I don't see a need for both of these, before they had little differences. For isntance both were sending genericerror when statuscode error.response.displaymessage didn't
//     // exist. This one returned display message directly and the other one inside an object so not directly. I think this is cleaner.I think we should just use getErrorMessageForDisplay and delete this one
//     return this.getHelfyError(error)
//   }

//   static getErrorMessageForDisplay(error: any): HelfyError {
//     return this.getHelfyError(error)
//   }

//   private static getHelfyError(error: any): HelfyError {
//     if (
//       error &&
//       error.response &&
//       'statusCode' in error.response &&
//       typeof error.response.statusCode === 'number' &&
//       'displayMessage' in error.response &&
//       typeof error.response.displayMessage === 'string'
//     ) {
//       return { displayMessage: error.response.statusCode, statusCode: error.response.statusCode };
//     } else {
//       return genericError;
//     }
//   }
// }
