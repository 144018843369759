import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from '../../../Helfy.Store/Hooks';
import { IPaymentMethods } from "../Types";
import { Payout } from "../Components/Payout";
import { BuyCredits } from "../Components/BuyCredits";
import { RoleEnum } from "../../../Helfy.Enums/RoleEnum";
import { VerifyPaypal } from "../Components/VerifyPaypal";
import styles from '../Styles/payment-methods.module.scss'
import { CardPlaceholder } from "../Components/CardPlaceholder";
// import { clearError, clearState, loadPaymentMethodsAsync } from "../Redux/Actions";
import { HelfyErrorModal } from "../../../Helfy.Components/UIStates/HelfyErrorModal";
import { HelfySuccessModal } from "../../../Helfy.Components/UIStates/HelfySuccessModal";
import { ScreenLoadingIndicator } from "../../../Helfy.Components/Loaders/ScreenLoadingIndicator";
import { useLocation } from 'react-router-dom';
import { AuthorizeStripe } from '../Components/AuthorizeStripe';
import { apiGetPostAsync, apiSimpleSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';
import { AccountContentHolder } from '../../../Helfy.Layout/Account/AccountContentHolder';

export const PaymentMethodsContainer = React.memo(() => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const role = useAppSelector(state => state.app.role);
  const [isVerified, setIsVerified] = useState(false);
  const errorTitle = intl.formatMessage({ id: 'generic.messages.error.modal.title' });
  const purchaseSuccessText = intl.formatMessage({ id: 'generic.messages.payment.purchase.success' });
  const purchaseCancelText = intl.formatMessage({ id: 'generic.messages.payment.purchase.cancel' });
  const title = intl.formatMessage({ id: 'generic.messages.success.modal.title.update' });
  const stripeVerified = intl.formatMessage({ id: "generic.messages.stripe.verified" });
  const stripeNotVerified = intl.formatMessage({ id: "generic.messages.stripe.not.verified" });
  const [isBusy, setIsBusy] = useState(false)

  // from parameters we will get if payments is succesfull or cancelled.
  const search = new URLSearchParams(useLocation().search);
  const isSuccess = search.get('success') === "true";
  const isCanceled = search.get('canceled') === "true";
  const isReturn = search.get('return') === "true"; //we don't actually use this. it's just for reference
  const isRefresh = search.get('refresh') === "true";// we probbaly won't use this.

  // const options: IPaymentMethods = useAppSelector(state => state.paymentMethods.options);
  // const { isBusy, showError, errorMessage, isSuccess } = useAppSelector(state => state.paymentMethods);
  //todo: delete paypal parts probably. this is old
  //todo: we load this everytime pretty primitive maybe it should be in app state? or maybe local state is ok actually
  useEffect(() => {
    async function _dummy() {
      const { error, r } = await apiGetPostAsync(true, null, null, null, null, "/Account/loadPayoutSettings", "load payout settings", null);
      const verified = r?.data?.payoutMethodVerified || false;
      setIsVerified(verified);

      // if (isRefresh && !verified) { //i don't think we will need this?
      //   await authorizeStripeAsync();
      // }
    }

    _dummy();
  }, []);

  const authorizeStripeAsync = async () => {
    const { error, r } = await apiGetPostAsync(false, null, null, null, setIsBusy, "/Payment/AuthorizePayout", "authorize payout", null);
    if (!error)
      window.location.href = r?.data?.url; // change location to url that we get from api
  }

  function handleError() {
    // dispatch(clearError())
  }

  async function handleSuccess() {
    // dispatch(clearState());
    // await dispatch(loadPaymentMethodsAsync());
  }

  return (
    <AccountContentHolder className={`${styles["my-account-content"]} ${styles.payment}`}>
      <div className={`${styles.paypal} ${styles.section}`}>
        <p className={styles.title}>{intl.formatMessage({ id: "generic.messages.stripe" })}</p>
        <div className={styles.content}>
          <React.Fragment>
            {role !== RoleEnum.Counselor.stringValue && (
              <BuyCredits show={true} />
            )}
            {role === RoleEnum.Counselor.stringValue && (
              <ScreenLoadingIndicator show={isBusy}>
                <p className={styles.text}>{isVerified ? stripeVerified : stripeNotVerified}</p>
                {/* todo: isBusy  in authorizestripe here doesn't make sense as it wont be displayed anyway */}
                <AuthorizeStripe show={!isVerified} isBusy={isBusy} authorizeStripeAsync={authorizeStripeAsync} />
                <Payout show={true} disabled={!isVerified} />
              </ScreenLoadingIndicator>
            )}
            {/* <CardPlaceholder /> */}
          </React.Fragment>
        </div>
      </div>
      <HelfySuccessModal show={isSuccess} title={title} message={purchaseSuccessText} onConfirmClick={handleSuccess} />
      <HelfyErrorModal show={isCanceled} title={errorTitle} message={{ displayMessage: purchaseCancelText }} onConfirmClick={handleError} />
    </AccountContentHolder>
  )
  //todo: zasto u error modalu nije direktno message string već mora biti objekt sa displayMessage?
});