import React from 'react';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ISelectedRoom } from "../../Types";
import styles from '../../Styles/chat.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../Helfy.Store/Hooks';
import { useHelfyIntl } from '../../../../Helfy.Utility/TranslationUtilities';
import { HelfyErrorModal } from '../../../../Helfy.Components/UIStates/HelfyErrorModal';
import { RoleEnum } from '../../../../Helfy.Enums/RoleEnum';

interface Props {
  hasFreeSession: boolean;
  selectedRoom: ISelectedRoom

}

export const StartSessionButton = React.memo(({ selectedRoom }: Props) => {
  const price = useAppSelector(i => i.participant.selectedRoom.pricePerHour)
  const credits = useAppSelector(i => i.account.accountInfo.credits); //todo: malo je tu credits malo negdje drugdje treba to pogledati
  const isPatient = useAppSelector(i => i.app.role) === RoleEnum.Patient.stringValue;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const intl = useHelfyIntl()

  const onErrorClick = () => {
    setError("")
    navigate("/account/payments");
  }

  const onClick = () => {
    if (isPatient && credits < price)
      setError(intl.getString("video.call.not.enough.credits.text"))
    else
      navigate("/video-call")
  }

  //todo: this was before but I've changed it so that it shows enter video room in the chat room and we will move this message somewhere else
  // {!selectedRoom.isTrial && <FormattedMessage id="chat.header.start.session"/>}
  // {selectedRoom.isTrial && <FormattedMessage id="chat.header.start.free.session"/>}

  return (
    <>
      <button className={styles.creditsBtn} onClick={onClick}>
        <FormattedMessage id="chat.header.enter.video.room" />
      </button>
      <HelfyErrorModal title={intl.getString("video.call.not.enough.credits.title")} onConfirmClick={onErrorClick} message={{ displayMessage: error }} show={!!error} />
    </>
  )
});