import React from 'react';
import { FormattedMessage } from "react-intl";
import styles from '../../Styles/counselor-on-boarding-slides.module.scss';
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { ISteps } from '../../Types';
import { useAppSelector } from '../../../../Helfy.Store/Hooks';
import { CancelButton } from '../../../../Helfy.Components/Buttons/CancelButton';

interface Props extends ISteps {
  cancel: () => void;
}

export const Welcome = ({ move, cancel }: Props) => {
  const userHasFilledProfile = useAppSelector(state => state.app.userHasFilledProfile);

  return (
    <OnBoardingCardHolder>
      <div className={styles.slide1}>
        <h1 className={styles.intro}><FormattedMessage id="counselor.on.boarding.welcome.title" /></h1>
        <p className={styles.introContent}><FormattedMessage id="counselor.on.boarding.welcome.text" /></p>
        <div className={`${styles.buttonHolder} ${!userHasFilledProfile && styles.center}`}>
          {userHasFilledProfile && <CancelButton textId="generic.messages.cancel" onClick={cancel} />}
          <PrimaryButton textId="counselor.on.boarding.button.next" onClick={() => move(1)} />
        </div>
      </div>
    </OnBoardingCardHolder>
  );
}
