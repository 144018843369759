import React from 'react';
import Select from "react-select";
import { useIntl } from "react-intl";
import styles from '../Styles/transactions.module.scss';
import { NoTransactions } from "../Components/NoTransactions";
import icSearch from '../../../Helfy.Assets/Images/ic_search.svg';
import icDown from '../../../Helfy.Assets/Images/ic_arrow_down.svg';
import { singleStyles } from "../../../Helfy.Components/Select/Styles";
import { TransactionLoader } from "../Components/TransactionLoader";
import { TransactionItem } from "../Components/TransactionItem";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../Helfy.Components/Buttons/SecondaryButton";

interface Props {
  timeOptions: any;
  typeOptions: any;
  isBusy: boolean;
  showNoResult: boolean;
  transactions: Array<any>;
  selectedTimeOption: any;
  selectedTypeOption: any;

  onSearch(): void;
  onHistoryDownload(): void;
  handleTextChange(e: any): void;
  handleSelectTypeChange(type: any): void;
  handleSelectTimeChange(time: any): void;
}

export const TransactionsView = (
  {
    isBusy,
    showNoResult,
    timeOptions,
    typeOptions,
    onSearch,
    transactions,
    onHistoryDownload,
    handleSelectTypeChange,
    selectedTypeOption,
    handleTextChange,
    selectedTimeOption,
    handleSelectTimeChange
  }: Props) => {

  const intl = useIntl();

  //todo: i don't think we need total definitely not in this way
  // function getTotal() {
  //   return transactions.reduce((sum, x) => sum + x.amount, 0);
  // }

  return (
    <div className={`${styles["my-account-content"]} ${styles.transaction}`}>
      <div className={`${styles.filters} ${styles.section}`}>
        <div className={styles.search}>
          <img src={icSearch} alt="Search" />
          <input onChange={handleTextChange} type="text" placeholder={intl.formatMessage({ id: "generic.messages.description.or.amount" })} />
        </div>
        <div className={styles.time}>
          <div style={{ width: '100%' }}>
            <Select
              isMulti={false}
              options={timeOptions}
              styles={singleStyles}
              value={selectedTimeOption}
              onChange={handleSelectTimeChange}
              placeholder={intl.formatMessage({ id: "generic.messages.choose.time" })}
            />
          </div>
        </div>
        <div className={styles.type}>
          <div style={{ width: '100%' }}>
            <Select
              isMulti={false}
              styles={singleStyles}
              value={selectedTypeOption}
              onChange={handleSelectTypeChange}
              options={typeOptions}
              placeholder={intl.formatMessage({ id: "generic.messages.choose.type" })}
            />
          </div>
        </div>
        <div className={styles.type}>
          <SecondaryButton textId="generic.messages.search" isBusy={isBusy} onClick={onSearch} />
        </div>
      </div>
      <div className={`${styles.table} ${styles.section}`}>
        <table>
          <thead>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.transaction.from" })}<img src={icDown} alt="From" /></th>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.transaction.to" })}<img src={icDown} alt="To" /></th>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.transaction.description" })}<img src={icDown} alt="To" /></th>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.time" })}<img src={icDown} alt="Time" /></th>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.amount" })}<img src={icDown} alt="Amount" /></th>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.transaction.status" })}<img src={icDown} alt="Status" /></th>
            <th className={styles["table-head"]}>{intl.formatMessage({ id: "generic.messages.receipt" })}<img src={icDown} alt="Receipt" /></th>
          </thead>
          <tbody>
            {transactions.map(item => <TransactionItem key={item.id} transaction={item} />)}
          </tbody>
        </table>
        {showNoResult && <NoTransactions />}
        {isBusy && <TransactionLoader />}
      </div>
      {/* <div className={`${styles.total} ${styles.section}`}>
        <div className={styles.content}>
          <p className={styles.text}>{intl.formatMessage({id: "generic.messages.total"})}</p>
          <span className={styles.number}>{getTotal()} $</span>
        </div>
      </div> */}
      <div className={`${styles["buttons-holder"]} ${styles.one}`}>
        <PrimaryButton textId="generic.messages.download.history" isBusy={isBusy} onClick={onHistoryDownload} disabled={isBusy} />
      </div>
    </div>
  )
};
