import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  role: '',
  userName: '',
  authToken: "",
  givenName: '',
  currentEmailInvite: "",
  isAuthenticated: false,
  userHasFilledProfile: false,
  userIsAccepted: false
};

const appSlice = createSlice({
  name: "application",
  initialState: initialState,
  reducers: {
    logIn: (state, action) => { //todo: this isn't type safe should we change it?
      const p = action.payload
      state.userId = p.userId
      state.role = p.role
      state.isAuthenticated = true
      state.userName = p.userName
      state.authToken = p.authToken
      state.givenName = p.givenName
      state.userHasFilledProfile = p.userHasFilledProfile
      state.userIsAccepted = p.userIsAccepted
    },
    signOut: (state) => {
      state.isAuthenticated = false
    },
    setUserProfileFilled: (state) => {
      state.userHasFilledProfile = true
    },
    setCurrentEmailInvite: (state, action) => { //todo: should this go to another reducer?
      state.currentEmailInvite = action.payload
    }
  }
})

export const { logIn, signOut, setUserProfileFilled, setCurrentEmailInvite } = appSlice.actions
export default appSlice.reducer
// export const getLoading = (state: RootState) => state.emailLookup.loading;, signOut, fillUserProfile, setCurrentEmailInvite