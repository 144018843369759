import { IntlShape } from "react-intl";

export enum TransactionStatusEnum {
    Complete = 0,
    Cancelled = 1,
    Initialized = 2,
    Processing = 3
}

export const TransactionStatusEnumStringKeys: { [key in TransactionStatusEnum]: string } = {
    [TransactionStatusEnum.Complete]: "generic.messages.transaction.type.complete",
    [TransactionStatusEnum.Cancelled]: "generic.messages.transaction.type.cancelled",
    [TransactionStatusEnum.Initialized]: "generic.messages.transaction.type.initialized",
    [TransactionStatusEnum.Processing]: "generic.messages.transaction.type.processing",
};

export const getTransactionStatusEnumWithEmptyOptions = (intl: IntlShape): { value: number | null, label: string }[] => [
    { value: null, label: intl.formatMessage({ id: "generic.messages.transaction.type.any" }) },
    ...Object.entries(TransactionStatusEnumStringKeys).map(([key, value]) => ({
        value: parseInt(key),
        label: intl.formatMessage({ id: value })
    }))
];
