import React, { useState } from "react";
import { ModalBody } from "../../../Helfy.Components/Modal/ModalBody";
import { ModalHeader } from "../../../Helfy.Components/Modal/ModalHeader";
import styles from "../Styles/review-session.module.scss"
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton } from "../../../Helfy.Components/Buttons/PrimaryButton";
import { HelfyRating } from "../../../Helfy.Components/HelfyRating";
import { FastField, Field } from "formik";
import { FormikRatingField } from "../../../Helfy.Components/Formik/HelfyRatingField";
import { TextAreaField } from "../../../Helfy.Components/Formik/TextAreaField";
import { getErrorMessageFromApi } from "../../../Helfy.Components/ApiErrors";

interface Props {
	isValid: boolean;
	error: string | null;
	isSubmitting: boolean;

	onClose(): void;
	handleSubmit(): void;
}

export const ReviewSessionView = ({ onClose, handleSubmit, error, isValid, isSubmitting }: Props) => {
	const intl = useIntl();
	const [rating, setRating] = useState(0);

	return (
		<ModalBody bodyStyle={{ width: '700px', overflow: 'hidden' }}>
			<ModalHeader show={true} text={intl.formatMessage({ id: "review.session.title" })} onCloseClick={onClose} />
			<div className={styles.modalContent}>
				{!!error && <div className={styles.errorMessage}>{error}</div>}
				<p className={styles.reviewLine1}><FormattedMessage id="review.session.line1" /></p>
				<div className={styles.stars}>
					<Field
						name="rating"
						component={FormikRatingField}
						starDimension="50px"
						starSpacing="1px"
					/>
				</div>
				<div className={styles["message-area"]}>
					<Field
						name="comment"
						type="text"
						component={TextAreaField}
					/>
				</div>
				<div className={styles.footer}>
					<PrimaryButton
						textId="review.session.submit"
						onClick={handleSubmit}
						disabled={!isValid}
						isBusy={isSubmitting}
					/>
				</div>
			</div>
		</ModalBody>
	)
}