import React, { useState } from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss';
import { InputField } from "../../../../Helfy.Components/Formik/InputField";
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { HelfyMultiSelect } from "../../../../Helfy.Components/Formik/HelfyMultiSelect";
import { CreatableField } from "../../../../Helfy.Components/Formik/CreatableField";
import { FileField } from "../../../../Helfy.Components/Formik/FileField";
import { ISteps } from '../../Types';
import { useAppSelector } from '../../../../Helfy.Store/Hooks';
import { TextAreaField } from '../../../../Helfy.Components/Formik/TextAreaField';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
}

export const ProfileView = ({ move, isValid, handleSubmit }: Props) => {
  //todo: will have to fix this eventually
  //todo: labels are not being translated
  const specialitiesOptions = useAppSelector(state => state.counselorOnBoarding.specialtiesOptions);
  const languagesOptions = useAppSelector(state => state.counselorOnBoarding.languagesOptions);

  const onSubmit = () => {
    handleSubmit();
    move(1);
  }

  //todo: go through whole obboarding here and in patient and transalte all labels
  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>My Profile</h3>
          <div className={styles.infoContent}>
            <div className={styles.left}>
              <FastField
                id="educationInput"
                name="education"
                type="text"
                label="Education"
                placeholder="Education"
                component={TextAreaField} />
              <FastField
                id="pricePerHourInput"
                name="pricePerHour"
                type="number"
                step="0.1"
                min={0}
                label="Price Per Hour ($)"
                placeholder="Price Per Hour"
                component={InputField}
              />
            </div>
            <div className={`${styles.right} ${styles.data}`}>
              <div className={styles.data}>
                <FastField
                  id="languageInput"
                  name="languages"
                  type="text"
                  label="Language"
                  options={languagesOptions}
                  placeholder="Select languages"
                  component={HelfyMultiSelect}
                />
                <FastField
                  id="specialitiesInput"
                  name="specialties"
                  type="text"
                  label="Specialities"
                  options={specialitiesOptions}
                  placeholder="Specialities"
                  component={CreatableField} />
                {/* todo:delete if we don't need other specialties */}
                {/* <FastField
                  type="text"
                  id="otherSpecialitiesInput"
                  name="otherSpecialties"
                  options={specialitiesOptions}
                  label="Secondary specialities"
                  placeholder="Other Specialities"
                  component={CreatableField} /> */}
                {/* todo: take this back once you start using it
                <FastField
                  id="resumeInput"
                  name="resume"
                  type="file"
                  disabled={false}
                  label="Upload Resume"
                  placeholder="Upload Resume"
                  component={FileField} />*/}
              </div>
            </div>
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId="counselor.on.boarding.button.next" onClick={onSubmit} disabled={!isValid} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}