import React from "react";
import { HelfySuccessModal } from "./HelfySuccessModal";

interface Props {
    title: string;
    message: string;
    error: string | null;
    onConfirmClick(): void;
}

export const HelfySimpleSuccessModal = ({ title, error, onConfirmClick, message }: Props) =>
    <HelfySuccessModal title={title} onConfirmClick={onConfirmClick} message={message} show={error == null} />