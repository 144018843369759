//todo delete whole loadspecialities
import axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "../../../Helfy.Config/AppSettings";
import { ErrorManager } from "../../../Helfy.Managers/ErrorManager";
import { LogManager } from "../../../Helfy.Managers/LogManager";


export const LOAD_SPECIALITIES_OPTIONS_SUCCESS = "LOAD_SPECIALITIES_OPTIONS_SUCCESS";
export const LOAD_SPECIALITIES_OPTIONS_FAILURE = "LOAD_SPECIALITIES_OPTIONS_FAILURE";
export const LOAD_SPECIALITIES_OPTIONS_REQUESTED = "LOAD_SPECIALITIES_OPTIONS_REQUESTED";


export const loadSpecialitiesAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: LOAD_SPECIALITIES_OPTIONS_REQUESTED});
      const response = await axios.get(`${API_URL}/Options/GetSpecialties`);
      dispatch({type: LOAD_SPECIALITIES_OPTIONS_SUCCESS, payload: response.data});
      return true;
    } catch (e) {
      const error: any = ErrorManager.getErrorMessageForDisplay(e);
      LogManager.logError("LOAD_SPECIALITIES ERROR", error);
      dispatch({type: LOAD_SPECIALITIES_OPTIONS_FAILURE, payload: error});
      return false;
    }
  }
};