import React from 'react';
import styles from "../Styles/card-loading.module.scss";


export const LoadingCard = React.memo(() => {
  return (
    <div className={`${styles.card} ${styles.loading}`}>
      <div className={`${styles.avatar} ${styles.empty}`}/>
      <div className={styles["line-1"]}/>
      <div className={styles["line-2"]}/>
      <div className={styles["container-1"]}/>
      <div className={styles["line-3"]}/>
      <div className={styles["line-4"]}/>
    </div>
  )
})