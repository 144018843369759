import React from 'react';
import { FormattedDate } from "react-intl";
import styles from '../Styles/review-item.module.scss';
import { HelfyRating } from "../../../Helfy.Components/HelfyRating";
import { HelfyAvatar } from "../../../Helfy.Components/Avatar/HelfyAvatar";

interface Props {
	review: any
}

export const ReviewItem = ({ review }: Props) => {
	const { rating, date, text } = review;

	return (
		<div className={styles["details-list"]}>
			<div className={styles["info-row-1"]}>
				<div className={styles["r-left"]}>
					<div className={styles.avatar}>
						<HelfyAvatar fullName='Registered User' profilePhoto='' />
					</div>
					<div className="name-date">
						<h3 className={styles.name}>Registered User</h3>
						<p className={styles.date}><FormattedDate value={date} /></p>
					</div>
				</div>
				<div className={styles["r-right"]}>
					<HelfyRating starDimension="20px" rating={rating} />
				</div>
			</div>
			<div className={styles["info-row-2"]}>
				<p className={styles["review-text"]}>{text}</p>
			</div>
		</div>
	);
}
