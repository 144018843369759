import React, { useState } from 'react'
import { Formik } from "formik";
import { Row } from "react-grid-system";
import { ResetPasswordSchema } from "./ValidationSchema";
import { ResetPasswordView } from "../Views/ResetPasswordView";
import { HelfyContainer } from "../../../Helfy.Layout/HelfyContainer";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { HelfySimpleErrorModal } from '../../../Helfy.Components/UIStates/HelfySimpleErrorModal';
import { HelfySimpleSuccessModal } from '../../../Helfy.Components/UIStates/HelfySimpleSuccessModal';
import { apiSubmitAsync } from '../../../Helfy.Api/Helpers/Helpers';

type FormValues = {
  newPassword: string,
  repeatPassword: string
}

export const ResetPasswordContainer = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>("")
  const [isBusy, setIsBusy] = useState(false)
  const search = new URLSearchParams(useLocation().search);

  const handleSubmitAsync = async (values: any, actions: any) => apiSubmitAsync(
    () => {
      const code = search.get('code') || "";
      const userId = search.get('userId') || 0;

      const data = { ...values, code: code, userId: userId };
      return data;
    }, actions, setError, setIsBusy, "/Auth/SetPassword", "Set new password", (r) => null)

  const handleSuccess = () => {
    navigate("/")
  }

  const text = intl.formatMessage({ id: 'reset.password.success.modal.text' });
  const title = intl.formatMessage({ id: 'reset.password.success.modal.title' });
  const errorTitle = intl.formatMessage({ id: 'reset.password.error.modal.title' });

  const initialValues: FormValues = {
    newPassword: "",
    repeatPassword: ""
  }

  return (
    <HelfyContainer paddedTop>
      <Row justify="center">
        <Formik validateOnMount validateOnBlur onSubmit={handleSubmitAsync} isInitialValid={false} initialValues={initialValues} validationSchema={ResetPasswordSchema}>
          {props => <ResetPasswordView {...props} />}
        </Formik>
      </Row>
      <HelfySimpleErrorModal error={error} title={errorTitle} setError={setError} />
      <HelfySimpleSuccessModal error={error} title={title} message={text} onConfirmClick={handleSuccess} />
    </HelfyContainer>
  );
}