import React from 'react';
import { FastField } from "formik";
import styles from '../../Styles/counselor-on-boarding.module.scss'
import { OnBoardingCardHolder } from "../../../../Helfy.Components/Cards/OnBoardingCardHolder";
import { SecondaryButton } from "../../../../Helfy.Components/Buttons/SecondaryButton";
import { PrimaryButton } from "../../../../Helfy.Components/Buttons/PrimaryButton";
import { TextAreaField } from "../../../../Helfy.Components/Formik/TextAreaField";
import { ISteps } from '../../Types';

interface Props extends ISteps {
  isValid: boolean;
  handleSubmit(): void;
  isBusy: boolean
}

export const HelfyQuestions3View = ({ move, isValid, handleSubmit, isBusy }: Props) => {
  const onSubmit = () => {
    handleSubmit();
  }

  //todo: zove se requiredsupervision a ne supervision-ed
  return (
    <>
      <OnBoardingCardHolder>
        <div className={styles.onBoardingContainer}>
          <h3>Helfy Questions 3</h3>
          <div className={styles.profileContent2}>
            <FastField
              id="notEmployeeDisclaimer"
              name="notEmployeeDisclaimer"
              type="text"
              label="Do you understand that you will not be our employee but an independent provider who provides services through the platform ?"
              placeholder="Freelancer?"
              component={TextAreaField}
            />
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton textId="counselor.on.boarding.button.prev" onClick={() => move(-1)} />
            <PrimaryButton textId="counselor.on.boarding.button.next" onClick={onSubmit} disabled={!isValid || isBusy} />
          </div>
        </div>
      </OnBoardingCardHolder>
    </>
  );
}