import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import styles from './Styles/upload-button.module.scss'
import icAvatar from '../../Helfy.Assets/Images/avatar-placeholder.png'
import axios from 'axios';
import { apiSimpleSubmitAsync, apiSubmitAsync } from '../../Helfy.Api/Helpers/Helpers';
import { setError } from '../../Helfy.Containers/AccountInfo/Redux/Reducer';
import { MessageStatusEnum } from '../../Helfy.Enums/MessageStatusEnum';

interface Props {
  id: string;
  form: any;
  field: any;
  errors: any;
}

export const UploadButton = ({ id, form, field }: Props) => {
  const [imageSrc, setImageSrc] = useState<string>(field.value || icAvatar);
  const [error, setError] = useState<string | null>(null);

  const handleChange = async (e: any) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    // const reader = new FileReader();
    // reader.onload = () => {
    //   const base64 = reader.result;
    //   setImageSrc(base64 as string);
    // };
    // reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append('file', file);
    var { r, error } = await apiSubmitAsync(() => formData, null, null, null, "/Account/Upload", "UPLOAD_IMAGE_ERROR", null)
    const url = r?.data.url
    if (url) {
      url && setImageSrc(url)
      form.setFieldValue(field.name, url)
    } else {
      //todo: tu ima error.displaymessage ponekada nema totalno je nesređeno i readi type unsafety. sredi to jednom zauvijek
      const message = error?.displayMessage ?? error;
      message && setError(message);
    }
  };

  return (
    <>
      <div>
        <img src={imageSrc} alt="Avatar" />
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.buttonWrapper}>
        <button className={styles.btn}>
          <FormattedMessage id="helfy.shared.upload" />
        </button>
        <input id={id} type="file" onChange={handleChange} multiple={false} accept="image/jpeg, image/png" />
      </div>
    </>
  );
}
