import React from 'react';
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import styles from '../Styles/payment-methods.module.scss'

interface Props {
  show: boolean;
}

export const BuyCredits = React.memo(({show}: Props) => {
  const intl = useIntl();
  if (!show) return null;

  return (
    <div className={`${styles["buttons-holder"]} ${styles.one}`}>
      <NavLink to="/buy-credits">
        <button className={styles.right}>{intl.formatMessage({id: "generic.messages.buy.credits"})}</button>
      </NavLink>
    </div>
  )
});