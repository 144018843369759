import Yup from '../../../Helfy.Validation/HelfyYupLocalizedValidation';

export const LoginSchema = Yup.object().shape({
  userName: Yup.string()
    .email()
    .required(),
  password: Yup.string()
    .min(6)
    .required(),
});
