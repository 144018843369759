import React from 'react';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-grid-system";
import styles from '../Styles/accept-email-invite.module.scss'
import emailSent from '../../../Helfy.Assets/Images/email-sent.svg'
import { HelfyContainer } from "../../../Helfy.Layout/HelfyContainer";
import { HelfyFormHolder } from "../../../Helfy.Layout/HelfyFormHolder";
import { useAppSelector } from '../../../Helfy.Store/Hooks';

interface Props {
  onRegisterClick(): void;
}

export const AcceptEmailInviteContainer = ({ onRegisterClick }: Props) => {
  const navigate = useNavigate();
  const emailInvite = useAppSelector((state) => state.app?.currentEmailInvite || "");

  const goToRegister = () => {
    navigate("/");
    onRegisterClick();
  }

  return (
    <HelfyContainer paddedTop>
      <Row justify="center">
        <Col xl={8} lg={8} md={8} xs={12} sm={12}>
          <HelfyFormHolder>
            <p><FormattedMessage id="accept.email.invite.title" /></p>
            <img src={emailSent} alt="accept-email-icon" />
            <div className={styles.description}>
              <FormattedMessage id="accept.email.invite.description" values={{ email: <strong className={styles.bold}>{emailInvite}</strong> }} />
            </div>
            <div className={styles.didNotGetEmail}>
              <FormattedMessage id="accept.email.invite.not.get.email" />
              <button onClick={() => navigate("/resend-email-verification")} className={styles.buttonSignUp}><FormattedMessage id="accept.email.invite.not.get.email.resend" /></button>
            </div>
            <div className={styles.incorrectEmail}>
              <FormattedMessage
                id="accept.email.invite.incorrect.email"
                values={{ link: <button onClick={goToRegister} className={styles.buttonSignUp}><FormattedMessage id="sign.up.title" /></button> }}
              />
            </div>
          </HelfyFormHolder>
        </Col>
      </Row>
    </HelfyContainer>
  )
}