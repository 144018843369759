import React from 'react';

import styles from './account-content.module.scss';

interface Props extends React.PropsWithChildren {
  className?: string;
}

export const AccountContentHolder = ({ children, className }: Props) => {
  const combinedClassName = `${styles.myAccountHolder} ${className || ''}`.trim();
  return (
    <div className={combinedClassName}>
      {children}
    </div>
  );
}