import React from "react";
import { HelfyErrorModal } from "./HelfyErrorModal";

interface Props {
    title?: string;
    error: string | null;
    setError: (a: string) => void
}

export const HelfySimpleErrorModal = ({ title, error, setError }: Props) =>
    <HelfyErrorModal title={title} onConfirmClick={() => setError("")} message={error} show={!!error} />