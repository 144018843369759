import React, { useEffect } from 'react';
import { Col, Row } from "react-grid-system";
import { HeroSection } from "../Sections/Hero/HeroSection";
import { ReasonsSection } from "../Sections/Reasons/ReasonsSection";
import { BreakSection } from "../Sections/Break/BreakSection";
import { HowDoesItWorkSection } from "../Sections/HowDoesItWork/HowDoesItWorkSection";
import { SecondReasonsSection } from "../Sections/SecondReasons/SecondReasonsSection";
import { CrisisSection } from "../Sections/Crisis/CrisisSection";

interface Props {
  onRegisterClick(): void;
}

//todo: maybe this is a hack i don't know for when you press email confirmation. for now it will do.
export const LandingPageContainer = ({ onRegisterClick }: Props) => {
  return (
    <Row justify="center">
      <Col lg={12} md={12} xs={12} sm={12}>
        <HeroSection onRegisterClick={onRegisterClick} />
        <ReasonsSection />
        <BreakSection onRegisterClick={onRegisterClick} />
        <HowDoesItWorkSection />
        <SecondReasonsSection />
        <BreakSection onRegisterClick={onRegisterClick} />
        <CrisisSection />
      </Col>
    </Row>
  );
}