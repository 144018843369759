import React from 'react';
import { Col, Row } from "react-grid-system";
import { NavLink } from "react-router-dom";

export const PrivacyPolicyContainer = () => {
  return (
    <Row justify="center">
      <Col lg={8} md={8} xs={12} sm={12}>
        <h1>Privacy Policy</h1>
        <ol>
          <li>
            About Your Privacy and This Privacy Policy
            <p>Your privacy is extremely important to us. We continuously devote significant thought, effort, tools, resources, and procedures to protect and safeguard it. This document constitutes our "Privacy Policy," detailing issues related to your privacy when using our services. Its purpose is to inform you of our policies, procedures, and practices regarding the collection, use, and disclosure of any information provided through the Platform.</p>
            <p>The Privacy Policy is an integral component of our Terms and Conditions, accessible on our website. The terms within the Privacy Policy (such as, but not limited to, "we," "our," "us," "Platform," "Counsellor," "Counsellor Services," etc.) carry the same meaning as in our Terms and Conditions document. By using our Platform, you accept and agree to both the Terms and Conditions and the Privacy Policy. If you do not agree to be bound by the Privacy Policy, you should immediately quit using the Platform. By accessing and using our Platform, you affirm that you have read the Terms and Conditions and the Privacy Policy and that you understand, agree, and acknowledge all the terms contained in both documents.</p>
          </li>
          <li>
            Information Collection, Use, and Disclosure
            <p>To enable us to effectively operate the Platform and to facilitate your use of the Platform, including the Counsellor Services, we may need to collect your personally identifiable information (such as, but not limited to, your name, phone number, email address, and address), billing and payment information, profile information, log data (information such as your computer, Internet Protocol address ("IP"), pages that you visit, and the amount of time spent on those pages, actions you take, and other statistics), information related to the Counsellor Services or your need for Counsellor Services, and any information exchanged between you and your Counsellor (collectively referred to as the "Information"). In some cases, some of the Information that you provide to us may be considered health-related data. You may choose which Information, if any, you would like to share with us, but certain functions of the Platform may not be available to you without providing us with the necessary Information. By opting to provide the Information, you agree to our methods of collection and use, as well as to other terms and provisions outlined in this Privacy Policy.</p>
            <p><strong>Protecting this Information is our top priority. We will never sell or rent access to any Information you provide on the Platform. Except as detailed in this Privacy Policy, we will never use or disclose any Information unless you specifically and explicitly request or approve us to do so.</strong></p>
            <p>The Information may be used for the following purposes:
            </p>
            <ul>
              <li>To create your account on our Platform and enable you to log in and utilise the Platform.</li>
              <li>To manage your account, provide customer support, and ensure you receive quality service.</li>
              <li>To contact you or provide information, alerts, and suggestions related to the service.</li>
              <li>For billing-related purposes.</li>
              <li>To contact you directly or involve the appropriate authorities if either we or a Counsellor have reason to believe that you or another person may be in danger or involved in a criminal act.</li>
              <li>To match you with a Counsellor.</li>
              <li>To enable and facilitate the Counsellor Services.</li>
              <li>To supervise, administer, and monitor the service.</li>
              <li>To measure and enhance the quality, effectiveness, and delivery of our services.</li>
              <li>To market the Platform and Counsellor Services to you.</li>
            </ul>
            <p />
          </li>
          <li>
            Opting out of Marketing Communication
            <p>You can opt out of receiving email marketing by unsubscribing using the unsubscribe link provided in all our marketing email communications.</p>
          </li>
          <li>
            Cookies and Web Beacons
            <p>Like many websites, we utilise "cookies" and "web beacons" to gather information. A "cookie" is a small data file transferred to your computer's hard disk for record-keeping purposes. A "web beacon" is a tiny image placed on a web page or email that can track your visit or usage. We employ cookies and web beacons to enable the technical functioning of the Platform, administer your account login, and collect Log Data. You have the option to adjust your browser settings to reject cookies or to prompt you before accepting one. However, declining cookies may interfere with your ability to use the Platform. Additionally, the Platform may employ cookies and web beacons from third-party services not covered by our Privacy Policy, and we lack access or control over these cookies and web beacons. Furthermore, we may utilise third-party cookies for web analytics, attribution, and error management purposes.</p>
          </li>
          <li>
            Social and General Information Tools
            <p>We utilise several publicly available tools and information exchange resources, including (but not limited to) a blog, a Facebook page, a Twitter account, and others (collectively referred to as "Social and General Information Tools"). Any information you provide or share while using these Social and General Information Tools may be read, accessed, and collected by the respective site and its users, subject to their Privacy Policy.</p>
          </li>
          <li>
            Phishing
            <p>Online identity theft and account hacking, including the practice commonly known as "phishing," are significant concerns. You should always exercise caution when asked for your account information and ensure that you only provide it through our secure system. We will never request your login information or credit card details through any non-secure or unsolicited communication, whether it be via email, phone, or any other means.</p>
          </li>
          <li>
            Links
            <p>The Platform may include links to other websites, services, or offers owned, operated, or maintained by third parties. Clicking on a third-party link will redirect you to their respective website or service. The inclusion of such links does not constitute an endorsement, authorization, or representation of our affiliation with the third party, nor does it imply an endorsement of their privacy or information security policies or practices. We lack control over third-party websites and services, including their privacy policies and terms of use.</p>
          </li>
          <li>
            Security
            <p>Although using any Internet-based service carries inherent security risks that cannot be 100% prevented, our systems, infrastructure, encryption technology, operations, and processes are all designed, built, and maintained with your security and privacy in mind. We adhere to industry standards and best practices to prevent unauthorised access, use, and disclosure. Furthermore, we comply with all applicable federal and state laws, as well as regulations regarding data privacy.</p>
          </li>
          <li>
            Service Providers
            <p>We may employ third-party companies and individuals to facilitate our Platform, to perform tasks related to the Platform, or to provide audit, legal, operational, or other services for us. These tasks include, but are not limited to, customer service, technical maintenance, monitoring, email management and communication, database management, billing and payment processing, reporting, and analytics. We will share with them only the minimum necessary information to perform their tasks for us and only after entering into appropriate confidentiality agreements.</p>
          </li>
          <li>
            Children's Privacy
            <p>We do not knowingly collect or solicit any information from anyone under the age of 13, nor do we knowingly allow such individuals to become our users. The Platform is not directed toward, and is not intended to be used by, children under the age of 13. If you are aware that we have collected Personal Information from a child under the age of 13, please notify us by contacting us, and we will promptly delete that information.</p>
          </li>
          <li>
            International Transfer
            <p>Your information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction. Regardless of where your data is stored, it will be maintained securely as outlined in this policy. Your consent to our Terms and Conditions, followed by your submission of such information, represents your agreement to such transfers.</p>
          </li>
          <li>
            Compliance with Laws and Law Enforcement
            <p>We cooperate with government and law enforcement officials to enforce and comply with the law. We may disclose information necessary or appropriate to protect the safety of the public or any person, to respond to claims and legal processes (including but not limited to subpoenas), and to prevent or stop activity that may be illegal or dangerous. Additionally, you should be aware that Counsellors may be obligated to disclose information to law enforcement or other authorities to fulfil their professional and legal responsibilities. Specifically, and without limitation, you should be aware that the law requires mental health professionals to disclose information and/or act in the following cases: (a) reported or suspected abuse of a child or vulnerable adult; (b) serious suicidal potential; (c) threatened harm to another person; (d) court-ordered presentation of treatment.</p>
          </li>
          <li>
            General Data Protection Regulation (GDPR) Notice
            <p>This section provides additional information about our Privacy Policy relevant to users from the European Union. It is necessary for us to use your personal information:</p>
            <ul>
              <li>To perform our obligations in accordance with any contract that we may have with you.</li>
              <li>It is in our legitimate interest or a third party's legitimate interest to use personal information in such a way to ensure that we provide the Services in the best way that we can.</li>
              <li>It is our legal obligation to use your personal information to comply with any legal obligations imposed upon us.</li>
            </ul>
            <p />
            <p>You can view and edit any personal data that you have provided to us using this website. Automated processing of your Personal Information is necessary to operate the Platform effectively and to provide counselling and related services.</p>
            <p>You can exercise any of the rights the law confers to you, like for example: opt-out of all data processing, or request erasure of your data by following the instructions on our <a href="/opt_out/">opt-out page</a>. In some
              cases, we may be required to retain personal data to comply with applicable medical record retention laws.</p>
            <p>Helfy is the Controller with respect to your Personal Data. You can contact our Data Protection Officer with questions, concerns, or objections about this policy, or about your data by writing to:</p>
            <p>
              helfy Inc.<br />
              1111B S Governors Ave STE 7660<br />
              Dover DE<br />
              19904<br />
              USA<br />
              <a href="mailto:privacy@helfy.life">privacy@helfy.life</a>
            </p>
            <p>You can contact our EU Representative by writing to:</p>
            <p>
              helfy doo<br />
              Horvaćanska 31<br />
              10000 Zagreb<br />
              Croatia<br />
              <a href="mailto:privacy@helfy.life">privacy@helfy.life</a>
            </p>
          </li>
          <li>
            Changes to the Privacy Policy
            <p>We may update this privacy statement at our sole discretion. The date of the last revision of this policy appears at the end of this page. We encourage you to periodically review this page for the latest information on our Privacy Policy and practices. Regardless of changes to our Privacy Policy, we will never use the information you submit under our current privacy notice in a new way without first notifying you and providing you with the option to opt out.</p>
          </li>
          <li>
            Contacting us
            <p>If you have any questions or concerns about this Privacy Policy or our privacy-related practices, please contact us by clicking the "Contact" link at the bottom of any page on our website.</p>
          </li>
        </ol>
        Last Updated: June 6, 2024
      </Col>
    </Row>
  );
}