import React from 'react';
import styles from '../Styles/row-holder.module.scss';

interface Props extends React.PropsWithChildren {
  form: any;
  field: any;
}
const renderError = ({ form, field }: Props) => {
  const { name } = field;
  const { errors, touched } = form;

  if (!!(errors[name] && touched[name]))
    return styles.invalid;
}

export const RowHolder = ({ form, field, children }: Props) => {
  const errorCss = renderError({ form, field }) || "";
  return (
    <div className={`${styles.holder} ${errorCss}`}>
      {children}
    </div>
  );
}